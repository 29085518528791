import { render, staticRenderFns } from "./is_b_serve.vue?vue&type=template&id=1cd9994d&scoped=true&"
import script from "./is_b_serve.vue?vue&type=script&lang=js&"
export * from "./is_b_serve.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cd9994d",
  null
  
)

export default component.exports