<template>
	<div class="meal_detail" style="width: 100%;overflow: hidden;color: #1A1A1A;" v-if="prodetail&&meal_img_text&&tlist">
		<div class="maintanchuang" @click="tlshowtan" v-if="showtan">
			<div class="maintanc" @click="lshowtan" style="background: #FFFFFF;">
				<div class="maintline" @click="showtan = !showtan"><img src="../../public/imgs/close.png" alt=""></div>
				<div class="maintantxt">
					<div class="meal-researcher-box">
						<div class="div1">
							<div class="meal-researcher-name">{{showitem.goods_name| replaceCommaWithBlank}}</div>
							<div class="meal-researcher-titles">
								<p v-for="(itemm,indexx) in showitem.popular_science_title.split(' ')" :key="indexx">
									{{itemm}}
								</p>
							</div>
							<div class="meal-researcher-msgs">{{showitem.report_content}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="goods_unit-mainbox maintanchuang" @click="tlshowtan2" v-if="showtan2">
			<div class="goods_unit-main maintanc" style="max-width: 568px;" @click="lshowtan2">
				<div class="goods_unit-maintline" @click="tlshowtan2"><img src="../../public/imgs/close.png"
						alt=""></div>
				<div class="goods_unit-maintantxt" style="padding-top: 20px;">
					<div class="pro-center-info-maininfo" style="width: 100%;padding: 20px;">
						<div class="pro-amount-title" style="text-align: left;">
							营养标签
						</div>
						<div class="pro-amount-th">
							<div class="pro-amount-th-l" style="text-align: left;">
								<p>Amount Per Serving</p>
								<p>每份含成分({{thisnum}}颗)</p>
							</div>
							<div class="pro-amount-th-r">
								<p>% Daily Value</p>
								<p>每日营养摄入量百分比</p>
							</div>
						</div>
						<div class="pro-amount-td" v-for="(item,index) in prodetails.goods_detail.goods_unit"
							:key="index">
							<div class="pro-amount-td-l" style="width: 60%;">{{item.unit_eng}}
								<p>{{item.zh}}</p>
							</div>
							<div class="pro-amount-td-c" style="width: 20%;">
								{{Number(item.single_unit_size)*thisnum}}{{item.unit}}
							</div>
							<div class="pro-amount-td-r" style="width: 20%;">{{item.unit_percentage}}</div>
						</div>
						<div class="pro-amount-orther" style="text-align: left;">
							其他成分:{{prodetails.goods_detail.other_component}}
						</div>
						<div class="pro-amount-more" style="text-align: left;">
							*没有添加{{prodetails.goods_detail.exclusive}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="prodetail mealdetail mb_box">
			<div class="mealbg"></div>
			<div class="roll_img">
				<Carousel :autoplay="true" :autoplay-speed="3000" :radius-dot="true"
					style="width: 100vw;height: auto;margin-top: 0;">
					<CarouselItem v-for="(item,index) in prodetail.roll_img" :key="index"
						style="width: 100vw;height: auto;max-width: 10rem;">
						<img style="width: 100vw;height: auto;" :src="item" alt="">
					</CarouselItem>
				</Carousel>
				<div class="actual_num">已售{{prodetail.actual_num}}盒
					{{prodetail.surplus_num!=-1&&prodetail.surplus_num>0?' 剩余'+prodetail.surplus_num+'盒':''}}
				</div>
			</div>
			<div class="meal_info">
				<div class="meal_name">{{prodetail.name}}</div>
				<div class="meal_list">{{prodetail.introduce}}</div>
				<div class="meal_price"><del v-if="prodetail.total_price!=prodetail.pay_price"
						style="margin-right: 0.3rem;color: #999999;">￥{{prodetail.total_price}}</del>￥<span>{{prodetail.pay_price}}</span>/套
				</div>
			</div>
			<div class="mealdetailinfo" v-html="prodetail.detailed"></div>
			<div class="buy-bottom">
				<div class="mealprice">
					<div class="mprice">
						<div class="tprice">￥<span>{{prodetail.pay_price}}</span></div>
						<div class="pmsg">保税区直邮</div>
					</div>
					<div class="mbtns" v-if="prodetail.sell_out==0&&prodetail.status==1">
						<a class="ac" @click="addcart">加入购物车</a>
						<a class="zm" @click="gobuy">直接购买</a>
					</div>
					<div class="mbtns" v-if="prodetail.sell_out==1&&prodetail.status==1">
						<a class="tz" @click="daohuo(prodetail.id)">{{isAlipay? '已售罄' : '到货通知'}}</a>
					</div>
					<div class="mbtns" v-if="prodetail.status==0">
						<a class="tz">已下架</a>
					</div>
				</div>
			</div>
			<div class="mealfooter">

			</div>

		</div>
		<div class="pc_box" v-if="jiazai">
			<div class="meal-pc-banner">
				<div class="meal-pc-banner-imgs">
					<img :src="meal_img_text.big_img" alt="">
				</div>
				<div class="meal-pc-banner-info">
					<div class="meal-pc-banner-infobox">
						<div class="meal-pc-banner-title">
							<h1>{{meal_img_text.name_en}}</h1>
						</div>
						<div class="meal-pc-banner-title2">
							<h5>{{meal_img_text.name_cn}}</h5>
						</div>
						<div class="meal-pc-banner-msg">
							{{meal_img_text.introductions}}
						</div>
						<div class="meal-pc-banner-li">
							<div class="li-line" v-for="(item,index) in meal_img_text.bullet_point" :key="index">
								{{item.point}}
							</div>
						</div>
						<div class="meal-pc-banner-btn cursor" @click="addcart">
							<div class="meal-pc-banner-prices" v-if="prodetail.total_price!=prodetail.pay_price">
								<div class="meal-pc-banner-nprice"><span>￥</span>{{prodetail.pay_price}}</div>
								<div class="meal-pc-banner-oprice"><span>￥</span>{{prodetail.total_price}}</div>
							</div>
							<div class="meal-pc-banner-prices meal-pc-banner-pricess" v-else>
								<div class="meal-pc-banner-nprice"><span>￥</span>{{prodetail.pay_price}}</div>
							</div>
							<div class="meal-pc-banner-btn-center"></div>
							<button>加入购物车</button>
							<div class="meal-pc-banner-sellnum">已售{{prodetail.actual_num}}套</div>
						</div>
					</div>
				</div>
			</div>
			<div class="switch-box" :class="tlist.length>4?'':'nobtn'">
				<div class="switch-box-title">
					<div class="switch-box-name">
						<h3>Best Sellers</h3>
					</div>
					<div class="switch-box-msg">热门推荐</div>
					<div class="switch-box-btns" :style="tlist.length>4?'':'opacity: 0;z-index: 3;'">
						<div class="switch-box-last">
							<img src="../../public/imgs/more.png" alt="">
						</div>
						<div class="switch-box-next">
							<img src="../../public/imgs/more.png" alt="">
						</div>
					</div>
				</div>

				<div class="switch-box-main switch-box-mainq1200 switch-box-main2"
					style="width: 79vw;margin-left: -2vw;">
					<div class="switch-box-main-bg switch-box-main-bgl"></div>
					<div class="switch-box-main-bg switch-box-main-bgr"></div>
					<Carousel :autoplay="false" :autoplay-speed="3000" :radius-dot="true"
						style="width: 100%;height: auto;margin-top: 0;">
						<CarouselItem v-for="(item,index) in Math.ceil(tlist.length/4)" :key="index"
							style="width: calc(1200px + 4vw);height: auto;">
							<div class="switch-box-main switch-box-main1"
								style="margin:28.8px 1vw;width: calc(1200px + 2vw);">
								<div class="switch-tab" v-if="tlist[index*4]" @click="gopdetail(tlist[index*4].id)">
									<div class="switch-tab-img">
										<img :src="tlist[index*4].img_url" alt="">
									</div>
									<div class="switch-tab-info">
										<div class="switch-tab-name">{{tlist[index*4].name}}</div>
										<div class="switch-tab-price">￥{{tlist[index*4].total_price}}</div>
									</div>
								</div>
								<div class="switch-tab" v-if="tlist[index*4+1]" @click="gopdetail(tlist[index*4+1].id)">
									<div class="switch-tab-img">
										<img :src="tlist[index*4+1].img_url" alt="">
									</div>
									<div class="switch-tab-info">
										<div class="switch-tab-name">{{tlist[index*4+1].name}}</div>
										<div class="switch-tab-price">￥{{tlist[index*4+1].total_price}}</div>
									</div>
								</div>
								<div class="switch-tab" v-if="tlist[index*4+2]" @click="gopdetail(tlist[index*4+2].id)">
									<div class="switch-tab-img">
										<img :src="tlist[index*4+2].img_url" alt="">
									</div>
									<div class="switch-tab-info">
										<div class="switch-tab-name">{{tlist[index*4+2].name}}</div>
										<div class="switch-tab-price">￥{{tlist[index*4+2].total_price}}</div>
									</div>
								</div>
								<div class="switch-tab" v-if="tlist[index*4+3]" @click="gopdetail(tlist[index*4+3].id)">
									<div class="switch-tab-img">
										<img :src="tlist[index*4+3].img_url" alt="">
									</div>
									<div class="switch-tab-info">
										<div class="switch-tab-name">{{tlist[index*4+3].name}}</div>
										<div class="switch-tab-price">￥{{tlist[index*4+3].total_price}}</div>
									</div>
								</div>
							</div>
						</CarouselItem>
					</Carousel>
				</div>
				<div class="switch-box-main switch-box-mainq1000 switch-box-main2"
					style="width: 79vw;margin-left: -2vw;">
					<div class="switch-box-main-bg switch-box-main-bgl"></div>
					<div class="switch-box-main-bg switch-box-main-bgr"></div>
					<Carousel :autoplay="false" :autoplay-speed="3000" :radius-dot="true"
						style="width: 100%;height: auto;margin-top: 0;">
						<CarouselItem v-for="(item,index) in Math.ceil(tlist.length/4)" :key="index"
							style="width: calc(1000px + 4vw);height: auto;">
							<div class="switch-box-main switch-box-main1"
								style="margin:2vw 1vw;width: calc(1200px + 2vw);">
								<div class="switch-tab" v-if="tlist[index*4]" @click="gopdetail(tlist[index*4].id)">
									<div class="switch-tab-img">
										<img :src="tlist[index*4].img_url" alt="">
									</div>
									<div class="switch-tab-info">
										<div class="switch-tab-name">{{tlist[index*4].name}}</div>
										<div class="switch-tab-price">￥{{tlist[index*4].total_price}}</div>
									</div>
								</div>
								<div class="switch-tab" v-if="tlist[index*4+1]" @click="gopdetail(tlist[index*4+1].id)">
									<div class="switch-tab-img">
										<img :src="tlist[index*4+1].img_url" alt="">
									</div>
									<div class="switch-tab-info">
										<div class="switch-tab-name">{{tlist[index*4+1].name}}</div>
										<div class="switch-tab-price">￥{{tlist[index*4+1].total_price}}</div>
									</div>
								</div>
								<div class="switch-tab" v-if="tlist[index*4+2]" @click="gopdetail(tlist[index*4+2].id)">
									<div class="switch-tab-img">
										<img :src="tlist[index*4+2].img_url" alt="">
									</div>
									<div class="switch-tab-info">
										<div class="switch-tab-name">{{tlist[index*4+2].name}}</div>
										<div class="switch-tab-price">￥{{tlist[index*4+2].total_price}}</div>
									</div>
								</div>
								<div class="switch-tab" v-if="tlist[index*4+3]" @click="gopdetail(tlist[index*4+3].id)">
									<div class="switch-tab-img">
										<img :src="tlist[index*4+3].img_url" alt="">
									</div>
									<div class="switch-tab-info">
										<div class="switch-tab-name">{{tlist[index*4+3].name}}</div>
										<div class="switch-tab-price">￥{{tlist[index*4+3].total_price}}</div>
									</div>
								</div>
							</div>
						</CarouselItem>
					</Carousel>
				</div>
			</div>
			<div class="meal-three-tabs">
				<img :src="meal_img_text.img1" alt="">
				<img :src="meal_img_text.img2" alt="">
				<!-- <div v-html="meal_img_text.code_block1"></div>
				<div v-html="meal_img_text.code_block2"></div> -->
			</div>
			<div class="meal-advantages"
				:style="'background-image: url('+meal_img_text.img3+');background-repeat: no-repeat;background-size: 100% auto;'">
				<div class="meal-advantages-pros meal-advantages-prosq1200" :style="'background:'+backgroundcolor"
					:class="meal_img_text.popular_science.length==2?'nobtn jiner':meal_img_text.popular_science.length<3?'nobtn':''">
					<div class="meal-advantages-top">
						<div class="meal-researcher" :style="'background:'+backgroundcolor">
							<div class="meal-researcher-title">
								<h3>Researches</h3>
							</div>
							<div class="meal-researcher-msg">营养成分科普</div>
							<div class="change-one-btns" :class="meal_img_text.popular_science.length>2?'':'yincang'"
								:style="meal_img_text.popular_science.length>2?'':'opacity: 0;z-index: 3;'">
								<div class="change-one-last">
									<img src="../../public/imgs/more.png" alt="">
								</div>
								<div class="change-one-next">
									<img src="../../public/imgs/more.png" alt="">
								</div>
							</div>
						</div>
						<div class="meal-researcher-list" :class="meal_img_text.popular_science.length==1?'justone':''">
							<Carousel :autoplay="false" :autoplay-speed="3000" dot="none" :radius-dot="false" style="width: calc(280px + 40px);height: auto;margin-top: 0;">
								<CarouselItem v-for="(item,index) in meal_img_text.popular_science" :key="index"
									style="height: auto;width: calc(280px + 40px);">
									<div class="meal-researcher-box">
										<div class="div1">
											<div class="meal-researcher-name">{{item.goods_name| replaceCommaWithBlank}}</div>
											<div class="meal-researcher-titles">
												<p v-for="(itemm,indexx) in item.popular_science_title.split(' ')"
													:key="indexx">{{itemm}}</p>
											</div>
											<div class="meal-researcher-msgs">{{item.report_content}}</div>
											<div class="meal-researcher-more" @click="gothispro(item)">查看详情
											</div>
										</div>
									</div>
								</CarouselItem>
							</Carousel>
						</div>
					</div>
					<div class="meal-advantages-bottom">
						<div class="meal-advantages-bottom-title">
							参考文献
						</div>
						<div class="meal-advantages-bottom-msg" style="" v-html="meal_img_text.reference">
						</div>
					</div>
				</div>

				<div class="meal-product-list">
					<div class="meal-pc-main-title-box zuobian">
						<div class="meal-pc-main-title Evolve juzhong">
							<div class="meal-pc-main-title-line"></div>
							<h3>{{meal_img_text.advantage_title}}</h3>
						</div>
						<div class="meal-pc-main-msg">
							{{meal_img_text.advantage_title_copy}}
						</div>
						<div class="meal-pc-main-line"></div>
					</div>
					<div class="meal-product-advantages">
						<div v-for="(item,index) in meal_img_text.goods_list" :key="index">
							<div class="meal-product-advantage" v-if="index%2==0">
								<div class="meal-advantage-img">
									<div class="meal-advantage-img-bg"></div>
									<img :src="item.goods_img" alt="">
								</div>
								<div class="meal-advantage-null"></div>
								<div class="meal-advantage-infobox">
									<div class="meal-advantage-info">
										<div class="meal-advantage-info-title">
											<h5>{{item.goods_name_en}}</h5>
											<h5>{{item.goods_name_cn| replaceCommaWithBlank}}</h5>
										</div>
										<div class="meal-advantage-info-msg-list">
											<div class="meal-advantage-info-msg"
												v-for="(items,indexs) in item.advantage" :key="indexs">
												<div class="meal-advantage-info-name">{{items.title}}</div>
												<div class="meal-advantage-info-txt">
													{{items.text}}
												</div>
											</div>
										</div>
										<div class="meal-advantage-info-more cursor" @click="showinfo(item.goods_id)">
											<div>查看100%透明营养成分表</div>
										</div>
									</div>
								</div>
							</div>
							<div class="meal-product-advantage" v-if="index%2==1">
								<div class="meal-advantage-infobox">
									<div class="meal-advantage-info">
										<div class="meal-advantage-info-title">
											<h5>{{item.goods_name_en}}</h5>
											<h5>{{item.goods_name_cn| replaceCommaWithBlank}}</h5>
										</div>
										<div class="meal-advantage-info-msg-list">
											<div class="meal-advantage-info-msg"
												v-for="(items,indexs) in item.advantage" :key="indexs">
												<div class="meal-advantage-info-name">{{items.title}}</div>
												<div class="meal-advantage-info-txt">
													{{items.text}}
												</div>
											</div>
										</div>
										<div class="meal-advantage-info-more cursor" @click="showinfo(item.goods_id)">
											<div>查看100%透明营养成分表</div>
										</div>
									</div>
								</div>
								<div class="meal-advantage-null"></div>
								<div class="meal-advantage-img">
									<div class="meal-advantage-img-bg"></div>
									<img :src="item.goods_img" alt="">
								</div>
							</div>
						</div>
					</div>
					<div class="meal-product-info">
						<div class="meal-product-info-box">
							<div class="meal-product-info-title">
								<h3>Product</h3>
								<h3>Information</h3>
							</div>
							<div class="meal-product-info-msg">产品信息</div>
							<div class="meal-product-info-linel"></div>
							<div class="meal-product-info-list">
								<div class="meal-product-info-line" v-for="(item,index) in meal_img_text.information"
									:key="index">
									<div class="meal-product-info-name">{{item.information_title}}</div>
									<div class="meal-product-info-txt">{{item.information_text}}</div>
								</div>
							</div>
						</div>
						<div class="meal-product-info-img">
							<img :src="meal_img_text.img4" alt="">
						</div>
					</div>
					<div class="qus-cautions">
						<div class="meal-qus">
							<div class="meal-qus-name">
								<div style="position: absolute;top: 0;left: 0;">
									<div class="meal-qus-title">
										<h3>Frequently Asked</h3>
										<h3>Questions</h3>
									</div>
									<div class="meal-qus-msg">常见问题</div>
								</div>
							</div>
							<div class="meal-qus-list">
								<div class="meal-qus-line" v-for="(item,index) in meal_img_text.question_list"
									:key="index" v-show="item.question" @click="item.show=!item.show">
									<div class="meal-qus-line-qus">
										<div class="meal-qus-line-qustxt">Q：{{item.question}}</div>
										<div class="meal-qus-line-qusbtn">
											<span v-if="!item.show" class="iconfont"
												style="font-size:22px;">&#xe615;</span>
											<span v-if="item.show" class="iconfont"
												style="font-size:20px;">&#xe643;</span>
										</div>
									</div>
									<div class="meal-qus-line-answer" v-if="item.show">{{item.answer}}</div>
								</div>
							</div>
							<img class="bgwater" :src="publicurl+'water.png'" alt="">
						</div>
						<div class="meal-cautions">
							<div class="meal-cautions-name">
								<div class="meal-cautions-title">
									<h3>Cautions</h3>
								</div>
								<div class="meal-cautions-msg">注意事项</div>
								<div class="meal-cautions-linel"></div>
								<div class="meal-cautions-list">
									<div class="meal-cautions-line" v-for="(item,index) in meal_img_text.matter"
										:key="index">
										<div class="meal-cautions-line-name">{{item.matter_title}}</div>
										<div class="meal-cautions-line-txt">{{item.matter_text}}</div>
									</div>
								</div>
							</div>
							<div class="meal-cautions-img">
								<img :src="meal_img_text.img6" alt="">
							</div>
						</div>
					</div>
					<div class="meal-center-bg">
						<img :src="publicurl+'xs/ye.png'" alt="">
					</div>
				</div>
			</div>
			<div class="meal-nutripacks">
				<div class="meal-pc-main-title-box zuobian">
					<div class="meal-pc-main-title juzhong">
						<div class="meal-pc-main-title-line"></div>
						<h3>Directions For Use</h3>
					</div>
					<p class="elsetxt meal-qus-msg" style="font-weight: normal;">NUTRIPACKS营养包每日服用指南</p>
					<div class="meal-pc-main-msg" style="margin-top: 32px;">
						NUTRIPACKS营养团队始终秉承科学营养、合理剂量的原则，为避免过量服用营养补剂给身体带来负担，我们为你总结了NUTRIPACKS营养包的混合服用指南，请务必适量服用。
					</div>
					<div class="meal-pc-main-line"></div>
				</div>
				<div class="meal-public-img">
					<img :src="publicurl+'xs/meal_detail.png'" alt="">
				</div>
				<div class="meal-meallist" :class="tlist.length>3?'':'nobtn'">
					<div class="meal-meallist-name">
						<div class="meal-meallist-title">
							<h3>You May</h3>
							<h3>Also Like</h3>
						</div>
						<div class="meal-meallist-msg">看看其它应急营养包</div>
						<div class="change-one-btns" style="left: 12.5vw;"
							:style="tlist.length>3?'':'opacity: 0;z-index: 3;'">
							<div class="change-one-last">
								<img src="../../public/imgs/more.png" alt="">
							</div>
							<div class="change-one-next">
								<img src="../../public/imgs/more.png" alt="">
							</div>
						</div>
					</div>
					<div class="meal-meallist-list">
						<Carousel :autoplay="false" :autoplay-speed="3000" dots="none"
							style="width: 18.53vw;height: auto;margin-top: 0;">
							<CarouselItem v-for="(item,index) in tlist" :key="index"
								style="width: 18.53vw;height: auto;">
								<div class="meal-meallist-box" style="margin: 1vw 1vw 2vw 1vw;">
									<div class="meal-meallist-img cursor" @click="gopdetail(item.id)">
										<img :src="item.img_url" alt="">
									</div>
									<div class="meal-meallist-info">
										<div class="meal-meallist-addthis">
											<div class="meal-meallist-thisname">{{item.name}}</div>
											<div class="div cursor" @click="addthisone(item.id)">
												<img src="../../public/imgs/group1.png" alt="">
											</div>
										</div>
										<div class="meal-meallist-price">
											<div class="meal-meallist-nprice">￥{{item.total_price}}</div>
											<div class="meal-meallist-sellnum">已售{{item.actual_num}}套</div>
										</div>
									</div>
								</div>
							</CarouselItem>
						</Carousel>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'meal_detail',
		components: {
		},
		data: () => ({
			tlist: [],
			prodetails: '',
			showtan: false,
			showtanl: false,
			showtan2: false,
			showtanl2: false,
			backgroundcolor: '#d9c8bf',
			getcolors: [{
					id: 124,
					name: "精力复活包",
					color: '#EADB7E'
				},
				{
					id: 98,
					name: "免疫应急包",
					color: '#E2C7CC'
				},
				{
					id: 100,
					name: "解酒保肝包",
					color: '#B49D90'
				},
				{
					id: 96,
					name: "便秘畅清包",
					color: '#B3C5B7'
				},
				{
					id: 104,
					name: "安神助眠包",
					color: '#96869B'
				},
				{
					id: 97,
					name: "肠道舒缓包",
					color: '#7B96A8'
				},
				{
					id: 99,
					name: "口腔舒缓包",
					color: '#B4C0C8'
				},
				{
					id: 105,
					name: "橘丝带 Kit PRO",
					color: '#CA7C6E'
				},
				{
					id: 90,
					name: "橘丝带 Kit",
					color: '#CA7C6E'
				},
			],
			jiazai: true,
			showitem: '',
			meal_img_text: {},
			other_meal: [],
			publicurl: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/',
			info: '',
			gid: '',
			research: [],
			prodetail: '',
			thisnum: 1,
			bimg: '',
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		mounted() {
			var info = localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')) : ''
			this.info = info
			var that = this
			that.gid = this.$route.query.id
			var getcolor = this.getcolors
			getcolor.forEach((item) => {
				if (item.id == that.$route.query.id) {
					that.backgroundcolor = item.color
				}
			})

			//查看套餐文图信息
			this.$ajax.post(this.API.api.apiurl.getMealImgText, {
					meal_id: that.$route.query.id
				})
				.then(res => {
					var data = res.data
					if (data.code == 0) {
						if(!data.data.id){
							that.$router.replace({
								path: '404',
							})
						}
						// console.log(data)
						that.mdetail = data.data
						var meal_img_text = data.data
						var question_list = meal_img_text.question_list
						for (var p = 0; p < question_list.length; p++) {
							question_list[p].show = false
						}
						meal_img_text.question_list = question_list
						that.meal_img_text = meal_img_text
					} else {
						that.$Message.info('错误：' + data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
			//应急包列表
			this.$ajax.post(this.API.api.apiurl.getJuDaiMealList, {})
				.then(res => {
					var data = res.data
					if (data.code == 0) {
						var list = data.data
						var ll = []
						for (var i = 0; i < list.length; i++) {
							var lt = list[i].mealList
							lt.forEach((item) => {
								if (item.id != that.prodetail.id && item.id != 148) {
									ll.push(item)
								}
							})
						}
						that.tlist = ll
					} else {
						that.$Message.info('错误：' + data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
			this.$ajax.post(this.API.api.apiurl.getMealDetail, {
					meal_id: that.$route.query.id
				})
				.then(res => {
					var data = res.data.data
					if (res.data.code == 0) {
						data.pay_price = Number(data.pay_price.toFixed(2))
						that.prodetail = data
					} else {
						that.$Message.info('错误：' + res.data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
		},
		methods: {
			showinfo(id) {
				var that = this
				var content = this.prodetail.content
				content.forEach((item) => {
					if (item.goods_id == id) {
						that.thisnum = Number(item.num)
					}
				})
				this.$ajax.post(this.API.api.apiurl.getGoodsInfo, {
						goods_id: id
					})
					.then(res => {
						var data = res.data.data
						if (res.data.code == 0) {
							var goods_units = data.goods_detail.goods_unit
							goods_units.forEach((item, index) => {
								var eng = item.unit_eng
								var name = item.unit_name
								var ar = name.split(eng)
								if (eng) {
									goods_units[index].zh = ar[1]
								} else {
									goods_units[index].zh = name
								}
							})
							data.goods_detail.goods_unit = goods_units
							that.prodetails = data

							that.showtan2 = true
							// that.stop()
							// that.lockBody()
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						that.$Message.info(error)
					})
			},
			// 禁止滚动
			stop() {
				var mo = function(e) {
					e.preventDefault()
				}
				let cssStr = 'overflow-y: hidden; height: 100%;'
				document.getElementsByTagName('body')[0].style.cssText = cssStr
				document.documentElement.style.height = '100%'
				document.documentElement.style.overflow = 'hidden'
				document.body.style.cssText = cssStr
				document.addEventListener('touchmove', mo, false) // 禁止页面滑动
			},

			//  取消滑动限制
			move() {
				var mo = function(e) {
					e.preventDefault()
				}
				let cssStr = 'overflow-y: auto; height: auto;'
				document.getElementsByTagName('body')[0].style.cssText = cssStr
				document.documentElement.style.overflow = 'scroll'
				document.body.style.cssText = cssStr
				document.removeEventListener('touchmove', mo, false)
			},
			// 禁止页面滚动
			lockBody() {
				const {
					body
				} = document
				const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
				body.style.position = 'fixed'
				body.style.width = '100%'
				body.style.top = `-${scrollTop}px`
			},
			// 允许页滚动
			resetBody() {
				const {
					body
				} = document
				const {
					top
				} = body.style
				body.style.position = ''
				body.style.width = ''
				body.style.top = ''
				document.body.scrollTop = -parseInt(top, 10)
				document.documentElement.scrollTop = -parseInt(top, 10)
			},

			tlshowtan() {
				if (this.showtanl == true) {
					//console.log('')
				} else {
					this.showtan = false
				}
			},
			lshowtan() {
				var that = this
				this.showtanl = true
				setTimeout(function() {
					that.showtanl = false
				}, 700)
			},
			tlshowtan2() {
				if (this.showtanl2 == true) {
					//console.log('')
				} else {
					// this.move()
					// this.resetBody()
					this.showtan2 = false
				}
			},
			lshowtan2() {
				var that = this
				this.showtanl2 = true
				setTimeout(function() {
					that.showtanl2 = false
				}, 700)
			},
			gothispro(item) {
				//console.log(item)
				this.showitem = item
				this.showtan = true
			},
			compare(key) {
				return function(o1, o2) {
					var a = o1[key],
						b = o2[key];
					return a - b
				}
			},
			daohuo(id) {
				var that = this
				if(that.isAlipay) return
				if (!this.info) {
					that.$Message.info('请先登录')
					that.$router.push({
						path: 'login',
						query: {
							way: 'meal-detail?id=' + that.prodetail.id
						}
					})
				} else {
					this.$ajax.post(this.API.api.apiurl.saveTheArrivalNotice, {
							type: 2,
							ids: [id],
						}, {
							headers: {
								'Authorization': 'Bearer ' + that.info.token,
							}
						})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								//console.log(data)
								window.location.href = 'https://mmbizurl.cn/s/yiDciNLJd'
							} else {
								that.$Message.info('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			addthisone(id) {
				var that = this
				if (!this.info) {
					that.$Message.info('请先登录')
					that.$router.push({
						path: 'login',
						query: {
							way: 'meal-detail?id=' + that.prodetail.id
						}
					})
				} else {
					this.$ajax.post(this.API.api.apiurl.addCart, {
							source: 2,
							buy_id: '',
							count_id: '',
							meal_id: id,
							meal_type: 0,
							goods_id: '',
							goods_num: '',
							is_pro: 1
						}, {
							headers: {
								'Authorization': 'Bearer ' + that.info.token,
							}
						})
						.then(res => {
							if (res.data.code == 0) {
								//console.log(res.data)
								that.$Message.info(res.data.message)
								var l = Number(localStorage.getItem('clength'))
								localStorage.setItem('clength', l + 1)
							} else {
								that.$Message.info('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			addcart() {
				var that = this
				if (!this.info) {
					that.$Message.info('请先登录')
					that.$router.push({
						path: 'login',

						query: {
							way: 'meal-detail?id=' + that.prodetail.id+'&nologin=2'
						}
					})
				} else {
					this.$ajax.post(this.API.api.apiurl.addCart, {
							source: 2,
							buy_id: '',
							count_id: '',
							meal_id: that.prodetail.id,
							meal_type: 0,
							goods_id: '',
							goods_num: '',
							is_pro: 1
						}, {
							headers: {
								'Authorization': 'Bearer ' + that.info.token,
							}
						})
						.then(res => {
							if (res.data.code == 0) {
								//console.log(res.data)
								that.$Message.info(res.data.message)
								var l = Number(localStorage.getItem('clength'))
								localStorage.setItem('clength', l + 1)
							} else {
								that.$Message.info('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			gobuy() {
				var that = this
				var ad = 'meal-detail?id=' + that.prodetail.id+'&nologin=1'
				if (!this.info) {
					that.$Message.info('请先登录')
					that.$router.push({
						path: 'login',
						query: {
							way: ad
						}
					})
				} else {
					this.$ajax.post(this.API.api.apiurl.addCart, {
							source: 2,
							buy_id: '',
							count_id: '',
							meal_id: that.prodetail.id,
							meal_type: 1,
							goods_id: '',
							goods_num: '',
							is_pro: 1
						}, {
							headers: {
								'Authorization': 'Bearer ' + that.info.token,
							}
						})
						.then(res => {
							if (res.data.code == 0) {
								//console.log(res.data)
								var cart_id = res.data.data.cart_id
								that.$router.push({
									path: 'settlement',
									query: {
										cid: cart_id,
										type: 4
									}
								})
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			gopdetail(id) {
				this.jiazai = false
				this.$router.push({
					path: 'meal-detail',
					query: {
						id: id
					}
				})
				location.reload()
			},
			goBack() {
				//console.log('123')
				if (this.$route.query.way == 'report') {
					this.$router.go(-1);
				}
			},
			gohome() {
				this.$router.push({
					path: 'pro_detail'
				})
			},
			gounitdetail(id) {
				this.$router.push({
					path: 'goods-unit',
					query: {
						id: id
					}
				})
			},
			goreportdetail(id) {
				this.$router.push({
					path: 'research',
					query: {
						id: id
					}
				})
			}
		},
	}
</script>

<style>
	@media screen and (max-width:1920px) {
		.bgwater {
			position: absolute;
			height: auto;
			width: 61vw;
			left: 0;
			right: auto;
			/* top: -22vw; */
			z-index: 0;
			/* min-width: 1100px; */
		}
	}

	@media screen and (min-width: 1921px) and (max-width: 2300px) {
		.bgwater {
			position: absolute;
			height: auto;
			width: 61vw;
			/* right: 26vw; */
			left: auto;
			/* top: -20vw; */
			z-index: 0;
		}
	}

	@media screen and (min-width:2301px) {
		.bgwater {
			position: absolute;
			height: auto;
			width: 61vw;
			/* right: 27vw; */
			left: auto;
			/* top: -23vw; */
			z-index: 0;
		}
	}

	@media screen and (max-width:1440px) {
		.meal-qus {
			min-height: 33vw !important;
			height: auto !important;
		}
	}

	@media screen and (max-width:1350px) {
		.jiner .meal-researcher .change-one-btns {
			opacity: 1 !important;
		}

		.jiner .meal-researcher .yincang {
			opacity: 0 !important;
		}
	}

	.nobtn .meal-researcher-list .ivu-carousel button {
		display: none;
	}

	.meal_detail {
		color: #1A1A1A;
		min-height: calc(100vh - 80px);
		padding-bottom: 3.7vw;
		padding-bottom: 54px;
	}

	.meal_detail .maintanc {
		max-width: 568px;
	}

	.meal_detail .maintantxt {
		padding: 0;
	}

	.maintanchuang .meal-researcher-box {
		width: 100%;
		height: auto;
		padding: 20px;
	}

	.maintanchuang .meal-researcher-titles {
		margin: 1vw 0;
	}

	.maintanchuang .meal-researcher-msgs {
		overflow: inherit;
		-webkit-line-clamp: inherit;
	}

	.meal_detail .ivu-carousel-dots-inside {
		display: none !important;
	}

	.ivu-carousel button {
		z-index: 1;
	}

	.switch-box-main .ivu-carousel button {
		width: 2.3vw;
		height: 2.3vw;
		width: 60px;
		height: 60px;
		opacity: 0 !important;
		z-index: 1;
	}

	.switch-box-main .ivu-carousel-arrow.left {
		top: -4.5vw;
		top: calc(-13px - 30px + 7.2px);
		left: auto !important;
		right: calc(4vw + 60px) !important;
	}

	.switch-box-main .ivu-carousel-arrow.right {
		top: -4.5vw;
		top: calc(-13px - 30px + 7.2px);
		right: 2vw !important;
	}


	.meal-pc-banner .ivu-carousel-arrow.right {
		display: none;
	}

	.meal-product-info {
		width: 93vw;
		margin-left: 7vw;
		width: auto;
		margin-left: 12.5vw;
		margin-left: calc(50vw - 600px);
		margin-bottom: 164px;
		display: flex;
		position: relative;
		justify-content: flex-end;
		align-items: center;
		color: #1A1A1A;
		text-align: left;
	}

	.meal-product-info-list {
		margin: 32px 0;
	}

	.meal-product-info-line {
		width: 100%;
		display: flex;
		font-size: 18px;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.meal-product-info-line .meal-product-info-name {
		width: 85px;
		text-align: left;
		margin-right: 10px;
		font-weight: bold;
		display: inline-table;
		line-height: 1.5;
	}

	.meal-product-info-txt {
		width: calc(100% - 85px);
		text-align: left;
		line-height: 1.5;
	}

	.meal-product-info-linel {
		width: 100%;
		height: 1px;
		border-bottom: 2px solid #1A1A1A;
		margin-top: 26px;
	}

	.meal-product-info-title {
		width: auto;
		line-height: 1.2;
		font-family: MADE Evolve;
		font-size: 3vw;
		font-weight: bold;
	}

	.meal-product-info-msg {
		font-size: 24px;
		margin-top: 16px;
	}

	.meal-product-info-img {
		width: 50vw;
		height: 50vw;
		/* background: #EEEEEE; */
		overflow: hidden;
		display: flex;
		align-items: center;
	}

	.meal-product-info-img img {
		width: 100%;
		height: auto;
		align-items: center;
	}

	.meal-product-info-box {
		width: 33vw;
		width: 635px;
		height: auto;
		border-radius: 10px;
		background: #FFFFFF;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
		position: absolute;
		align-items: center;
		left: 0;
		padding: 2.8vw;
	}

	.meal-product-advantages {
		width: 100%;
		height: auto;
		padding: 0 0 5vw 0;
		padding: 0 0 0 0;
		text-align: left;
	}

	.meal-advantage-null {
		width: 7.9vw;
		width: 120px;
		height: auto;
	}

	.meal-advantage-info-txt {
		width: calc(100% - 20px);
		margin-left: 20px;
		text-align: justify;
		font-size: 18px;
	}

	.meal-advantage-info-name {
		width: 100%;
		text-indent: 20px;
		font-weight: bold;
		position: relative;
		margin-bottom: 16px;
	}

	.meal-advantage-info-name:before {
		background: #1A1A1A;
		content: "";
		height: 8px;
		position: absolute;
		top: 10px;
		left: 2px;
		width: 8px;
		border-radius: 8px;
	}

	.meal-advantage-info-more {
		font-size: 18px;
		padding-left: 20px;
	}

	.meal-advantage-info-more div {
		text-decoration: underline;
	}

	.meal-advantage-info-title {
		width: auto;
		font-size: 32px;
		font-weight: bold;
	}

	.meal-advantage-info-msg {
		margin-bottom: 64px;
	}

	.meal-advantage-info-msg-list {
		margin: 32px 0;
		font-size: 18px;
		line-height: 1.5;
	}

	.meal-product-advantage {
		width: calc(100% - 27.5vw);
		width: 1200px;
		height: auto;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8.9vw;
		margin-bottom: 164px;
	}

	.meal-product-advantage .meal-advantage-img {
		width: 30vw;
		height: 30vw;
		border-radius: 30vw;
		width: 540px;
		height: 540px;
		border-radius: 540px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		/* background: #E6EAEE; */
		/* background: #EEEEEE; */
	}

	.meal-advantage-img-bg {
		width: 25vw;
		height: 25vw;
		border-radius: 25vw;
		top: 2.5vw;
		left: 2.5vw;
		width: 480px;
		height: 480px;
		border-radius: 480px;
		top: 30px;
		left: 30px;
		position: absolute;
		background: #E6EAEE;
	}

	.meal-advantage-img img {
		width: auto;
		height: 100%;
		align-items: center;
		margin: 0 auto;
		position: absolute;
	}

	.meal-advantage-infobox {
		width: 33.3vw;
		width: 540px;
		height: auto;
		display: flex;
		align-items: center;
	}

	.meal_detail a {
		color: #1A1A1A;
		text-decoration: underline;
	}

	.meal_detail a:hover {
		color: #1A1A1A;
	}

	.meal-cautions {
		text-align: left;
		width: 93.05vw;
		margin: 0 0 0 6.95vw;
		width: 87.5vw;
		margin: 0 0 0 12.5vw;
		width: calc(50vw + 600px);
		margin: 0 0 0 calc(50vw - 600px);
		margin-top: 164px;
		display: flex;
		justify-content: space-between;
		/* align-items: center; */
		position: relative;
		z-index: 1;
	}

	.meal-cautions-img {
		width: calc(100% - 43.55vw);
		width: calc(100% - 700px);
		height: auto;
		max-height: 48.15vw;
		position: relative;
		display: flex;
		align-items: center;
		z-index: 1;
		/* overflow: hidden; */
		/* background: #EEEEEE; */
	}

	.meal-cautions-img img {
		width: 100%;
		height: auto;
		position: relative;
		z-index: 1;
		/* border-radius: 10px; */
	}

	.meal-cautions-name {
		width: 35.35vw;
		width: 640px;
		height: auto;
		align-items: center;
		z-index: 2;
	}

	.meal-cautions-title {
		font-family: MADE Evolve;
		font-size: 3vw;
		font-weight: bold;
		line-height: 1.2;
	}

	.meal-cautions-msg {
		font-size: 24px;
		margin-top: 16px;
	}

	.meal-cautions-linel {
		width: 100%;
		height: 1px;
		border-bottom: 2px solid #1A1A1A;
		margin: 26px 0;
	}

	.meal-cautions-line {
		font-size: 18px;
		display: flex;
		line-height: 1.5;
		margin-bottom: 16px;
	}

	.meal-cautions-line-name {
		font-weight: bold;
		width: 85px;
		margin-right: 10px;
		display: inline-table;
		text-align: left;
	}

	.meal-cautions-line-txt {
		width: calc(100% - 85px);
	}

	.qus-cautions {
		width: 100%;
		height: auto;
		background: #F5F5F5;
		padding: 164px 0 7.7vw 0;
		padding: 164px 0 110.7px 0;
		color: #1A1A1A;
	}

	.meal-qus {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 0;
		min-height: 29vw;
		height: auto;
		/* align-items: center; */
	}

	.meal-qus-line-qustxt {
		width: calc(100% - 2.1vw);
		height: 6.3vw;
		line-height: 6.3vw;
		height: 120px;
		line-height: 120px;
		font-size: 18px;
		font-weight: bold;
		overflow: hidden;
	}

	.meal-qus-line-answer {
		width: calc(100% - 36px);
		height: auto;
		line-height: 1.5;
		font-size: 16px;
		margin: -10px 0 2vw 32px;
	}

	.meal-qus-line-qusbtn {
		width: 2vw;
		line-height: 6.3vw;
		line-height: 120px;
		color: #C25E4D;
		font-size: 28px;
		cursor: pointer;
		text-align: right;
	}

	.meal-qus-list {
		width: 35.29vw;
		width: 50%;
		height: auto;
		border-top: 2px solid #1A1A1A;
		text-align: left;
		margin-top: 14px;
		position: relative;
		z-index: 1;
	}

	.meal-qus-line {
		border-bottom: 2px solid #1A1A1A;
	}

	.meal-qus-line-qus {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.meal-qus-name {
		width: 500px;
		height: auto;
		text-align: left;
		position: relative;
		/* overflow: hidden; */
	}

	.meal-qus .bgwater {
		/* position: relative; */
		top: auto;
		bottom: -27.6vw;
		min-width: 976px;
	}


	.meal-qus-title {
		font-size: 3vw;
		font-family: MADE Evolve;
		font-weight: bold;
		line-height: 1.2;
	}

	.meal-qus-msg {
		font-size: 24px;
		margin-top: 16px;
	}

	.meal-center-bg {
		position: absolute;
		bottom: -17.6vw;
		left: 0;
		width: 21.35vw;
		max-width: 600px;
		height: auto;
	}

	.meal-center-bg img {
		width: 100%;
		height: auto;
	}

	.meal-meallist-info {
		padding: 1.1vw
	}

	.meal-meallist-addthis {
		/* margin-bottom: 0.5vw; */
	}

	.meal-meallist-addthis,
	.meal-meallist-price {
		display: flex;
		justify-content: space-between;
		height: 1.8vw;
		line-height: 1.8vw;
	}

	#app .meal-meallist-addthis .div {
		/* border: 1px solid #C25E4D; */
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;

	}

	.meal-meallist-addthis .div {
		width: 1.8vw;
		height: 1.8vw;
		/* border-radius: 1.6vw; */
		/* font-size: 28px; */
		text-align: center;
		/* line-height: 2vw; */
		background: #FFFFFF;
		color: #C25E4D;
		margin: 0;
	}

	.meal-meallist-addthis .div img {
		width: 100%;
		height: auto;
	}

	.meal-meallist-thisname {
		font-size: 1.1vw;
		text-align: left;
		height: 1.8vw;
		overflow: hidden;
	}

	.meal-meallist-nprice {
		font-size: 1.1vw;
		font-weight: bold;
	}

	.meal-meallist-sellnum {
		font-size: 0.8vw;
		color: #999999;
	}

	.meal-meallist {
		width: 100%;
		/* margin: 0 0 0 7vw; */
		display: flex;
		justify-content: space-between;
		/* padding-bottom: calc(4.4vw - 50px); */
		overflow: hidden;
	}

	.meal-meallist-name {
		width: 29.15vw;
		padding-left: 7vw;
		width: 31.32vw;
		padding-left: 12.5vw;
		width: calc(50vw - 600px + 300px);
		height: 21.1vw;
		padding: 1vw 0 2vw 0;
		padding-left: calc(50vw - 600px);
		background: #FFFFFF;
		position: relative;
		color: #1A1A1A;
		text-align: left;
		z-index: 1;
		height: auto;
	}

	.meal-meallist-title {
		width: 220px;
		font-size: 3vw;
		line-height: 1;
		font-family: MADE Evolve;
	}

	.meal-meallist-msg {
		font-size: 24px;
		margin-top: 16px;
	}

	.meal-meallist-list {
		width: 68.68vw;
		width: calc(50vw + 600px - 300px);
		height: auto;
		display: flex;
		/* overflow-x: hidden; */
	}

	.meal-meallist-list .ivu-carousel-dots-inside {
		/* width: 100%!important; */
		display: none;
	}

	.meal-meallist-list .ivu-carousel-list {
		width: 70.6vw !important;
		overflow: inherit;
	}

	.meal-meallist-list .ivu-carousel button {
		width: 2.6vw;
		height: 2.6vw;
		width: 60px;
		height: 60px;
		opacity: 0 !important;
		z-index: 1;
	}

	.meal-meallist-list .ivu-carousel .meal-meallist-addthis .div {
		width: 1.8vw;
		height: 1.8vw;
	}

	.meal-meallist-list .meal-meallist-addthis .div {
		opacity: 1 !important;
	}

	.meal-meallist-list .ivu-carousel-arrow.left {
		top: auto !important;
		bottom: 1vw !important;
		left: -18vw !important;
		left: -300px !important;
	}

	.meal-meallist-list .ivu-carousel-arrow.right {
		top: auto !important;
		bottom: 1vw !important;
		left: -13vw !important;
		left: calc(2vw - 300px + 60px) !important;
		right: auto !important;
	}

	.meal-meallist-box {
		width: 16.53vw;
		height: auto;
		background: #FFFFFF;
		border-radius: 10px;
		overflow: hidden;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
		display: inline-table;
		margin-right: 2.8vw;
	}

	.meal-meallist-img {
		width: 100%;
		height: 12.5vw;
		height: auto;
		overflow: hidden;
		display: flex;
		align-items: center;
		/* background: #EEEEEE; */
	}

	.meal-meallist-img img {
		width: 100%;
		height: auto;
		align-items: center;
	}

	.meal-meallist-info {
		width: 100%;
		height: 5.6vw;
	}

	.meal-nutripacks {
		padding-top: 164px;
		background: #FFFFFF;
	}

	.meal-nutripacks .meal-pc-main-title {
		font-size: 3vw;
	}

	.meal-nutripacks .meal-pc-main-msg {
		font-size: 18px;
		width: 601px;
		margin: 0 auto;
		margin-top: 16px;
		/* margin-top: 3vw; */
	}

	.meal-public-img {
		width: calc(100% - 48vw);
		max-width: 1200px;
		margin: 0 auto;
		margin-bottom: 164px;
	}

	.meal-public-img img {
		width: 100%;
		height: auto;
	}

	.meal-product-list {
		margin-top: 164px;
		position: relative;
	}

	.Evolve {
		font-family: MADE Evolve;
	}

	.meal-advantages {
		width: 100%;
		height: auto;
		position: relative;
		padding-top: 17vw;
		background: #FFFFFF;
	}

	.meal-advantages-bg-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}

	.meal-advantages-bg-img img {
		width: 100%;
		height: auto;
	}

	.meal-advantages-pros {
		width: 1200px;
		width: 1200px;
		margin: 0 auto;
		border-radius: 10px;
		background: #D9C8BF;
		text-align: left;
		overflow: hidden;
		box-shadow: 0px 10px 20px 0px rgba(202, 176, 162, 0.14);
	}

	.meal-advantages-top {
		width: 100%;
		padding: 2.8vw 0 2.8vw 0;
		padding: 40px 0 40px 0;
		padding: 54px 0 54px 0;
		/* padding-bottom: 4.45vw; */
		display: flex;
		justify-content: space-between;
	}

	.meal-researcher {
		position: relative;
		width: calc(75vw - 44.7vw);
		width: calc(1200px - 710px);
		padding-left: 2.8vw;
		padding-left: 40px;
		padding-left: 54px;
		background: #D9C8BF;
		z-index: 1;
	}

	.change-one-btns {
		position: absolute;
		width: auto;
		bottom: 2vw;
		bottom: 30px;
		left: 2.8vw;
		left: calc(50vw - 600px) !important;
		display: flex;
		justify-content: space-between;
	}

	.meal-advantages-top .change-one-btns {
		left: 54px !important;
	}

	.meal-meallist .change-one-btns {
		bottom: calc(30px + 1vw);
	}

	.change-one-btns div {
		width: 60px;
		height: 60px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.nobtn .change-one-btns div {
		width: 3.3vw;
		height: 3.3vw;
		cursor: default;
		display: flex;
		align-items: center;
	}

	.change-one-btns div img {
		align-items: center;
		width: 2.3vw;
		width: 40px;
		height: auto;
		margin: 0 10px;
	}
		.meal-meallist-name .change-one-btns .change-one-last img{
			margin: 0 0 0 20px;
		}

	.change-one-last {
		transform: rotate(180deg);
		margin-right: 2vw;
	}

	.meal-researcher-list {
		width: 44.7vw;
		width: 710px;
		height: auto;
		display: flex;
		/* overflow: hidden; */
	}

	.meal-researcher-list .ivu-carousel-dots-inside {
		/* width: 100%!important; */
		display: none;
	}

	.meal-researcher-list .ivu-carousel-list {
		width: 70.6vw !important;
		overflow: inherit;
	}

	.meal-researcher-list .ivu-carousel button {
		width: 2.5vw;
		height: 2.5vw;
		width: 60px;
		height: 60px;
		opacity: 0 !important;
		z-index: 1;
	}


	.meal-researcher-list .ivu-carousel-arrow.left {
		top: auto !important;
		bottom: 1vw !important;
		bottom: 0px !important;
		left: calc(0px - 1200px + 710px + 2.8vw) !important;
	}

	.meal-researcher-list .ivu-carousel-arrow.right {
		top: auto !important;
		bottom: 1vw !important;
		bottom: 0px !important;
		left: calc(0px - 1200px + 710px + 60px + 4.8vw) !important;
		right: auto !important;
	}

	.meal-researcher-box {
		width: 19.45vw;
		height: 26vw;
		border-radius: 10px;
		background: #FFFFFF;
		margin-right: 2.8vw;
		display: inline-table;
		padding: 2.1vw 2.1vw;
		color: #1A1A1A;
		text-align: left;
		align-items: center;
		display: flex;
		width: 280px;
		/* height: 438px; */
		height: 380px;
		padding: 30px 30px;
	}

	.meal-researcher-box .div1 {
		align-items: center;
		width: 100%;
		height: auto;
	}

	.meal-researcher-name {
		font-size: 18px;
		font-weight: bold;
	}

	.meal-researcher-titles {
		font-size: 24px;
		height: 57.6px;
		font-weight: bold;
		margin: 0.05vw 0;
		line-height: 1;
	}

	.meal-researcher-titles p {
		overflow: hidden;
		height: 28.8px;
		line-height: 28.8px;
	}

	@media screen and (min-width:1300px) {
		.meal-researcher-titles {
			font-size: 24px;
			font-weight: bold;
			margin: 1vw 0;
			line-height: 1;
		}

		.meal-researcher-titles p {
			overflow: hidden;
			/* height: 38.4px; */
		}
	}

	.meal-researcher-more {
		font-size: 18px;
		text-decoration: underline;
		/* font-weight: 500; */
		cursor: pointer;
	}

	.meal-researcher-msgs {
		font-size: 18px;
		color: #666666;
		text-align: justify;
		margin-bottom: 2.2vw;
		line-height: 1.5;
		height: 137px;
		overflow: hidden;
		display: -webkit-box;
		/*  display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示  */
		-webkit-box-orient: vertical;
		/*  -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式  */
		-webkit-line-clamp: 5;
		/*  -webkit-line-clamp用来限制在一个块元素显示的文本的行数 */
		overflow: hidden;
	}

	.meal-advantages-bottom {
		width: 75%;
		padding: 0 2.8vw 2.8vw 2.8vw;
		padding: 0 40px 40px 40px;
		padding: 0 54px 54px 54px;
		/* padding-top: 4.45vw; */
		/* border-top: 2px solid #1A1A1A; */
	}

	.meal-advantages-bottom-title {
		font-size: 18px;
		line-height: 1.5;
		margin-bottom: 1.1vw;
		margin-bottom: 20px;
		font-weight: medium;
	}

	.meal-advantages-bottom-msg {
		font-size: 14px;
		line-height: 1.5;
	}

	.meal-advantages-bottom-msg li {
		margin-left: 15px;
	}

	.meal-advantages-pros-top {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.meal-researcher-title {
		font-size: 3vw;
		font-weight: bold;
		line-height: 1.2;
		font-family: MADE Evolve;
	}

	.meal-researcher-msg {
		font-size: 24px;
		margin-top: 16px;
	}

	.meal-explain {
		margin-top: 164px !important;
		padding-bottom: 8.4vw;
		padding-bottom: 120.8px;
	}

	.meal-explain .meal-pc-main-title-box {
		margin-bottom: 82px !important;
	}

	.meal-explain-main {
		width: calc(86vw - 11vw - 180px);
		width: 1200px;
		height: auto;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		/* align-items: center; */
	}

	.meal-explain-img {
		width: 40vw;
		width: 60%;
		height: 40vw;
		height: auto;
		display: flex;
		align-items: center;
		overflow: hidden;
		/* background: #EEEEEE; */
	}

	.meal-explain-img img {
		align-items: center;
		width: 100%;
		height: auto;
	}

	.meal-explain-list {
		width: 21.8vw;
		width: 34%;
		;
		height: auto;
		align-items: center;
	}

	.meal-explain-box {
		width: 100%;
		margin-bottom: 3.2vw;
		margin-bottom: 46px;
		padding-left: 50px;
		padding-left: 0;
		padding-top: 18px;
		position: relative;
		text-align: left;
	}

	.meal-explain-bg {
		font-size: 144px;
		/* font-weight: bold; */
		position: absolute;
		top: 50%;
		margin-top: -30px;
		left: 0;
		z-index: 0;
		opacity: 0.7;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		font-family: MADE Evolve;
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		display: none;
	}

	.meal-explain-title {
		width: 100%;
		font-size: 24px;
		line-height: 1.5;
		margin-bottom: 16px;
		font-weight: bold;
		position: relative;
		z-index: 1;
	}

	.meal-explain-msg {
		width: 100%;
		font-size: 18px;
		z-index: 2;
		position: inherit;
		text-align: justify;
		line-height: 1.5;
	}

	.index1 {
		color: #E6EAEE;
	}

	.index2 {
		color: #D9C8BF;
	}

	.index3 {
		color: #DCE0DC;
	}

	.meal-three-tabs {
		width: 100%;
		height: auto;
		margin-top: 9.4vw;
		margin-top: 135px;
		padding: 164px 0 0 0;
		background: #FFFFFF;
	}

	.meal-three-tabs img {
		width: 75%;
		height: auto;
	}

	.meal-three-ttt {
		width: 100%;
		height: auto;
	}

	.meal-three-ttt img {
		width: 100%;
		height: auto;
		width: 86%;
		width: 75%;
		margin: 0 auto;
	}

	.meal-tabs-list {
		width: 1200px;
		width: 1200px;
		margin: 0 auto;
		display: flex;
		/* margin-top: 1vw; */
		/* justify-content: space-between; */
		justify-content: space-evenly;
	}

	.meal-tabs-box {
		width: 25vw;
		width: 30%;
		height: auto;
		/* border-radius: 10px;
		background: #FFFFFF; */
		padding: 0 2.1vw;
		padding: 0 30px;
		text-align: center;
	}

	.meal-tabs-box-img {
		width: 13vw;
		height: 13vw;
		width: 250px;
		height: 250px;
		margin: 0 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}

	.meal-tabs-box-img img {
		width: 100%;
		width: 100%;
		height: auto;
		align-items: center;
		/* margin: 0 1vw; */
	}

	.meal-tabs-box-txt {
		width: 100%;
		font-size: 24px;
		margin: 16px 0;
		font-weight: bold;
		line-height: 1.5;
		/* text-align: left; */
	}

	.meal-tabs-box-msg {
		width: 100%;
		/* height: 54px; */
		font-size: 18px;
		line-height: 1.5;
		/* text-align: justify; */
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}

	.switch-box {
		width: 1200px;
		margin: 0 auto;
		margin-top: 6.9vw;
		margin-top: 82px;
	}

	.switch-box-main {
		width: 100%;
		display: flex;
		justify-content: left;
		margin-top: 3.15vw;
		margin-top: 45px;
		margin-top: 13px;
	}

	.switch-tab {
		width: 19.95vw;
		width: 17.25vw;
		height: auto;
		border-radius: 10px;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
		overflow: hidden;
		cursor: pointer;
		margin: 0 1vw;
		margin: 0 14.4px;
	}

	.switch-tab-img {
		width: 100%;
		height: 12.9vw;
		height: calc(225px - 1.125vw);
		height: auto;
		overflow: hidden;
		display: flex;
		align-items: center;
		/* background: #EEEEEE; */
	}

	.switch-tab-img img {
		width: 100%;
		height: auto;
		align-items: center;
	}

	.switch-tab-info {
		width: 100%;
		padding: 0 20px;
		line-height: 6.65vw;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 24px;
	}

	.switch-tab-info div {
		font-size: 22px;
		align-items: center;
		height: 3.65vw;
		line-height: 3.65vw;
		height: auto;
		line-height: 3;
		overflow: hidden;
	}

	.switch-tab-name {
		text-align: left;
		max-width: 60%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.switch-tab-price {
		text-align: right;
		max-width: 40%;
		overflow: hidden;
	}

	.switch-box-title {
		position: relative;
	}

	.switch-box-name {
		font-size: 48px;
		font-weight: bold;
		text-align: left;
		line-height: 1.2;
		font-family: MADE Evolve;
	}

	.switch-box-msg {
		font-size: 24px;
		text-align: left;
		margin-top: 16px;
	}

	.switch-box-btns {
		width: auto;
		position: absolute;
		right: 0;
		bottom: -0.5vw;
		display: flex;
		justify-content: space-between;
	}

	.switch-box-btns div {
		width: 3.3vw;
		height: 3.3vw;
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.nbtn .switch-box-btns div {
		width: 3.3vw;
		height: 3.3vw;
		display: flex;
		align-items: center;
		cursor: default;
	}

	.switch-box-btns div img {
		width: 2.3vw;
		width: 40px;
		margin: 0 10px;
		width: 30px;
		margin: 0 15px;
		height: auto;
		align-items: center;
	}

	.meal-researcher .change-one-btns .change-one-last img{
		margin:  0 0 0 20px;
	}

	.switch-box-btns .switch-box-last {
		transform: rotate(180deg);
		margin-right: 2vw;
	}

	.meal-pc-banner {
		width: 100vw;
		height: 47.5vw;
		min-height: 600px;
		display: flex;
		justify-content: space-between;
		color: #1A1A1A;
	}

	.meal-pc-banner-imgs {
		width: 60vw;
		height: 47.5vw;
		min-height: 600px;
		background: #EEEEEE;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.meal-pc-banner-imgs img {
		width: 100%;
		height: auto;
		align-items: center;
	}

	.meal-pc-banner-info {
		width: 36vw;
		height: 42vw;
		min-height: 600px;
		/* padding-left: 2.25vw; */
		margin: 0 5vw;
		text-align: left;
		align-items: center;
		display: flex;
		position: relative;
	}

	.the-price-btn {
		position: absolute;
		bottom: 0.4vw;
		left: 2.25vw;
	}

	.meal-pc-banner-infobox {
		align-items: center;
		/* margin-top: 164px; */
	}

	.meal-pc-banner-title {
		font-size: 64px;
		font-weight: bold;
		text-align: left;
		font-family: MADE Evolve;
		line-height: 1.1;
	}

	.meal-pc-banner-title2 {
		font-size: 32px;
		font-weight: bold;
		margin: 16px 0;
	}

	.meal-pc-banner-msg {
		font-size: 18px;
	}

	.meal-pc-banner-li {
		font-size: 18px;
		line-height: 1.2;
		margin: 40px 0;
	}

	.meal-pc-banner-btn {
		height: 60px;
		border-radius: 60px;
		width: 24vw;
		display: flex;
		justify-content: center;
		background: #C25E4D;
		/* overflow: hidden; */
		position: relative;
		align-items: center;
	}

	.meal-pc-banner-sellnum {
		position: absolute;
		right: -160px;
		/* bottom: 0; */
		width: 140px;
		align-items: center;
	}

	.meal-pc-banner-prices {
		width: calc(50% - 2px);
		text-align: center;
		color: #FFFFFF;
		height: 44px;
		margin: 8px 0;
	}

	.meal-pc-banner-prices .meal-pc-banner-nprice {
		font-size: 32px;
		line-height: 1;
		height: 32px;
		font-weight: bold;
	}

	.meal-pc-banner-pricess .meal-pc-banner-nprice {
		font-size: 32px;
		line-height: 44px;
		/* height: 32px; */
		font-weight: bold;
	}

	.meal-pc-banner-nprice span {
		font-size: 20px;
	}

	.meal-pc-banner-prices .meal-pc-banner-oprice {
		font-size: 12px;
		text-decoration: line-through;
		font-weight: regular;
	}

	.meal-pc-banner-btn-center {
		width: 1px;
		height: 40px;
		margin: 10px 0;
		background: #D97767;
	}

	.meal-pc-banner-btn button {
		width: calc(50% - 2px);
		text-align: center;
		line-height: 60px;
		height: 60px;
		background: none;
		color: #FFFFFF;
		font-size: 18px;
		border-radius: 0 60px 60px 0;
	}

	.meal-pc-banner-sellnum {
		/* width: 100%; */
		text-align: left;
		font-size: 16px;
		color: #666666;
		/* margin-top: 0.7vw; */
	}

	.li-line {
		position: relative;
		text-indent: 16px;
		line-height: 1.8;
	}

	.li-line:before {
		content: "";
		background: #1A1A1A;
		content: "";
		height: 8px;
		position: absolute;
		top: 12.2px;
		left: 0;
		width: 8px;
		border-radius: 8px;
	}

	.meal-pc-main-title-box {
		width: 100%;
		height: auto;
		text-align: center;
		position: relative;
		margin-bottom: 5vw !important;
		margin-bottom: 72px !important;
	}

	.juzhong {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.meal-pc-main-title {
		font-size: 3vw;
		font-weight: bold;
		line-height: 1.2;


	}

	.meal-pc-main-title-line {
		width: 10px;
		height: 2.6vw;
		max-height: 70px;
		min-height: 50px;
		margin-left: -1vw;
		margin-right: 1vw;
		background: #C25E4D;
	}

	.meal-pc-main-msg {
		font-size: 24px;
		margin-top: calc(0.55vw + 5px);
		margin-top: 16px;
		/* margin-top: 3vw; */
		line-height: 1.5;
	}

	.zuobian {
		margin-bottom: 82px;
	}

	.zuobian .meal-pc-main-line {
		display: none;
	}

	.meal-pc-main-line {
		width: 100px;
		height: 12px;
		margin: 0 auto;
		margin-top: 1.5vw;
		background: #C25E4D;
		margin-bottom: 82px;
	}

	@media screen and (max-width:1023px) {
		.meal_detail .mb_box {
			display: block;
		}

		.meal_detail .pc_box {
			display: none;
		}
	}

	@media screen and (min-width:1024px) {
		.meal_detail .mb_box {
			display: none;
		}

		.meal_detail .pc_box {
			display: block;
			width: 100%;
			background: #F5F5F5;
		}
	}






	.mealbg {
		width: 100vw;
		height: 100vh;
		background: #F8F8F8;
		z-index: -1;
		position: fixed;
		top: 0;
		left: 0;
	}

	.mealdetail {
		width: 100%;
		max-width: 10rem;
		margin: 0 auto;
		padding-bottom: 1.8rem;
		background: #f8f8f8;
	}

	.roll_img {
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
	}

	.actual_num {
		position: absolute;
		bottom: 0;
		right: 0;
		width: auto;
		line-height: 0.61rem;
		font-size: 0.28rem;
		padding: 0 0.5rem;
		background: #C25E4D;
		color: #FFFFFF;
	}

	.mealdetailinfo {
		width: 100vw;
		max-width: 10rem;
		margin: 0 auto;
	}

	.mealdetailinfo img {
		width: 100%;
		max-width: 10rem;
		height: auto;
	}

	.meal_info {
		width: 100%;
		margin: 0 auto;
		text-align: center;
	}

	.meal_name {
		font-size: 0.64rem;
		line-height: 0.81rem;
		font-weight: bold;
		margin-top: 0.7rem;
	}

	.meal_list {
		font-size: 0.32rem;
		line-height: 0.51rem;
		margin: 0.3rem 0;
	}

	.meal_price {
		font-size: 0.3rem;
		margin-bottom: 0.7rem;
	}

	.paycart-txts {
		width: auto;
		height: 1rem;
		text-align: right;
		padding: 0.1rem 0;
	}

	.noyuan {
		padding: 0.3rem 0;
	}

	.deltxtss {
		width: 100%;
		text-align: right;
		font-size: 0.2rem;
		line-height: 0.39rem;
		margin-left: 0.2rem;
		color: #999999;
	}

	.meal_price span {
		font-size: 0.42rem;
		/* font-weight: bold; */
	}

	.buy-bottom {
		width: 100%;
		max-width: 100vw;
		/* margin: 0 auto; */
		height: 1.8rem;
		background: #FFFFFF;
		position: fixed;
		bottom: 0;
		left: 0;
		margin-left: 0;
		box-shadow: 0rem -0.06rem 0.20rem 0rem rgba(0, 0, 0, 0.08);
		z-index: 11;
	}

	@media screen and (min-width:1001px) {
		.meal_detail .ivu-carousel-track {
			display: flex;
			width: auto !important;
			overflow: inherit;
		}
	}

	@media screen and (max-width:1000px) {
		.buy-bottom {
			width: 100%;
			max-width: 10rem;
			/* margin: 0 auto; */
			height: 1.8rem;
			background: #FFFFFF;
			position: fixed;
			bottom: 0;
			left: 50%;
			margin-left: -5rem;
			box-shadow: 0rem -0.06rem 0.20rem 0rem rgba(0, 0, 0, 0.08);
			z-index: 11;
		}

		.goods_unit-maintantxt {
			padding-top: 0 !important;
		}

		.ivu-icon {
			margin-top: 0 !important;
		}

		.ivu-carousel .ivu-carousel-arrow {
			font-size: 16px !important;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.mealprice {
		width: 90%;
		max-width: 10rem;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		padding: 0.3rem 0;
	}

	.mealprice .mprice {
		width: auto;
		height: auto;
	}

	.mprice .tprice {
		width: 100%;
		height: 0.71rem;
		font-size: 0.2rem;
		text-align: left;
	}

	.mprice .tprice span {
		font-size: 0.5rem;
		font-weight: bold;
	}

	.mprice .pmsg {
		width: 100%;
		line-height: 0.49rem;
		font-size: 0.3rem;
		color: rgb(155, 155, 155);
	}

	.mbtns {
		width: auto;
		display: flex;
	}

	.mbtns a {
		text-decoration: none !important;
	}

	.mbtns .zm:hover {
		color: #FFFFFF;
	}

	.mbtns a {
		width: 2.6rem;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.3rem;
		background: #C25E4D;
		color: #FFFFFF;
		border-radius: 1rem;
		margin: 0.1rem 0;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(194, 94, 77, 0.25);
	}

	.mbtns .ac {
		color: #C25E4D;
		background: #FFFFFF;
		box-shadow: 0rem 0.09rem 0.15rem 0rem rgba(0, 0, 0, 0.1);
		margin-right: 0.3rem;
	}

	.mbtns .tz {
		color: #C25E4D;
		border: 0.01rem solid #C25E4D;
		background: #FFFFFF;
		box-shadow: none;
	}

	@media screen and (min-width:1921px) {

		/* .meal-tabs-box-msg,.meal-explain-msg{
			font-size: 1vw;
			font-size: 18px;
		}
		.meal-tabs-box-txt,.meal-explain-title{
			font-size: 1.2vw;
			font-size: 24px;
			font-weight: bold;
		} */
		.meal-pc-banner-info {
			width: 30vw;
			padding-left: 0;
			margin: 0 5vw;
		}

		.the-price-btn {
			left: 0;
		}

		.meal-pc-banner-title {
			font-size: 4vw;
			font-size: 72px;
		}

		.meal-pc-banner-title2 {
			font-size: 2vw;
			font-size: 40px;
			font-weight: bold;
		}

		.meal-pc-banner-msg {
			font-size: 1vw;
			/* width: 90%; */
			font-size: 24px;
		}

		.meal-pc-banner-li {
			font-size: 0.8vw;
			font-size: 24px;
		}

		.meal-pc-banner-btn {
			height: 4.15vw;
			max-width: 550px;
			border-radius: 4.15vw;
		}

		.meal-pc-banner-prices {
			height: 3.2vw;
			line-height: 3.2vw;
			margin: 0.475vw 0;
			font-size: 2vw;
		}

		.meal-pc-banner-pricess .meal-pc-banner-nprice {
			font-size: 2vw;
			line-height: 3.2vw;
			font-size: 40px;
		}

		.meal-pc-banner-prices .meal-pc-banner-nprice {
			font-size: 2vw;
			/* line-height: 2.8vw; */
			font-size: 40px;
		}

		.meal-pc-banner-prices .meal-pc-banner-oprice {
			font-size: 0.8vw;
			font-size: 24px;
		}

		.meal-pc-banner-sellnum {
			font-size: 1vw;
			font-size: 24px;
		}

		.meal-pc-banner-btn-center {
			height: 2.8vw;
			margin: 0.675vw 0;
		}

		.meal-pc-banner-btn button {
			font-size: 1vw;
			height: 4.15vw;
			line-height: 4.15vw;
			font-size: 32px;
		}

		.meal-pc-banner-li {
			margin: 3.35vw 0 3.6vw 0;
		}

		.switch-box-name {
			font-size: 2vw;
			/* font-size: 40px; */
		}

		.switch-box-msg {
			font-size: 1vw;
		}

		.switch-tab-info {
			font-size: 24px;
		}

		.meal-researcher-title {
			font-size: 3vw;
			font-size: 56px;
		}

		.meal-researcher-msg {
			font-size: 1vw;
			font-size: 24px;
		}

		.meal-researcher-name {
			font-size: 18px;
			line-height: 1.5;
		}

		.meal-researcher-box {
			width: 280px;
			/* height: 438px; */
			height: 380px;
			padding: 30px 30px;
		}

		.meal-researcher-titles {
			font-size: 1.65vw;
			/* height: 5.28vw; */
			line-height: 1.5;
			font-size: 24px;
		}

		.meal-researcher-list {
			height: 403px;
		}

		.meal-researcher-titles p {
			/* height: 2.64vw; */
			line-height: 1.2;
		}

		.meal-researcher-msgs,
		.meal-researcher-more {
			font-size: 0.8vw;
			font-size: 18px;
		}

		.meal-researcher-msgs {
			font-size: 0.8vw;
			height: 6.3vw;
			height: 135px;
			margin-bottom: 10px;
			/* line-height: 1.28vw; */
			display: -webkit-box;
			/*  display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示  */
			-webkit-box-orient: vertical;
			/*  -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式  */
			-webkit-line-clamp: 5;
			/*  -webkit-line-clamp用来限制在一个块元素显示的文本的行数 */
			overflow: hidden;
			font-size: 18px;
		}

		.meal-advantages-bottom-title {
			font-size: 1vw;
			font-size: 24px;
		}

		.meal-advantages-bottom-msg {
			font-size: 1vw;
			font-size: 24px;
		}

		.meal-advantages-bottom-msg li {
			margin-left: 1vw;
		}

		.meal-pc-main-title {
			font-size: 4vw;
			font-size: 64px !important;
		}

		.meal-pc-main-msg {
			font-size: 1vw;
			font-size: 24px;
		}

		.meal-advantage-info-title {
			/* font-size: 2.2vw; */
			font-size: 2vw;
			font-size: 40px;
			line-height: 1.5;
		}

		.meal-advantage-info-msg-list {
			font-size: 0.8vw;
			font-size: 20px;
		}

		.meal-advantage-info-txt {
			width: calc(100% - 20px);
			margin-left: 20px;
		}

		.meal-advantage-info-name {
			font-size: 1vw;
			font-size: 24px;
			text-indent: 20px;
		}

		.meal-advantage-info-name:before {
			/* top: calc(0.8vw - 4px); */
			top: 12px;
		}

		.meal-advantage-info-more {
			font-size: 0.8vw;
			font-size: 20px;
			padding-left: 20px;
		}

		.meal-product-info-title {
			font-size: 3vw;
			font-size: 56px;
		}

		.meal-product-info-msg {
			font-size: 1vw;
			font-size: 24px;
			margin-top: 0.55vw;
		}

		.meal-product-info-line {
			font-size: 0.8vw;
			font-size: 20px;
		}

		.meal-product-info-line .meal-product-info-name {
			/* width: 4.2vw; */
			margin-right: 1.1vw;
			margin-right: 10px;
		}

		.meal-product-info-linel {
			margin-top: 1.2vw;
			margin-top: 15px;
		}

		.meal-product-info-list {
			/* margin-top: 2.2vw; */
		}

		.meal-qus-title,
		.meal-cautions-title {
			font-size: 4vw;
			font-size: 64px;
		}

		.meal-qus-name {
			width: 30.3vw;
		}

		.meal-qus-msg,
		.meal-cautions-msg {
			font-size: 1vw;
			font-size: 24px;
			margin-top: 0.55vw;
		}

		.meal-qus-line-qustxt {
			font-size: 1vw;
			font-size: 24px;
		}

		.meal-qus-line-qusbtn {
			/* font-size: 2vw; */
			text-align: right;
		}

		.meal-qus-line-answer {
			font-size: 0.8vw;
			font-size: 20px;
			margin-left: 1.8vw;
		}

		.meal-cautions-line {
			font-size: 0.8vw;
			font-size: 20px;
		}

		.meal-cautions-linel {
			margin: 1.2vw 0;
		}

		.meal-cautions-line-name {
			width: 4.2vw;
			margin-right: 1.1vw;
		}

		.meal-nutripacks .meal-pc-main-title {
			font-size: 3vw;
			font-size: 56px;
			line-height: 1.2;
		}

		.meal-nutripacks .meal-pc-main-msg {
			width: 41.65vw;
			font-size: 1vw;
			font-size: 24px;
		}

		.meal-meallist-name {
			/* width: 15.15vw; */
		}

		.meal-meallist-title {
			font-size: 3vw;
			font-size: 56px;
			width: auto;
		}

		.meal-meallist-msg {
			font-size: 1vw;
			font-size: 24px;
		}

		.meal-meallist-thisname,
		.meal-meallist-nprice,
		#app .meal-meallist-addthis .div {
			font-size: 1.25vw;
			font-size: 24px;
		}

		.meal-meallist-sellnum {
			font-size: 0.8vw;
			font-size: 20px;
		}

		.meal-meallist-addthis .div {
			line-height: 3.1vw;
		}

		.meal-cautions-title,
		.meal-qus-title {
			/* font-size: 3vw; */
		}

		.li-line {
			margin-bottom: 5px;
		}

		.meal-pc-banner-infobox {
			align-items: auto;
			margin-top: 164px;
		}

		.li-line:before {
			top: 17.6px;
		}

		.meal-pc-main-msg {
			margin-top: 0.55vw;
		}

		.meal-meallist-msg {
			margin-top: 0.55vw;
		}
	}

	.justone .ivu-carousel-list {
		width: 44.7vw !important;
		display: flex;
		justify-content: flex-end;
	}

	/* @media screen and (min-width:1700px) {
		.dasan .change-one-btns div {
			width: 3.3vw;
			height: 3.3vw;
			cursor: default;
			display: flex;
			align-items: center;
		}
		.dasan .change-one-btns{
			opacity: 0;z-index: 3;
		}
	} */
	@media screen and (min-width:2150px) {
		.dasi .change-one-btns div {
			width: 3.3vw;
			height: 3.3vw;
			cursor: default;
			display: flex;
			align-items: center;
		}

		.dasi .change-one-btns {
			opacity: 0;
			z-index: 3;
		}
	}



	.switch-box-main .ivu-carousel-list {
		overflow: inherit;
	}

	.switch-box-main2 {
		position: relative;
	}

	.switch-box-main-bg {
		position: absolute;
		width: calc(50vw - 600px);
		height: 100%;
		top: 0;
		background: #F5F5F5;
		z-index: 2;
	}

	.switch-box-main-bgl {
		left: calc(600px - 50vw);
	}

	.switch-box-main-bgr {
		right: calc(600px - 50vw);
	}




	.switch-box {
		width: 1200px;
	}

	.switch-box-main {
		width: calc(1200px + 4vw) !important;
	}

	.switch-tab {
		width: calc(300px - 1.5vw);
		width: calc(300px - 21.5px);
	}

	.switch-box-mainq1000 {
		display: none;
	}

	.switch-box-mainq1200 {
		display: block;
	}

	@media screen and (max-width:1440px) {

		.meal-meallist .change-one-btns {
			bottom: calc(30px + 1vw);
			bottom: 10px;
		}

		.meal-meallist-list .ivu-carousel-arrow.left {
			top: auto !important;
			bottom: 1vw !important;
			left: -18vw !important;
			left: -280px !important;
			bottom: -20px !important;
			left: -300px !important;
		}

		.meal-meallist-list .ivu-carousel-arrow.right {
			top: auto !important;
			bottom: 1vw !important;
			left: -13vw !important;
			left: calc(2vw - 280px + 60px) !important;
			right: auto !important;
			bottom: -20px !important;
			left: calc(2vw - 300px + 60px) !important;
		}

		.switch-box {
			width: 1000px;
		}

		.switch-tab {
			width: calc(250px - 1.5vw);
			width: calc(250px - 21.5px);
		}

		.switch-tab-img {
			height: calc(187.5px - 1.125vw);
			height: auto;
		}

		.switch-box-mainq1000 {
			display: block;
		}

		.switch-box-mainq1200 {
			display: none;
		}

		.switch-box-main {
			width: calc(1000px + 4vw) !important;
		}

		.switch-box-main-bg {
			width: calc(50vw - 500px);
		}

		.switch-box-main-bgl {
			left: calc(500px - 50vw);
		}

		.switch-box-main-bgr {
			right: calc(500px - 50vw);
		}

		.meal-tabs-list {
			width: 1000px;
		}

		.meal-explain-main {
			width: 1000px;
			justify-content: space-around;
		}

		.meal-advantages-pros {
			width: 1000px;
		}

		.meal-researcher {
			width: calc(1000px - 670px);
		}
		.meal-researcher-list{
			width: 670px;
		}

		.meal-product-advantage {
			width: 1000px;
		}

		.meal-advantage-infobox {
			width: 480px;
		}

		.meal-product-advantage .meal-advantage-img {
			width: 480px;
			height: 480px;
		}

		.meal-advantage-img-bg {
			width: 420px;
			height: 420px;
			border-radius: 420px;
		}

		.meal-advantage-null {
			width: 40px;
		}

		.meal-qus {
			width: 1000px;
		}

		.meal-cautions {
			width: calc(50vw + 500px);
			margin-left: calc(50vw - 500px);
		}

		.meal-cautions-name {
			width: 500px;
		}

		.meal-cautions-img {
			width: calc(100% - 560px);
		}

		.meal-qus .bgwater {
			width: 760px;
			min-width: 760px;
		}

		.meal-meallist-name {
			width: calc(50vw - 500px + 300px);
			padding-left: calc(50vw - 500px);
		}

		.change-one-btns {
			left: calc(50vw - 500px) !important;
		}

		.meal-meallist-list {
			width: calc(50vw + 500px - 300px);
		}

		.meal-product-info {
			margin-left: calc(50vw - 500px);
		}

		.meal-product-info-box {
			width: 550px;
		}

		.meal-product-info-img {
			width: 55vw;
		}

		.qus-cautions {
			padding-bottom: 164px;
		}

		/* .change-one-btns div{
			width: 40px;
			height: 40px;
		}
		.meal-meallist-list .ivu-carousel button{
			width: 40px;
			height: 40px;
		} */
		.change-one-btns div img,
		.switch-box-btns div img {
			width: 30px;
			height: 30px;
			margin: 5px;
		}
		.change-one-last{
			position: relative;
		}
		.meal-meallist-name .change-one-btns div img{
			width: 30px;
			height: 30px;
			margin: 0 15px;
		}
		.meal-meallist-name .change-one-btns .change-one-last{
			justify-content: left;
		}
		.meal-meallist-name .change-one-btns .change-one-last img{
			margin: 0 0 0 30px;
		}

		.switch-box-btns {
			bottom: -2px;
		}

		.switch-box-btns .switch-box-last {
			margin-right: calc(2vw + 20px);
		}

		.switch-box-btns div {
			width: 40px;
			height: 40px;
			/* margin: 0 8px; */
		}

		.switch-box-main .ivu-carousel button {
			width: 2.3vw;
			height: 2.3vw;
			width: 60px;
			height: 60px;
			width: 40px;
			height: 40px;
			opacity: 0 !important;
			z-index: 1;
		}

		.switch-box-main .ivu-carousel-arrow.left {
			top: calc(-13px - 25px + 7.2px) !important;
		}

		.switch-box-main .ivu-carousel-arrow.right {
			top: calc(-13px - 25px + 7.2px) !important;
		}


		.meal-researcher-list .ivu-carousel-arrow.left {
			top: auto !important;
			bottom: 1vw !important;
			bottom: 0px !important;
			left: calc(0px - 1000px + 680px + 2.8vw) !important;
		}

		.meal-researcher-list .ivu-carousel-arrow.right {
			top: auto !important;
			bottom: 1vw !important;
			bottom: 0px !important;
			left: calc(0px - 1000px + 680px + 60px + 4.8vw) !important;
			right: auto !important;
		}
		.meal-researcher .change-one-btns div img{
			margin: 0 15px;
		}
		.meal-researcher .change-one-btns .change-one-last img{
			margin:  0 0 0 30px;
		}
	}

	@media screen and (min-width:1201px) and (max-width:1600px) {}

	@media screen and (min-width:1000px) and (max-width:1200px) {
		.meal-qus .bgwater {
			bottom: -30.6vw;
		}
	}

	@media screen and (min-width:1201px) and (max-width:1439px) {
		.meal-qus .bgwater {
			bottom: -26vw;
		}
	}

	@media screen and (max-width:1920px) {
		.meal-advantage-info-title h5{
			font-size: 24px!important;
		}
	}

	@media screen and (min-width:1921px){}

	.meal-advantages-bottom,.meal-advantages-bottom-msg p{
		color: #1A1A1A!important;
	}
</style>
