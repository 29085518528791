<template>
	<div class="fkefu">
		<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/kefu.png" alt="">
		<!-- <div class="kefumsg">长按保存二维码</div> -->
		<div class="ghome" @click="gohome">返回上一页</div>
	</div>
</template>

<script>
	export default{
		name:'kefu',
		components:{
		},
		data:()=>({
		}),
		mounted() {
		},
		methods:{
			gohome(){
				this.$router.go(-1)
			}
		},
	}
</script>

<style>
	.fkefu{
		width: 100%;
		height: 100vh;
		padding-top: 10%;
	}
	.fkefu img{
		width: 80vw;
		height: auto;
		margin: 0 auto;
	}
	.kefumsg{
		width: 100%;
		height: 0.61rem;
		line-height: 0.61rem;
		text-align: center;
		color: #1A1A1A;
		font-weight: bold;
		font-size: 0.37rem;
	}
	.ghome{
		width: 3rem;
		padding: 0 0.5rem;
		height: 0.81rem;
		font-size: 0.37rem;
		line-height: 0.81rem;
		text-align: center;
		cursor: pointer;
		margin: 0 auto;
		margin-top: 0.7rem;
		color: #999999;
	}
</style>
