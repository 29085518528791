<template>
  <div class="show_mb"> 
    <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/build/dist/img/shengyinow.png" class="shengyiback"/>
    <div class="shengyifoot">
        <button class='shengyibutton' @click="goquestion">
            <a :href="url">开始答题</a>
        </button>
    </div>
 </div>
</template>

<script>
export default {
 data(){
 return {
    url:'https://www.wjx.cn/vm/tl5hubw.aspx#'
 }

 },
 methods:{
    goquestion(){
        window.location.href='https://www.wjx.cn/vm/tl5hubw.aspx#'
    }
 }

}

</script>

<style scoped>
   .show_mb .shengyiback{
        width:100vw;
        height: 100vh;
    }
    .shengyifoot{
        width:100vw;
        position:absolute;
        bottom:1.5rem    
    }
    .shengyibutton{
        width:5rem;
        height: 1rem;
        background-color:#0E6D9F;
        border-radius:0.5rem;
        margin:0 auto;          
    }
    .shengyibutton a{
        font-size:0.3rem;
        font-size:500;
        color:#ffffff
    }
</style>
