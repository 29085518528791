export default class Util {

  static getAppVersion(){
    const str = navigator.userAgent
    const appMatches = str.match(/APP\((.*?)\)/);
    if (!appMatches) {
      return "";
    }
    const appVersion = appMatches[1];
    return appVersion;
  }

  static getIdfa(){
    const str = navigator.userAgent
    const idMatches = str.match(/ID\((.*?)\)/);
    if (!idMatches) {
      return "";
    }
    const id = idMatches[1];
    return id;
  }

  static getMemberInfo(){
    var info = localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')) : undefined;
    return info;
  }

  static checkIsLogin(){
    return Util.getMemberInfo() != undefined;
  }
}
