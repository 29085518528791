<template>
	<div class="wxpaid">
		<div class="order_msg">
			<div class="order_text_area">
				<div class="wxtoubox">
					<img class="wxtou" src="../../public/imgs/xcxt.png" alt="">
				</div>
				<h4 class="order_msg_title order_msg_success">成都小橘袋健康科技有限公司</h4>
			</div>
			<div class="order_items_area">
				<div class="order_item_area">
					<div class="order_item_hd">订单状态</div>
					<div class="order_item_bd">支付成功</div>
				</div>
				<div class="order_item_area">
					<div class="order_item_hd">订单尾号</div>
					<div class="order_item_bd">{{w}}</div>
				</div>
				<div class="order_item_area">
					<div class="order_item_hd">订单金额</div>
					<div class="order_item_bd">¥{{m}}</div>
				</div>
			</div>
			<button class="btn-get" @click="goback">返回</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'wxpaid',
		components: {},
		data: () => ({
			id:'',
			m:'',
			w:''
		}),
		mounted() {
			var id = this.$route.query.i?this.$route.query.i:''
			var arr = id.split("_")
			this.id = arr[0]
			this.m = arr[1]
			this.w = arr[2]
		},
		methods: {
			gohome() {
				this.$router.push({
					path: 'home'
				})
			},
			goback(){
				var ssurl = 'https://'+window.location.host+'/paid?id='+this.id
				// //console.log(ssurl)
				
				let mchData ={action:'jumpOut', jumpOutUrl:ssurl};
				let postData = JSON.stringify(mchData);
				parent.postMessage(postData,'https://payapp.weixin.qq.com');
				// var that = this
				// this.$router.push({
				// 	path: 'order_detail',
				// 	query:{
				// 		id:that.id
				// 	}
				// })
			}
		},
	}
</script>

<style>
	.wxtoubox{
		width: 1.6rem;
		height: 1.6rem;
		border-radius: 2rem;
		margin: 0 auto;
		border: 0.01rem solid #999999;
		overflow: hidden;
	}
	.wxtou{
		width: 1.2rem;
		height: 1.1rem;
		border-radius: 0.2rem;
		margin: 0.25rem auto;
	}
	.order_msg_title{
		margin-top: 0.5rem;
	}
	.wxpaid{
		width: 100%;
		height: auto;
		margin-top: -0.7rem;
	}
	.order_msg{
		width: 100%;
		text-align: center;
	}
	.order_text_area{
		width: 100%;
		height: auto;
	}
	.order_items_area{
		width: 100%;
		height: auto;
		margin-top: 0.7rem;
	}
	.order_item_area{
		width: 80%;
		margin: 0 auto;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.36rem;
		display: flex;
		justify-content: space-between;
	}
	.btn-get{
		/* width:40%; */
		/* line-height: 1rem; */
		border-radius: 0.19rem;
		margin: 0 auto;
		margin-top: 0.7rem;
		
		width: 4.22rem;
		/* height: 1.11rem; */
		line-height: 1.11rem;
		background: #C25E4D;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(194, 94, 77, 0.25);
		border-radius: 0.57rem;
		font-size: 0.3rem;
		color: #FFFFFF;
		text-align: center;
	}
	.order_item_hd{
		color: #95999C;
	}
</style>
