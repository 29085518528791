<template>
	<div class="addressdetail main-page">
		<div class="page-bg"></div>
		<div class="address-title">
			{{way==1?'新建地址':'修改地址'}}
		</div>
		<div class="address-box">
			<div class="newaddress-mainbox">
				<div class="newaddress-line">
					<div class="newaddress-name fontsize">收货人</div>
					<div class="newaddress-value">
						<input type="text" v-model="name" placeholder="跨境清关，请填写真实姓名">
					</div>
				</div>
				<div class="newaddress-line">
					<div class="newaddress-name fontsize">身份证号</div>
					<div class="newaddress-value">
						<input type="text" maxlength="18" v-model="id_card" placeholder="请填写身份证号">
					</div>
				</div>
				<div class="newaddress-line">
					<div class="newaddress-name fontsize">联系方式</div>
					<div class="newaddress-value">
						<input type="text" maxlength="11" v-model="phone" placeholder="请填写电话号码">
					</div>
				</div>
				<div class="newaddress-line-flex">
					<div class="newaddress-left">
						<div class="left-top fontsize">设为默认地址</div>
						<div class="left-msg">注：每次下单时会使用该地址</div>
					</div>
					<div class="newaddress-right">
						<i-switch true-color="#C25E4D" false-color="#bbbbbb" v-model="is_default" @on-change="change" />
					</div>
				</div>
			</div>
			<div class="newaddress-mainbox">
				<div class="newaddress-line">
					<div class="newaddress-name fontsize">所在地区</div>
					<div class="newaddress-value newaddress-value-pace">
						<v-distpicker :province="province" :city="city" :area="area" @province="onChangeProvince" @city="onChangeCity"
						@area="onChangeArea"></v-distpicker>
						<!-- <v-distpicker type="mobile" :province="province" :city="city" :area="area" @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea"></v-distpicker> -->
					</div>
				</div>
				<div class="newaddress-line">
					<div class="newaddress-name fontsize">详细地址</div>
					<div class="newaddress-value detail-address">
						<textarea class="detailtxt" placeholder="请输入你的详细地址" v-model="detail" name="" id="" cols="30" rows="10"></textarea>
					</div>
				</div>
				<div class="newaddress-btns" :class="way==2?'delway':''">
					<div class="nosave" @click="nosave">返回</div>
					<div class="save-btn public-btn del-btn" v-if="way==2" @click="delthis">删除</div>
					<div class="save-btn public-btn" v-if="hass">保存</div>
					<div class="save-btn public-btn" v-if="!hass" @click="savethis">保存</div>
				</div>
			</div>
		</div>
		
		<Modal v-model="modal2" width="423.9" :styles="{top: '30%'}">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>删除确定</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>确认删除该地址吗？</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="noshowit">取消</div>
				<div class="modalok modalbtn cursor" @click="del">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
	</div>
</template>

<script>
	import VDistpicker from 'v-distpicker'
	// import {
	// 	Switch
	// } from 'view-design';
	export default {
		name: 'newaddress',
		components: {
			VDistpicker,
			// Switch
		},
		data: () => ({
			way: 1,
			address: '',
			info: '',
			name: '',
			phone: '',
			modal2: false,
			id_card: '',
			detail: '',
			is_default: true,
			hass:false,
			post_code: '',
			province: '',
			phonetest: '^1(3([0-35-9]\\d|4[1-8])|4[14-9]\\d|5([0-35689]\\d|7[1-79])|66\\d|7[2-35-8]\\d|8\\d{2}|9[13589]\\d)\\d{7}$',
			city: '',
			area: '',
			region: [],
		}),
		mounted() {
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				//console.log('用户未登录')
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login'
				})
			}
			this.info = info
			this.way = this.$route.query.way
			//console.log(this.$route.query)
			if (this.$route.query.id) {
				this.getaddress(this.$route.query.id)
			}
		},
		methods: {
			noshowit(){
				this.modal2=false
			},
			change(e) {
				console.log(e)
			},
			onChangeProvince(e) {
				//console.log(e)
				this.province = e.value
			},
			onChangeCity(e) {
				//console.log(e)
				this.city = e.value
			},
			onChangeArea(e) {
				//console.log(e)
				this.area = e.value
			},
			onSelected(data) {
				console.log(data)
			},
			getaddress(id) {
				var that = this
				//获取单条地址信息
				this.$ajax.post(this.API.api.apiurl.getAddress, {
						addressId: id,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data.data
						if (res.data.code == 0) {
							if(!data){
								that.$router.replace({path:'404'})
							}else{
								//console.log(data)
								that.address = data
								that.name = data.name
								that.phone = data.phone
								that.id_card = data.id_card
								that.detail = data.address
								that.is_default = data.is_default == 0 ? false : true
								that.province = data.province_name
								that.city = data.city_name
								that.area = data.area_name
							}
						}else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			nosave() {
				this.$router.go(-1);
			},
			savethis() {
				this.hass = true
				var that = this
				//console.log(that.province, that.city, that.area)
				if (!that.name) {
					that.$Message.info('请输入真实姓名')
					this.hass = false
				} else if (!that.id_card) {
					that.$Message.info('请输入身份证号')
					this.hass = false
				} else if (!that.phone) {
					that.$Message.info('请输入电话号码')
					this.hass = false
				} else if (!that.detail) {
					that.$Message.info('请输入详细地址')
					this.hass = false
				} else if (!that.province || that.province == '省') {
					that.$Message.info('请选择省份')
					this.hass = false
				} else if (!that.city || that.city == '市') {
					that.$Message.info('请选择城市')
					this.hass = false
				} else if (!that.area || that.area == '区') {
					that.$Message.info('请选择地区')
					this.hass = false
				} else {
					that.saveAddress()
				}
			},
			delthis() {
				this.modal2 = true
			},
			del() {
				var that = this
				//删除地址
				this.$ajax.post(this.API.api.apiurl.delAddress, {
						addressId: that.$route.query.id,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							//console.log(data)
							if (that.$route.query.page && that.$route.query.page == 'pay') {
								that.$router.go(-1);
							} else {
								// that.$router.replace({
								// 	path: 'addresslist'
								// })
								that.$router.go(-1);
							}
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			saveAddress() {
				var that = this
				var region = [that.province, that.city, that.area]

				var testphone = new RegExp(this.phonetest)
				if (!that.phone || !testphone.test(that.phone)) {
					this.$Message.info('请输入正确的手机号')
					this.hass = false
				} else {
					//保存地址信息
					if (that.$route.query.id) {
						this.$ajax.post(this.API.api.apiurl.saveAddress, {
								member_id: that.info.member_id,
								addressId: that.$route.query.id,
								name: that.name,
								phone: that.phone,
								id_card: that.id_card,
								address: that.detail,
								is_default: that.is_default == false ? 0 : 1,
								// post_code: '',
								region: region,
							},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
							.then(res => {
								////console.log(res)
								// var data = res.data.data
								that.hass = false
								if (res.data.code == 0) {
									//console.log(data)
									if (that.$route.query.page && that.$route.query.page == 'pay') {
										that.$router.go(-1);
									} else {
										// that.$router.replace({
										// 	path: 'addresslist'
										// })
										that.$router.go(-1);
									}
								} else {
									that.$Message.info('错误：' + res.data.message)
									that.hass = false
								}
							})
							.catch(error => {
								that.hass = false
								console.log(error)
							})
					} else {
						this.$ajax.post(this.API.api.apiurl.saveAddress, {
								member_id: that.info.member_id,
								name: that.name,
								phone: that.phone,
								id_card: that.id_card,
								address: that.detail,
								is_default: that.is_default == false ? 0 : 1,
								// post_code: '',
								region: region,
							},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
							.then(res => {
								////console.log(res)
								// var data = res.data.data
								if (res.data.code == 0) {
									//console.log(data)
									if (that.$route.query.page && that.$route.query.page == 'pay') {
										that.$router.go(-1);
									} else {
										// that.$router.replace({
										// 	path: 'addresslist'
										// })
										that.$router.go(-1);
									}
								} else {
									that.$Message.info('错误：' + res.data.message)
									that.hass = false
								}
							})
							.catch(error => {
								console.log(error)
								that.hass = false
							})
					}
				}
			}
		}
	}
</script>

<style>
	.distpicker-address-wrapper {
		width: 100%;
		display: flex;
	}

	#app .distpicker-address-wrapper select {
		padding: 0 0.1rem 0 0.45rem;
		height: 0.71rem;
		line-height: 0.71rem;
		border-radius: 0;
		border: none;
		/* font-size: 0.26rem; */
		font-size: 14px;
		width: 2rem;
		font-weight: 500;
		background: none;
	}

	#app .distpicker-address-wrapper select option:first-child {
		display: none;
	}

	.address-detail .address-box {
		display: flex;
		justify-content: space-between;
	}

	.newaddress-mainbox {
		width: 6.6rem;
		height: auto;
	}

	.newaddress-line {
		width: 100%;
		margin-bottom: 0.8rem;
	}

	.newaddress-line .newaddress-name {
		width: 100%;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.41rem;
		color: #1A1A1A;
		text-align: left;
	}

	.newaddress-value {
		width: 100%;
		margin-top: 0.3rem;
	}

	.newaddress-value input {
		width: 100%;
		height: 0.89rem;
		background: #FFFFFF;
		border-radius: 0.89rem;
		border: none;
		line-height: 0.89rem;
		/* font-size: 0.26rem; */
		font-size: 14px;
		color: #1A1A1A;
		text-indent: 0.59rem;
		border: 0.02rem solid #D5DDE6;
	}

	input::-webkit-input-placeholder {
		color: #CCCCCC;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #CCCCCC;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #CCCCCC;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #CCCCCC;
	}

	textarea::-webkit-input-placeholder {
		color: #CCCCCC;
	}

	textarea::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #CCCCCC;
	}

	textarea:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #CCCCCC;
	}

	textarea:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #CCCCCC;
	}

	.newaddress-line-flex {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.newaddress-left {
		width: auto;
		height: 0.78rem;
		text-align: left;
	}

	.newaddress-left .left-top {
		line-height: 0.41rem;
		font-size: 0.41rem;
		color: #1A1A1A;
	}

	.newaddress-left .left-bottom {
		line-height: 0.41rem;
		font-size: 0.26rem;
		color: #666666;
	}

	.newaddress-right {
		width: 1.21rem;
		height: 0.61rem;
	}

	.newaddress-value-pace {
		width: 100%;
		height: 0.89rem;
		background: #FFFFFF;
		border-radius: 0.89rem;
		padding: 0.09rem 0;
		border: 0.02rem solid #D5DDE6;
	}

	.newaddress-value label {
		margin-bottom: 0;
	}

	.distpicker-address-wrapper {
		width: 100%;
		height: 0.71rem;
		display: flex;
		justify-content: space-around;
	}

	.detail-address {
		width: 100%;
		height: 3.07rem;
		background: #FFFFFF;
		border-radius: 0.19rem;
		border: 0.04rem dashed #D5DDE6;
		padding: 0.3rem 0.59rem;
	}

	.detailtxt {
		width: 100%;
		height: 100%;
		border: none;
		line-height: 0.41rem;
		/* font-size: 0.26rem; */
		font-size: 14px;
		resize:none;
	}

	.newaddress-btns {
		width: 100%;
		height: 1.11rem;
		display: flex;
		justify-content: flex-end;
	}

	.delway {
		justify-content: space-between;
	}

	.newaddress-btns div {
		cursor: pointer;
	}

	.nosave {
		width: auto;
		height: 1.11rem;
		line-height: 1.11rem;
		font-size: 0.3rem;
		color: #999999;
		margin-right: 0.6rem;
	}

	#app .delway .save-btn {
		width: 2.5rem;
	}
	#app .delway .del-btn{
		color:#C25E4D;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
	}

	.addressdetail .address-box {
		padding: 0.59rem 0;
	}

	@media screen and (max-width:1000px) {
		.newaddress-mainbox {
			width: 100%;
			margin-bottom: 0.8rem;
		}

		.addressdetail .address-box {
			padding: 0 0.19rem;
		}

		.address-title {
			padding: 0 0.19rem;
			margin: 0.56rem 0;
		}
		.delway{
			justify-content: flex-end;
		}
		.delway .save-btn{
			margin-left: 0.6rem;
		}
		#app .distpicker-address-wrapper select{
			padding: 0;
		}
	}
</style>
