<template>
	<div class="alert-box">
		<div class="alert">
			<div class="alert-title">{{rdata.title}}</div>
			<div class="alert-txt">{{rdata.txt}}</div>
			<div class="alert-btns">
				<div class="alert-btn alert-ok cursor" @click="ok">{{rdata.oktxt}}</div>
				<div class="alert-btn alert-no cursor" @click="no">{{rdata.notxt}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'alertmsg',
		props: {
			rdata: Object
		},
		watch:{},
		mounted() {
			//console.log(this.rdata)
		},
		methods:{
			ok(){
				this.$emit('getback',{value:'1'})
			},
			no(){
				this.$emit('getback',{value:'0'})
			}
		}
	}
</script>

<style>
	.alert-box{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.7);
		z-index: 16;
		display: flex;
		justify-content: center;
	}
	.alert{
		width: 6rem;
		height: 4rem;
		padding: 0.59rem;
		background: #FFFFFF;
		border-radius: 0.19rem;
		margin-top:24%;
	}
	.alert-title{
		width: 100%;
		height: 0.81rem;
		line-height: 0.81rem;
		font-size: 0.45rem;
		font-weight: bold;
		text-align: center;
	}
	.alert-txt{
		width: 100%;
		height: 1.4rem;
		text-align: center;
		line-height: 0.41rem;
		font-size: 0.3rem;
	}
	.alert-btns{
		width: 100%;
		height: 0.8rem;
		display: flex;
		justify-content: space-around
	}
	.alert-btns .alert-btn{
		width: 2rem;
		height: 0.7rem;
		line-height: 0.7rem;
		border-radius: 0.7rem;
		text-align: center;
		font-size: 0.28rem;
		margin: 0.05rem 0;
		color: #C25E4D;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(221, 221, 221, 0.84);
	}
	.alert-btns .alert-no{
		width: 2rem;
		height: 0.7rem;
		line-height: 0.7rem;
		border-radius: 0.7rem;
		text-align: center;
		font-size: 0.28rem;
		margin: 0.05rem 0;
		color: #FFFFFF;
		background: #C25E4D;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.24);
	}
</style>
