<!-- eslint-disable vue/no-parsing-error -->
<template>
	<div class="prodetail" style="color: #1A1A1A;" v-if="prodetail">
				<div class="maintanchuang" @click="tlshowtan" v-if="showtan">
					<div class="maintanc" @click="lshowtan">
						<div class="maintline" @click="showtan = !showtan"><img src="../../public/imgs/close.png" alt=""></div>
						<div class="maintantxt main-left-p" v-html="reportinfo.content"></div>
					</div>
				</div>
				<div class="goods_unit-mainbox maintanchuang" @click="tlshowtan2" v-if="showtan2">
					<div class="goods_unit-main maintanc" @click="lshowtan2">
						<div class="goods_unit-maintline" @click="showtan2 = !showtan2"><img src="../../public/imgs/close.png" alt=""></div>

						<goods-unit />
					</div>
				</div>
		<div class="show_mb">
			<div class="prodetail-top">
				<div class="pro-top-bg">
					<img class="pro-bg-url" :src="bimg" alt="banner">
					<div class="detail-name-box">
						<div class="en-name yinz">{{eng_name}}</div>
						<div class="zn-name">{{goods_name| replaceCommaWithBlank}}</div>
						<div class="detailprice" v-if="false">
							<span>{{prodetail.goods_detail.day_use+' '+prodetail.goods_detail.min_unit}}/天</span>
							<span>￥{{prodetail.goods_detail.dayprice}}/天</span>
						</div>
						<div class="detail-cats">
							<div class="detail-cat" v-for="(item,index) in prodetail.goods_detail.cat" :key="index">
								<div class="cat-img"><img :src="item.url" alt=""></div>
								<div class="cat-name">{{item.title}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="prodetail-main">
				<div class="prodetail-txt">
					<!-- {{prodetail.goods_img_text.product_introduction}} -->
					{{prodetail.goods_detail.product_introduction| replaceCommaWithBlank}}
				</div>
				<div class="food_origin" v-if="prodetail.goods_img_text.food_origin">
					<div class="food_origin-title">天然食物获取来源</div>
					<div class="food_origin-txt">{{prodetail.goods_img_text.food_origin}}</div>
				</div>
				<div class="prodetail-imgs">
					<img :src="prodetail.goods_img_text.img4" alt="img4">
				</div>
				<div class="detail-pro-title">
					<div class="pro-title1 yinz">Product Advantages</div>
					<!-- <div class="pro-title2">{{goods_name| replaceCommaWithBlank}}的优势</div> -->
					<div class="pro-title2">{{goods_name| replaceCommaWithBlank}}的科普</div>
				</div>
				<div class="prodetail-imgs">
					<img :src="prodetail.goods_img_text.img1" alt="img1">
				</div>
				<div class="function-list">
					<div class="function-box" v-for="(item,index) in prodetail.goods_img_text.function" :key="index">
						<img :src="item.img" alt="">
						<div class="function-txts">
							<div class="function-title">{{item.title}}</div>
							<div class="function-msg">
								<span>{{item.label[0]?item.label[0]:''}}{{item.label[1]?' | ':''}}{{item.label[1]?item.label[1]:''}}{{item.label[2]?' | ':''}}{{item.label[2]?item.label[2]:''}}{{item.label[3]?' | ':''}}{{item.label[3]?item.label[3]:''}}</span>
								<!-- <span v-for="(items,indexs) in item.label" :key="indexs">{{items}}{{indexs+1!=item.label.length?' | ':''}}</span> -->
							</div>
						</div>
					</div>
				</div>
				<div class="advantage-list">
					<div class="advantage-box" v-for="(item,index) in prodetail.goods_img_text.advantage" :key="index">
						<div class="advantage-title">{{item.title}}</div>
						<div class="advantage-txt">
							<div class="advantage-border"></div>
							<div class="advantage-msg">{{item.describe}}</div>
						</div>
					</div>
				</div>
				<div class="prodetail-imgs">
					<img :src="prodetail.goods_img_text.img2" alt="">
				</div>
				<div class="hbg">
					<div class="pro-info-title">
						<div class="title-l-l1"></div>
						<div class="title-l-l2"></div>
						<div class="pro-info-box">
							<div class="pro-info-name">产品信息</div>
							<div class="pro-info-msg yinz">Product Information</div>
						</div>
						<div class="title-r-r2"></div>
						<div class="title-r-r1"></div>
					</div>
					<div class="pro-infobg-box">
						<div class="infobg-title">
							<div class="t">{{goods_name| replaceCommaWithBlank}}</div>
							<div class="yinz">{{eng_name}}</div>
						</div>
						<div class="infobg-cats">
							<img :src="item.url" alt="" v-for="(item,index) in prodetail.goods_detail.cat" :key="index">
						</div>
						<div class="goods_label-box">
							<div class="goods_label" v-for="(item,index) in prodetail.goods_detail.goods_label"
								:key="index">
								<img :src="item.img_url" alt="">
								<div>{{item.title}}</div>
							</div>
						</div>
						<div class="redayuse">
							<span class="b">每日营养推荐量</span>
							<span class="yinz">Suggested Use</span>
						</div>
						<div class="day-use-box">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/pill.png"
								alt="" v-for="(item,index) in prodetail.goods_detail.day_use" :key="index">
							<div class="day-use-num">
								{{prodetail.goods_detail.day_use}}{{prodetail.goods_detail.min_unit}}</div>
						</div>

						<div class="bg-title">
							<span class="b">营养标签</span><span class="yinz mleft">Supplement Facts</span>
						</div>
						<div class="goods_unit-box">
							<div class="goods_unit-title">
								<div class="goods_unit-name">
									<div>每份含成分({{prodetail.goods_detail.day_use}}颗)</div>
									<div>Amount Per Serving</div>
								</div>
								<div class="goods_unit-nums">
									<div>每日营养摄入量百分比</div>
									<div>% Daily Value</div>
								</div>
							</div>
							<div class="goods_unit-line"
							:class="index+1==prodetail.goods_detail.goods_unit.length?'bborder':''"
							v-for="(ingredientsItem,index) in prodetail.goods_detail.goods_unit" :key="index">
								<!-- 有分组的情况 -->
									<div v-if="ingredientsItem.group_en_name" style="width:100%">
										<div class="ingredients_info">
											<div class="ingredient_item_one">{{ingredientsItem.group_en_name}}</div>
											<div class="ingredient_item_two">{{ingredientsItem.group_unit_size}}{{ingredientsItem.group_unit}}</div>
											<div class="ingredient_item_three">{{ingredientsItem.group_unit_percentage}}</div>
										</div>
										<div class="ingredients_group">
											<div class="ingredient_group_en"><div v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_eng}}</div></div>
											<div class="ingredient_group_zh">{{ingredientsItem.group_zh_name}}<span v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_name}}{{ index<(ingredientsItem.ingredients).length-1?',':''}}</span></div>
										</div>
									</div>
									<!-- 没有分组的情况 -->
									<div v-else style="width:100%">
										<div class="ingredients_info ingredients_info_single" v-for="(item,index) in ingredientsItem.ingredients" :key="index">
											<div class="ingredient_item_one">{{item.unit_eng}} {{item.unit_name}}</div>
											<div class="ingredient_item_two">{{item.unit_size}}{{item.unit}}</div>
											<div class="ingredient_item_three">{{item.unit_percentage}}</div>
										</div>
									</div>
							</div>
							<!-- <div class="goods_unit-line"
								:class="index+1==prodetail.goods_detail.goods_unit.length?'bborder':''"
								v-for="(item,index) in prodetail.goods_detail.goods_unit" :key="index">
								<div class="goods_unit-name">
									<div>{{item.unit_eng}} <p>{{item.zh}}</p></div>
								</div>
								<div class="goods_unit-num">
									<div>{{item.unit_size}}{{item.unit}}</div>
								</div>
								<div class="goods_unit-x">
									<div>{{item.unit_percentage}}</div>
								</div>
							</div> -->
							<div class="goods_unit-line bborder" v-if="prodetail.goods_detail.other_component">
								<!-- <div class="goods_unit-name"> -->
								<div>其他成分:{{prodetail.goods_detail.other_component}}</div>
								<!-- </div> -->
							</div>
						</div>
						<div class="exclusive">
							没有添加{{prodetail.goods_detail.exclusive}}
						</div>
						<div class="gochecklist cursor" @click="gounitdetail(prodetail.goods_detail.id)">
							<div>100%透明单粒成分表</div>
							<img src="../../public/imgs/more.png" alt="">
						</div>
					</div>
					<div class="pro-info-title">
						<div class="title-l-l1"></div>
						<div class="title-l-l2"></div>
						<div class="pro-info-box">
							<div class="pro-info-name">注意事项</div>
							<div class="pro-info-msg yinz">Cautions</div>
						</div>
						<div class="title-r-r2"></div>
						<div class="title-r-r1"></div>
					</div>
					<div class="populationlist">
						<div class="population-line">
							<div class="population-title">
								<img src="../../public/imgs/pro/ren.png" alt="">
								<div>建议人群</div>
							</div>
							<div class="population-txt">
								{{prodetail.goods_img_text.suggested_population}}
							</div>
						</div>
						<div class="population-line">
							<div class="population-title">
								<img src="../../public/imgs/pro/jianyi.png" alt="">
								<div>服用建议</div>
							</div>
							<div class="population-txt">
								{{prodetail.goods_img_text.advice_on_taking}}
							</div>
						</div>
						<div class="population-line">
							<div class="population-title">
								<img src="../../public/imgs/pro/jin.png" alt="">
								<div>禁忌人群</div>
							</div>
							<div class="population-txt">
								{{prodetail.goods_img_text.taboo_population}}
							</div>
						</div>
						<div class="population-line">
							<div class="population-title">
								<img src="../../public/imgs/pro/tips.png" alt="">
								<div>特别注意</div>
							</div>
							<div class="population-txt">
								{{prodetail.goods_img_text.points_for_attention}}
							</div>
						</div>
					</div>
					<div class="prodetail-imgs">
						<img :src="prodetail.goods_img_text.img3" alt="">
					</div>
					<div class="yanjiu">
						<div class="yan-title">
							<div class="yan-name"><span class="b">相关研究</span><span class=" yinz">Related Research</span>
							</div>
							<img :src="prodetail.goods_img_text.img5" alt="">
						</div>
						<div class="yan-list">
							<div class="yan-line" v-for="(item,index) in prodetail.report_list" :key="index">
								<div class="yan-line-title">{{item.title}}</div>
								<div class="yan-line-txt">{{item.intro}}</div>
								<div class="yan-detail" @click="goreportdetail(item.id)">
									<div>详情</div>
									<img src="../../public/imgs/pro/more.png" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="show_pc detail_pc">
			<div class="prodetail-top" style="margin: 0;height: auto;">
				<div class="pro-top-bg"
					style="width:100%;height: 47.5vw;justify-content: flex-start;align-items: center;">
					<div class="pro-bg-url-img" style="width: 60vw;height: 47.5vw;overflow: hidden;">
						<img class="pro-bg-url" :src="prodetail.goods_img_text.img8" alt="" style="width: 100%;height: auto;margin-top: -80px;">
					</div>
					<div class="detail-name-box"
						style="width: 36vw;margin-left:5vw;height: auto;color:#1A1A1A;align-items: center;">
						<div class="en-name"
							style="font-family: MADE Evolve;font-size: 64px;height: auto;line-height:normal;color:#1A1A1A">
							<h1>{{eng_name}}</h1></div>
						<div class="zn-name"
							style="font-size: 32px;margin: 16px 0 40px 0;height: auto;line-height:normal;color:#1A1A1A">
							<h5>{{goods_name| replaceCommaWithBlank}}</h5></div>
						<div class="prodetail-txt fontsize"
							style="width: 28vw;text-align: justify;font-size: 18px;height: auto;line-height:1.5;color:#1A1A1A">
							<!-- {{prodetail.goods_img_text.product_introduction| replaceCommaWithBlank}} -->
							{{prodetail.goods_detail.product_introduction| replaceCommaWithBlank}}
						</div>
						<div class="detail-cats"
							style="width: 20vw;min-width: 250px;display: flex;justify-content: normal;flex-wrap: wrap;height: auto;position: relative;">
							<div class="detail-cat" style="display: flex;width:auto;margin-right: 1vw;margin-top: 40px;"
								v-for="(item,index) in prodetail.goods_detail.cat" :key="index">
								<div class="cat-img" style="width: 30px;height: 30px;"><img
										style="width: 30px;height: auto;" :src="item.url" alt=""></div>
							</div>
						</div>
						<div class="pro-dayprice" style="" v-if="false">
							<span>{{prodetail.goods_detail.day_use+' '+prodetail.goods_detail.min_unit}}/天</span>
							<span>￥{{prodetail.goods_detail.dayprice}}/天</span>
						</div>
					</div>
				</div>
			</div>
			<!-- <div style="width: 100%; background-color: #fff;">
				<div class="goods_label-box" style="background: #fff;padding: 0;height: auto;padding: 5.7vw 0;padding: 82px 0;" :style="prodetail.goods_detail.goods_label.length>5?'justify-content: space-between;':'justify-content: space-evenly;'">
					<div class="goods_label" style="width: auto;height: auto;margin: 0;"
						v-for="(item,index) in prodetail.goods_detail.goods_label" :key="index">
						<img :src="item.img_url" alt="" style="width: 64px;height: 64px;">
						<div style="font-size: 18px;">{{item.title}}</div>
					</div>
				</div>
			</div> -->
			<div style="width: 100%; background-color: #fff;" :key="indexs" v-for="(hang,indexs) in Math.ceil(prodetail.goods_detail.goods_label.length/5)" :class="prodetail.goods_detail.goods_label.length==indexs+1?'lastone':''">
				<div class="goods_label-box" :class="indexs==0&&prodetail.goods_detail.goods_label.length>1?'firstone':prodetail.goods_detail.goods_label.length==1?'firstone1':''" style="background: #fff;padding: 0;height: auto;padding: 5.7vw 0;" :style="prodetail.goods_detail.goods_label.length>5?'':'justify-content: space-evenly;'">
					<div class="goods_label" style="width: auto;height: auto;margin: 0;"
						v-for="(item,index) in prodetail.goods_detail.goods_label" :key="index" v-show="indexs*5<=index&&(indexs+1)*5>index">
						<img :src="item.img_url" alt="" style="width: 64px;height: 64px;">
						<div style="font-size: 18px;">{{item.title}}</div>
					</div>
				</div>
			</div>
			<div class="prodetail-main" style="width: 100%;margin-top: 5.3vw;margin-top: 76px;margin-top: 50px;">
				<!-- <div class="dbottom-bg"></div> -->
				<div :class="prodetail.goods_img_text.food_origin?'tian-box':'tian-box1'"
					style="width: 75vw;justify-content: space-between;align-items: center;margin: 0 auto;" :style="prodetail.goods_img_text.food_origin?'':'width: 100vw;justify-content:center;margin:0 auto;'">
					<div class="food_origin" v-if="prodetail.goods_img_text.food_origin"
						style="width: 45%;height: auto;align-items: center;display: flex;">
						<div style="width: 25vw;align-items: center;">
							<div class="food_origin-title"
								style="font-size: 24px;margin: 0;height: auto;">天然食物获取来源</div>
							<div class="food_origin-txt fontsize"
								style="font-size: 18px;line-height: 1.5;height: auto;margin-top: 16px;">
								{{prodetail.goods_img_text.food_origin}}</div>
						</div>
					</div>
					<div class="prodetail-imgs" style="37.55vw;height: auto;">
						<img :src="prodetail.goods_img_text.img4" alt="">
					</div>
				</div>
				<div class="detail-pro-title zuobian">
					<div class="pro-title1 Evolve juzhong" style="font-size: 3vw;">
						<div class="meal-pc-main-title-line"></div>
						<!-- <p>Product Advantages</p> -->
						<h3>Product Advantages</h3>
					</div>
					<!-- <div class="pro-title2 meal-pc-main-msg" style="font-weight: normal;">{{goods_name| replaceCommaWithBlank}}的优势</div> -->
					<div class="pro-title2 meal-pc-main-msg" style="font-weight: normal;">{{goods_name| replaceCommaWithBlank}}的科普</div>
					<div class="pro-detail-line"></div>
				</div>
				<div class="pro-function-list pro-function-listq1200" style="width: 75vw;height: auto;margin: 0 auto;position: relative;">
					<img class="pro-change-img pro-last" src="../../public/imgs/more.png" alt="" v-if="prodetail.goods_img_text.function.length>3">
					<Carousel :autoplay="false" :autoplay-speed="3000" :radius-dot="false" dot="none"
						style="width: 100%;height: auto;margin-top: 0;">
						<CarouselItem v-for="(item,index) in Math.ceil(prodetail.goods_img_text.function.length/3)"
							:key="index" style="width: 100%;height: auto;">
							<div class="pro-function-list-box"
								style="width: 1200px;display: flex;justify-content: space-evenly;">
								<div class="pro-function-box" :style="prodetail.goods_img_text.function.length==1?'width: 29.24vw;':''" v-if="prodetail.goods_img_text.function[index*3+0]">
									<div class="pro-function-img">
										<img :src="prodetail.goods_img_text.function[index*3+0].img" alt="">
									</div>
									<div class="pro-function-title">
										{{prodetail.goods_img_text.function[index*3+0].title}}</div>
									<div class="pro-function-status">
										<span>{{prodetail.goods_img_text.function[index*3+0].label[0]?prodetail.goods_img_text.function[index*3+0].label[0]:''}}{{prodetail.goods_img_text.function[index*3+0].label[1]?' | ':''}}{{prodetail.goods_img_text.function[index*3+0].label[1]?prodetail.goods_img_text.function[index*3+0].label[1]:''}}{{prodetail.goods_img_text.function[index*3+0].label[2]?' | ':''}}{{prodetail.goods_img_text.function[index*3+0].label[2]?prodetail.goods_img_text.function[index*3+0].label[2]:''}}{{prodetail.goods_img_text.function[index*3+0].label[3]?' | ':''}}{{prodetail.goods_img_text.function[index*3+0].label[3]?prodetail.goods_img_text.function[index*3+0].label[3]:''}}</span>
									</div>
									<div class="pro-function-msg">
										{{prodetail.goods_img_text.function[index*3+0].describe}}
									</div>
								</div>
								<div class="pro-function-box" v-if="prodetail.goods_img_text.function[index*3+1]">
									<div class="pro-function-img">
										<img :src="prodetail.goods_img_text.function[index*3+1].img" alt="">
									</div>
									<div class="pro-function-title">
										{{prodetail.goods_img_text.function[index*3+1].title}}</div>
									<div class="pro-function-status">
										<span>{{prodetail.goods_img_text.function[index*3+1].label[0]?prodetail.goods_img_text.function[index*3+1].label[0]:''}}{{prodetail.goods_img_text.function[index*3+1].label[1]?' | ':''}}{{prodetail.goods_img_text.function[index*3+1].label[1]?prodetail.goods_img_text.function[index*3+1].label[1]:''}}{{prodetail.goods_img_text.function[index*3+1].label[2]?' | ':''}}{{prodetail.goods_img_text.function[index*3+1].label[2]?prodetail.goods_img_text.function[index*3+1].label[2]:''}}{{prodetail.goods_img_text.function[index*3+1].label[3]?' | ':''}}{{prodetail.goods_img_text.function[index*3+1].label[3]?prodetail.goods_img_text.function[index*3+1].label[3]:''}}</span>
									</div>
									<div class="pro-function-msg">
										{{prodetail.goods_img_text.function[index*3+1].describe}}
									</div>
								</div>
								<div class="pro-function-box" v-if="prodetail.goods_img_text.function[index*3+2]">
									<div class="pro-function-img">
										<img :src="prodetail.goods_img_text.function[index*3+2].img" alt="">
									</div>
									<div class="pro-function-title">
										{{prodetail.goods_img_text.function[index*3+2].title}}</div>
									<div class="pro-function-status">
										<span>{{prodetail.goods_img_text.function[index*3+2].label[0]?prodetail.goods_img_text.function[index*3+2].label[0]:''}}{{prodetail.goods_img_text.function[index*3+2].label[1]?' | ':''}}{{prodetail.goods_img_text.function[index*3+2].label[1]?prodetail.goods_img_text.function[index*3+2].label[1]:''}}{{prodetail.goods_img_text.function[index*3+2].label[2]?' | ':''}}{{prodetail.goods_img_text.function[index*3+2].label[2]?prodetail.goods_img_text.function[index*3+2].label[2]:''}}{{prodetail.goods_img_text.function[index*3+2].label[3]?' | ':''}}{{prodetail.goods_img_text.function[index*3+2].label[3]?prodetail.goods_img_text.function[index*3+2].label[3]:''}}</span>
									</div>
									<div class="pro-function-msg">
										{{prodetail.goods_img_text.function[index*3+2].describe}}
									</div>
								</div>
							</div>
						</CarouselItem>
					</Carousel>
					<img class="pro-change-img pro-next" src="../../public/imgs/more.png" alt="" v-if="prodetail.goods_img_text.function.length>3">
				</div>
				<div class="pro-function-list pro-function-listq1000" style="height: auto;margin: 0 auto;position: relative;">
					<img class="pro-change-img pro-last" src="../../public/imgs/more.png" alt="" v-if="prodetail.goods_img_text.function.length>3">
					<Carousel :autoplay="false" :autoplay-speed="3000" :radius-dot="false" dot="none"
						style="width: 100%;height: auto;margin-top: 0;">
						<CarouselItem v-for="(item,index) in Math.ceil(prodetail.goods_img_text.function.length/3)"
							:key="index" style="width: 100%;height: auto;">
							<div class="pro-function-list-box"
								style="width: 960px;display: flex;justify-content: space-around;">
								<div class="pro-function-box" :style="prodetail.goods_img_text.function.length==1?'width: 29.24vw;':''" v-if="prodetail.goods_img_text.function[index*3+0]">
									<div class="pro-function-img">
										<img :src="prodetail.goods_img_text.function[index*3+0].img" alt="">
									</div>
									<div class="pro-function-title">
										{{prodetail.goods_img_text.function[index*3+0].title}}</div>
									<div class="pro-function-status">
										<span>{{prodetail.goods_img_text.function[index*3+0].label[0]?prodetail.goods_img_text.function[index*3+0].label[0]:''}}{{prodetail.goods_img_text.function[index*3+0].label[1]?' | ':''}}{{prodetail.goods_img_text.function[index*3+0].label[1]?prodetail.goods_img_text.function[index*3+0].label[1]:''}}{{prodetail.goods_img_text.function[index*3+0].label[2]?' | ':''}}{{prodetail.goods_img_text.function[index*3+0].label[2]?prodetail.goods_img_text.function[index*3+0].label[2]:''}}{{prodetail.goods_img_text.function[index*3+0].label[3]?' | ':''}}{{prodetail.goods_img_text.function[index*3+0].label[3]?prodetail.goods_img_text.function[index*3+0].label[3]:''}}</span>
									</div>
									<div class="pro-function-msg">
										{{prodetail.goods_img_text.function[index*3+0].describe}}
									</div>
								</div>
								<div class="pro-function-box" v-if="prodetail.goods_img_text.function[index*3+1]">
									<div class="pro-function-img">
										<img :src="prodetail.goods_img_text.function[index*3+1].img" alt="">
									</div>
									<div class="pro-function-title">
										{{prodetail.goods_img_text.function[index*3+1].title}}</div>
									<div class="pro-function-status">
										<span>{{prodetail.goods_img_text.function[index*3+1].label[0]?prodetail.goods_img_text.function[index*3+1].label[0]:''}}{{prodetail.goods_img_text.function[index*3+1].label[1]?' | ':''}}{{prodetail.goods_img_text.function[index*3+1].label[1]?prodetail.goods_img_text.function[index*3+1].label[1]:''}}{{prodetail.goods_img_text.function[index*3+1].label[2]?' | ':''}}{{prodetail.goods_img_text.function[index*3+1].label[2]?prodetail.goods_img_text.function[index*3+1].label[2]:''}}{{prodetail.goods_img_text.function[index*3+1].label[3]?' | ':''}}{{prodetail.goods_img_text.function[index*3+1].label[3]?prodetail.goods_img_text.function[index*3+1].label[3]:''}}</span>
									</div>
									<div class="pro-function-msg">
										{{prodetail.goods_img_text.function[index*3+1].describe}}
									</div>
								</div>
								<div class="pro-function-box" v-if="prodetail.goods_img_text.function[index*3+2]">
									<div class="pro-function-img">
										<img :src="prodetail.goods_img_text.function[index*3+2].img" alt="">
									</div>
									<div class="pro-function-title">
										{{prodetail.goods_img_text.function[index*3+2].title}}</div>
									<div class="pro-function-status">
										<span>{{prodetail.goods_img_text.function[index*3+2].label[0]?prodetail.goods_img_text.function[index*3+2].label[0]:''}}{{prodetail.goods_img_text.function[index*3+2].label[1]?' | ':''}}{{prodetail.goods_img_text.function[index*3+2].label[1]?prodetail.goods_img_text.function[index*3+2].label[1]:''}}{{prodetail.goods_img_text.function[index*3+2].label[2]?' | ':''}}{{prodetail.goods_img_text.function[index*3+2].label[2]?prodetail.goods_img_text.function[index*3+2].label[2]:''}}{{prodetail.goods_img_text.function[index*3+2].label[3]?' | ':''}}{{prodetail.goods_img_text.function[index*3+2].label[3]?prodetail.goods_img_text.function[index*3+2].label[3]:''}}</span>
									</div>
									<div class="pro-function-msg">
										{{prodetail.goods_img_text.function[index*3+2].describe}}
									</div>
								</div>
							</div>
						</CarouselItem>
					</Carousel>
					<img class="pro-change-img pro-next" src="../../public/imgs/more.png" alt="" v-if="prodetail.goods_img_text.function.length>3">
				</div>

				<div class="pro-info-list-box">
					<div class="pro-info-list-l">
						<div class="pro-info-list-lbg"></div>
						<img :src="prodetail.goods_img_text.img6" alt="">
					</div>
					<div class="pro-info-list-r">
						<div class="pro-info-list-line" :class="index==0?'pro-info-list-line1':''" v-for="(item,index) in prodetail.goods_img_text.advantage" :key="index" v-show="item.title!='美国制造'">
							<div class="pro-info-list-title">{{item.title}}</div>
							<div class="pro-info-list-msg">
								{{item.describe}}
							</div>
						</div>
					</div>
				</div>

				<div class="pro-madeinus" v-for="(item,index) in prodetail.goods_img_text.advantage" :key="index" v-show="item.title=='美国制造'">
					<div class="pro-made-l">
						<div class="pro-made-title Evolve">
							<h3>Made in USA</h3>
						</div>
						<div class="pro-made-txt">美国制造</div>
						<div class="pro-made-msg">
							{{item.describe}}
						</div>
					</div>
					<div class="pro-made-r">
						<!-- <img :src="prodetail.goods_img_text.img2" alt=""> -->
						<img :src="publicurl+'xs/Mask.png'" alt="">
					</div>
				</div>

				<div class="detail-pro-title zuobian">
					<div class="pro-title1 Evolve juzhong" style="font-size: 3vw;">
						<div class="meal-pc-main-title-line"></div>
						<h3>Product Information</h3>
					</div>
					<div class="pro-title-msg" style="font-size: 24px;line-height: normal;font-weight: normal;">
						产品信息
					</div>
					<div class="pro-detail-line"></div>
				</div>

				<div class="pro-center-info-box">
					<div class="pro-center-info-img">
						<img :src="prodetail.goods_img_text.img7" alt="">
					</div>
					<div class="pro-center-info-info" id="sc">
						<div class="pro-center-info-title Evolve">
							<h5>{{eng_name}}</h5>
						</div>
						<div class="pro-center-info-msg">
							{{goods_name| replaceCommaWithBlank}}
						</div>
						<div class="pro-center-info-icons">
							<img :src="item.url" alt="" v-for="(item,index) in prodetail.goods_detail.cat" :key="index">
						</div>
						<div class="pro-center-info-line"></div>
						<div class="pro-center-info-lmsg">
							每日营养推荐量
						</div>
						<div class="pro-center-info-num">
							{{prodetail.goods_detail.day_use}}颗
						</div>
					</div>
					<div class="pro-center-info-maininfo" :class="{ 'pro-center-info-maininfo-shadow': showmore&&!hasshow }" id='thisoutheight' :style="'max-height:'+maxheight" ref="thisoutheight">
						<div style="width: 100%;height: 100%;overflow: hidden;">
							<div ref="thisheight" style="width: 100%;height: auto;">
								<div class="pro-amount-title">
									<span class="Evolve">Supplement Facts </span>营养标签
								</div>
								<div class="pro-amount-th">
									<div class="pro-amount-th-l">
										<p>Amount Per Serving</p>
										<p>每份含成分({{prodetail.goods_detail.day_use}}颗)</p>
									</div>
									<div class="pro-amount-th-r">
										<p>% Daily Value</p>
										<p>每日营养摄入量百分比</p>
									</div>
								</div>
								<!-- <div class="pro-amount-td" v-for="(item,index) in prodetail.goods_detail.goods_unit" :key="index">
									<div class="pro-amount-td-l">{{item.unit_eng}} <p>{{item.zh}}</p></div>
									<div class="pro-amount-td-c">{{item.unit_size}}{{item.unit}}</div>
									<div class="pro-amount-td-r">{{item.unit_percentage}}</div>
								</div> -->
								<div class="pro-amount-td"
								v-for="(ingredientsItem,index) in prodetail.goods_detail.goods_unit" :key="index">
									<!-- 有分组的情况 -->
										<div v-if="ingredientsItem.group_en_name" style="width:100%">
											<div class="ingredients_info">
												<div class="ingredient_item_one">{{ingredientsItem.group_en_name}}</div>
												<div class="ingredient_item_two">{{ingredientsItem.group_unit_size}}{{ingredientsItem.group_unit}}</div>
												<div class="ingredient_item_three">{{ingredientsItem.group_unit_percentage}}</div>
											</div>
											<div class="ingredients_group">
												<div class="ingredient_group_en"><div v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_eng}}</div></div>
												<div class="ingredient_group_zh">{{ingredientsItem.group_zh_name}}<span v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_name}}{{ index<(ingredientsItem.ingredients).length-1?',':''}}</span></div>
											</div>
										</div>
										<!-- 没有分组的情况 -->
										<div v-else style="width:100%">
											<div class="ingredients_info ingredients_info_single" v-for="(item,index) in ingredientsItem.ingredients" :key="index">
												<div class="ingredient_item_one">{{item.unit_eng}} {{item.unit_name}}</div>
												<div class="ingredient_item_two">{{item.unit_size}}{{item.unit}}</div>
												<div class="ingredient_item_three">{{item.unit_percentage}}</div>
											</div>
										</div>
								</div>
								<div class="pro-amount-orther">
									其他成分：{{prodetail.goods_detail.other_component}}
								</div>
								<div class="pro-amount-more">
									*没有添加{{prodetail.goods_detail.exclusive}}
								</div>
							</div>
							<div class="showthisnow" v-if="showmore&&hasshow" :class="hasshow?'hasshowit':''">
								<!-- <img class="tbg" src="../../public/imgs/pro/jianbian.png" alt=""> -->
								<div class="click-showmore" v-if="hasshow" @click="hasshowfalse">
									<div>收起</div>
									<img style="transform: rotate(-90deg);" src="../../public/imgs/pro/more.png" alt="">
								</div>
							</div>
						</div>
						<div class="showthisnow" v-if="showmore&&!hasshow">
							<!-- <img class="tbg" src="../../public/imgs/pro/jianbian.png" alt=""> -->
							<div class="click-showmore" v-if="!hasshow" @click="hasshowtrue">
								<div>展开全部</div>
								<img src="../../public/imgs/pro/more.png" alt="">
							</div>
						</div>
					</div>
						<div class="showdetail"  @click="gounitdetail(prodetail.goods_detail.id)">
							查看100%透明营养成分表
						</div>
				</div>

				<div class="detail-pro-title zuobian" style="margin-top:calc(164px + 30px + 34px);">
					<div class="pro-title1 Evolve juzhong" style="font-size: 3vw;">
						<div class="meal-pc-main-title-line"></div>
						<!-- <p>Cautions</p> -->
						<h3>Cautions</h3>
					</div>
					<div class="pro-title-msg" style="font-size: 24px;line-height: normal;font-weight: normal;">
						注意事项
					</div>
					<div class="pro-detail-line"></div>
				</div>

				<div class="pro-populationlist">
					<div class="pro-population-line">
						<img class="pro-population-img" src="../../public/imgs/pro/c11.png" alt="">
						<div class="pro-population-title">
							<img src="../../public/imgs/pro/c1.png" alt="">
							<div>建议人群</div>
						</div>
						<div class="pro-population-txt">
							{{prodetail.goods_img_text.suggested_population}}
						</div>
					</div>
					<div class="pro-population-line">
						<img class="pro-population-img" src="../../public/imgs/pro/c21.png" alt="">
						<div class="pro-population-title">
							<img src="../../public/imgs/pro/c2.png" alt="">
							<div>服用建议</div>
						</div>
						<div class="pro-population-txt">
							{{prodetail.goods_img_text.advice_on_taking}}
						</div>
					</div>
					<div class="pro-population-line">
						<img class="pro-population-img" src="../../public/imgs/pro/c31.png" alt="">
						<div class="pro-population-title">
							<img src="../../public/imgs/pro/c3.png" alt="">
							<div>禁忌人群</div>
						</div>
						<div class="pro-population-txt">
							{{prodetail.goods_img_text.taboo_population}}
						</div>
					</div>
					<div class="pro-population-line">
						<img class="pro-population-img" src="../../public/imgs/pro/c41.png" alt="">
						<div class="pro-population-title">
							<img src="../../public/imgs/pro/c4.png" alt="">
							<div>特别注意</div>
						</div>
						<div class="pro-population-txt">
							{{prodetail.goods_img_text.points_for_attention}}
						</div>
					</div>
				</div>

				<div class="pro-main-img-bg">
					<img :src="prodetail.goods_img_text.img9" alt="">
				</div>

				<div class="pro-bottom-list" :class="prodetail.report_list.length>3?'':'nobtn'">
					<div class="pro-bottom-list-l">
						<div class="pro-bottom-list-title Evolve">
							<h3>Related</h3>
							<h3>Research</h3>
						</div>
						<div class="pro-bottom-list-msg">相关研究</div>
						<div class="change-one-btns" style="left: 0;" :class="prodetail.report_list.length>2?'noyincang':'yincang'" :style="prodetail.report_list.length>3?'':'opacity: 0;z-index: 3;cursor: default;'">
							<div class="change-one-last">
								<img src="../../public/imgs/more.png" alt="">
							</div>
							<div class="change-one-next">
								<img src="../../public/imgs/more.png" alt="">
							</div>
						</div>
					</div>
					<div class="pro-bottom-list-r">
						<Carousel :autoplay="false" :autoplay-speed="3000" dots="none"
							style="width: 320px;height: auto;margin-top: 0;">
							<CarouselItem v-for="(item,index) in prodetail.report_list" :key="index"
								style="width: 320px;height: auto;">
								<div class="pro-bottom-list-bt">
									<div class="pro-bottom-list-bname">{{item.title}}</div>
									<div class="pro-bottom-list-bmsg">{{item.intro}}</div>
									<div class="pro-bottom-list-bdetail cursor" @click="goreportdetail(item.id)">
										查看更多
									</div>
								</div>
							</CarouselItem>
						</Carousel>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import goodsUnit from './goods_unit'

	export default {
		name: 'productDetail',
		components: {
			goodsUnit
		},
		data: () => ({
			info: '',
			gid: '',
			maxheight:'600px',
			eng_name:'',
			goods_name:'',
			showtan:false,
			showtanl:false,
			showtanl2:false,
			showtan2:false,
			showmore:false,
			hasshow:false,
			cha:0,
			reportinfo:'',
			prodetail: '',
			publicurl:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/',
			bimg: ''
		}),
		updated() {
			var th = this.$refs.thisheight
			if(th.scrollHeight>500){
				this.showmore = true
			}
		},
		mounted() {
			var that = this
			that.gid = this.$route.query.id
			this.$ajax.post(this.API.api.apiurl.getGoodsInfo, {
					goods_id: that.$route.query.id
				})
				.then(res => {
					var data = res.data.data
					if (res.data.code == 0) {
						var goods_detail = data.goods_detail
						var sprice = goods_detail.shop_price
						var dayprice = Number(sprice * goods_detail.day_use).toFixed(2)
						goods_detail.dayprice = dayprice
						data.goods_detail = goods_detail
						var iu = data.goods_img_text.big_img
						//console.log(iu)
						that.bimg = iu
							var goods_units = data.goods_detail.goods_unit
							// goods_units.forEach((item,index)=>{
							// 	var eng = item.unit_eng
							// 	var name = item.unit_name
							// 	var ar = name.split(eng)
							// 	if (eng) {
							// 		goods_units[index].zh = ar[1]
							// 	} else {
							// 		goods_units[index].zh = name
							// 	}
							// })
							console.log(data.goods_detail.goods_unit)
							data.goods_detail.goods_unit = goods_units
						that.prodetail = data
						that.eng_name = data.goods_detail.eng_name
						that.goods_name = data.goods_detail.goods_name
					} else {
						that.$Message.info('错误：' + res.data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
		},
		methods: {
			hasshowtrue(){
				let scrollHeightOne = window.pageYOffset;
				this.cha = scrollHeightOne
				this.maxheight = 'inherit'
				this.hasshow=true
				this.showmore = true
				this.$forceUpdate()
			},
			hasshowfalse(){
				this.maxheight = '600px'
				this.hasshow=false
				this.showmore = true
				window.scrollTo(0,this.cha)
				this.$forceUpdate()
			},
			tlshowtan2(){
				if(this.showtanl2 == true){
					//console.log('')
				}else{
					this.showtan2 = false
				}
			},
			lshowtan2(){
				var that = this
				this.showtanl2 = true
				setTimeout(function(){
					that.showtanl2 = false
				},700)
			},
			tlshowtan(){
				if(this.showtanl == true){
					//console.log('')
				}else{
					this.showtan = false
				}
			},
			lshowtan(){
				var that = this
				this.showtanl = true
				setTimeout(function(){
					that.showtanl = false
				},700)
			},
			goBack() {
				if (this.$route.query.way == 'report') {
					this.$router.go(-1);
				}
			},
			gohome() {
				this.$router.push({
					path: 'pro_detail'
				})
			},
			gounitdetail() {
				//console.log(id)
				this.showtan2 = true
			},
			goreportdetail(id) {
				var that = this
				this.$ajax.post(this.API.api.apiurl.getReportInfo, {
						id:id
					})
					.then(res => {
						var data = res.data
						if (data.code == 0) {
							that.reportinfo = data.data
							that.showtan = true;
						} else {
							that.$Message.info('错误：' + data.msg)
						}
					})
					.catch(error => {
						that.$Message.info(error)
					})
			}
		},
	}
</script>

<style>
	.zuobian .pro-detail-line{
		display: none;
	}
	.maintanchuang{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 11;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0,0,0,0.3);
	}
	.maintanc{
		width: 92%;
		max-width: 568px;
		height: 70vh;
		max-height: 600px;
		border-radius: 10px;
		margin: 0 auto;
		align-items: center;
		background: #FFFFFF;
		position: relative;
		padding:50px 20px;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
	}
	.maintantxt{
		width: 100%;
		padding: 0 20px;
		height: 100%;
		overflow-x: hidden;
		overflow: scroll;
	}
	.maintantxt::-webkit-scrollbar {
		display: none;
	}
	.goods_unit-maintantxt{
		width: 100%;
		padding: 0 20px;
		height: 100%;
		overflow-x: hidden;
		overflow: scroll;
	}
	.goods_unit-maintantxt::-webkit-scrollbar {
		display: none;
	}
	.maintline{
		width: 20px;
		height: 20px;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}
	.maintline img{
		width: 100%;
		height: auto;
	}
	.goods_unit-maintline{
		width: 20px;
		height: 20px;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}
	.goods_unit-maintline img{
		width: 100%;
		height: auto;
	}
		.maintantxt p strong span{
			font-size: 24px!important;
		}
		.maintantxt p span{
			font-size: 18px!important;
			background: #FFFFFF!important;
		}
	.showdetail{
		position: absolute;
		width: 220px;
		font-size: 18px;
		margin: 0 auto;
		text-decoration: underline;
		bottom: calc(-30px - 34px);
		right: calc(16.5vw - 110px);
		right: calc(290px - 110px);
		cursor: pointer;
		text-align: center;
	}
	.pro-amount-orther{
		padding: 8px 0;
		font-size: 14px;
		line-height: normal;
		font-weight: bold;
		border-top: 1px solid #1A1A1A;
		border-bottom: 2px solid #1A1A1A;
		cursor: auto;
	}
	.pro-amount-more{
		color: #999999;
		margin-top: 8px;
		font-size: 14px;
	}
	.pro-amount-title{
		font-size: 24px;
		margin-bottom: 16px;
		font-weight: bold;
	}
	.pro-amount-td{
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 8px 0;
		font-size: 14px;
		line-height: normal;
		border-bottom: 1px solid #1A1A1A;
	}
	.pro-amount-th{
		border-top: 4px solid #1A1A1A;
		display: flex;
		justify-content: space-between;
	}
	.pro-amount-td-l{
		width: 18.15vw;
		width: 50%;
		text-align: left;
	}
	.pro-amount-td-c,.pro-amount-td-r{
		width: calc(50% - 9.075vw);
		width: 25%;
		height: auto;
		text-align: right;
	}
	.pro-amount-th-l{
		font-size: 14px;
		line-height: normal;
		margin: 12px 0;
		font-weight: bold;
	}
	.pro-amount-th-r{
		font-size: 14px;
		line-height: normal;
		margin: 12px 0;
		text-align: right;
		font-weight: bold;
	}
	.pro-center-info-box{
		width: 75vw;
		width: 1200px;
		/* min-height: 50.58vw; */
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		/* align-items: center; */
		color: #1A1A1A;
		text-align: left;
		/* margin-bottom: calc(2.1vw + 34px); */
		position: relative;
	}
	.pro-center-info-title{
		font-size: 32px;
		line-height: 1.2;
	}
	.pro-center-info-lmsg{
		font-size: 18px;
		margin-top: 32px;
		margin-bottom: 8px;

	}
	.pro-center-info-num{
		font-size: 24px;
		font-weight: bold;
	}
	.pro-center-info-line{
		width: 100%;
		height: 1px;
		border-bottom: 2px solid #1A1A1A;
	}
	.pro-center-info-msg{
		font-size: 24px;
		margin-top: 16px;
		margin-top: 5px;
	}
	.pro-center-info-icons{
		display: flex;
		flex-wrap: wrap;
		width: 240px;
		margin: 64px 0 34px 0;
	}
	.pro-center-info-icons img{
		width: 48px;
		height: 48px;
		margin: 0 30px 30px 0;
	}
	.pro-center-info-maininfo{
		width: 33vw;
		width: 580px;
		height: auto;
		/* max-height: 600px; */
		padding: 30px;
		border: 2px solid #1A1A1A;
		position: relative;
		/* padding-bottom: 0; */
		overflow: hidden;
	}

	.pro-center-info-maininfo-shadow ::before {
		content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg,rgba(255,255,255,0) 30%,rgba(255,255,255,0.1) 100%);
	}
	.goods_unit-maintantxt .pro-center-info-maininfo{
		max-height: inherit;
	}
	.showthisnow{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 88px;
		display: flex;
		/* background: rgba(255,255,255,0.9);
		background-image: url(../../public/imgs/pro/jianbian.png);
		background-repeat: no-repeat;
		background-size: 140%; */
		background-color: #FFFFFF;
		background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
		justify-content: center;
		/* align-items: center; */
	}
	.hasshowit{
		position: inherit;
		height: auto;
		bottom: 0;
		left: 0;
		margin-top: -20px;
	}
	.click-showmore{
		width: auto;
		height: 20px;
		line-height: 20px;
		display: flex;
		font-size: 16px;
		color: #C25E4D;
		cursor: pointer;
		margin-top: 34px;
	}
	.click-showmore img{
		width: 10px;
		height: 16px;
		margin-top: 2px;
		margin-left: 10px;
		transform: rotate(90deg);
	}
	.pro-center-info-maininfo .tbg{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
	.pro-center-info-info{
		width: 20vw;
		width: 320px;
		height: auto;
		/* margin: 0 4vw 0 2vw; */
		align-items: center;
	}
	.pro-center-info-img{
		width: 14vw;
		width: 172.5px;
		height: auto;
		display: flex;
		align-items: center;
		align-items: flex-start;
		justify-content: center;
	}
	.pro-center-info-img img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	.pro-populationlist{
		width: 75vw;
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 9.25vw;
		margin-bottom: 133px;
	}
	.pro-population-line{
		width: 35vw;
		height: 13.88vw;
		padding: 2.35vw;
		width: calc(50% - 15px);
		height: auto;
		padding: 30px;
		background: rgb(230,234,238);
		border-radius: 10px;
		margin-bottom: 2.55vw;
		margin-bottom: 30px;
		text-align: left;
		position: relative;
	}
	.pro-population-img{
		position: absolute;
		width: 6.25vw;
		height: auto;
		top: 0;
		right: 0;
		/* display: none; */
	}
	.pro-population-title{
		width: auto;
		height: auto;
	}
	.pro-population-title img{
		width: auto;
		height: 2.82vw;
		max-height: 55px;
	}
	.pro-population-title div{
		width: auto;
		margin-top: 1.1vw;
		margin-top: 16px;
		color: #1A1A1A;
		font-size: 24px;
		font-size: 18px;
		font-weight: bold;
	}
	.pro-population-txt{
		margin-top: 16px;
		color: #666666;
		line-height: 1.5;
		text-align: justify;
		font-size: 18px;
		/* height: 57.6px; */
		overflow: hidden;
	}
	.pro-main-img-bg{
		width: 100%;
		height: 36vw;
		max-height: 600px;
		display: flex;
		align-items: center;
		overflow: hidden;
	}
	.pro-main-img-bg img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	.pro-bottom-list{
		width: 87.5vw;
		margin-left: 12.5vw;
		width: calc(50vw + 600px);
		margin-left: calc(50vw - 600px);
		display: flex;
		justify-content: space-between;
		margin-top: 9.4vw;
		margin-top: 135.2px;
		text-align: left;
		color: #1A1A1A;
		position: relative;
	}
	.pro-bottom-list .change-one-btns{
		left: 0!important;
	}
	.pro-bottom-list .ivu-carousel-track{
		display: flex;
	}
	.pro-bottom-list .ivu-carousel-list{
		width: 60vw;
		width: calc(50vw + 600px - 300px);
		overflow: hidden;
	}
	.pro-bottom-list .ivu-carousel-dots{
		display: none;
	}
	.pro-bottom-list .ivu-carousel button {
		width: 3vw;
		height: 3vw;
		width: 60px;
		height: 60px;
		opacity: 0 !important;
		z-index: 1;
	}

	.pro-bottom-list .ivu-carousel-arrow.left {
		top: auto!important;
		bottom: 1vw!important;
		bottom: 0px!important;
		left: -27.5vw !important;
		left: -300px !important;
		right: auto !important;
	}

	.pro-bottom-list .ivu-carousel-arrow.right {
		top: auto!important;
		bottom: 1vw!important;
		bottom: 0px!important;
		left: -22.5vw !important;
		left: calc(2vw + 60px - 300px) !important;
		right: auto !important;
	}
	.pro-bottom-list-bt{
		width: 280px;
		height: 336px;
		border-radius: 10px;
		/* box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); */
		margin: 2vw 20px 2vw 20px;
		margin: 28.8px 20px 28.8px 20px;
		padding: 30px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background:#F5F5F5;
	}
	.pro-bottom-list-bmsg{
		font-size: 18px;
		height:135px;
		overflow: hidden;
		color: #666666;
		margin: 16px 0 32px 0;
		line-height: 1.5;
		text-align: justify;
		width: 100%;
		align-items: center;
			display: -webkit-box;  /*  display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示  */
      -webkit-box-orient: vertical; /*  -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式  */
      -webkit-line-clamp: 5; /*  -webkit-line-clamp用来限制在一个块元素显示的文本的行数 */
      overflow: hidden;
	}
	.pro-bottom-list-bdetail{
		font-size: 18px;
		text-decoration: underline;
		align-items: center;
		width: 100%;
	}
	.pro-bottom-list-bname{
		font-size: 24px;
		font-weight: bold;
		/* overflow: hidden; */
		line-height: 30px;
		width: 100%;
		align-items: center;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}
	.pro-bottom-list-l{
		width: 16.8vw;
		width: 300px;
		height: auto;
		position: relative;
	}
	.pro-bottom-list-title{
		font-size: 3vw;
		font-weight: bold;
		line-height: 1;
		margin-top: 2vw;
		margin-top: 28.8px;
	}
	.pro-bottom-list-msg{
		font-size: 24px;
		margin-top: 8px;
		margin-top: 16px;
	}
	.pro-bottom-list-r{
		width: 60vw;
		width: calc(50vw + 600px - 300px);
		height: auto;
		/* overflow: hidden; */
	}
	.pro-info-list-title{
		font-size: 24px;
		font-weight: bold;
		position: relative;
		text-indent: 15px;
	}
	.pro-info-list-title:before {
		content: "";
		background: #1A1A1A;
		content: "";
		height: 8px;
		position: absolute;
		top: 14px;
		left: 0;
		width: 8px;
		border-radius: 8px;
	}
	.pro-info-list-msg{
		font-size: 18px;
		margin-top: 16px;
		text-align: justify;
		margin-left: 15px;
		line-height: 1.5;
	}
	.pro-info-list-line {
		width: 35vw;
		width: 560px;
		height: auto;
		background: #FFFFFF;
		margin-bottom: 32px;
		margin-bottom: 64px;
		/* padding:1.5vw 2vw; */
		position: relative;
		/* margin-left: 2vw; */
	}
	/* .pro-info-list-line:hover{
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
	}

		.pro-info-list-line:hover::before {
			content: "";
			position: absolute;
			top: 20px;
			left: -20px;
			border-width: 15px 12px 10px 8px;
			border-color: transparent #FFFFFF transparent transparent;
			border-style: solid;
		} */



	.pro-info-list-r {
		width: 35vw;
		width: 560px;
		height: auto;
		align-items: center;
	}

	.pro-info-list-box {
		width: 75vw;
		width: 1200px;
		height: auto;
		margin: 0 auto;
		margin-top: 8vw;
		margin-top: 115px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: left;
		color: #1A1A1A;
		margin-bottom: 11.4vw;
		margin-bottom: 164px;
	}

	.pro-info-list-l {
		width: 30vw;
		height: 30vw;
		width: 520px;
		height: 520px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.pro-info-list-lbg{
		width: 460px;
		height: 460px;
		position: absolute;
		border-radius: 460px;
		margin: 30px;
		background: #E6EAEE;
	}

	.pro-info-list-l img {
		height: 100%;
		width: auto;
		margin:0 auto;
		position: absolute;
	}

	.pro-madeinus {
		width: 100%;
		height: auto;
		max-height: 700px;
		overflow: hidden;
		display: flex;
		background: #D9C8BF;
		align-items: center;
		color: #1A1A1A;
	}

	.pro-made-title {
		font-size: 3vw;
		line-height: 1.2;
		font-weight: bold;
	}

	.pro-made-txt {
		font-size: 24px;
		margin: 16px 0 32px 0;
	}

	.pro-made-msg {
		font-size: 18px;
		text-align: justify;
	}

	.pro-made-l {
		width: calc(100% - 51.4vw);
		align-items: center;
		height: auto;
		text-align: left;
		padding: 0 9.72vw 0 9.72vw;
		margin-top: -0.3vw;
	}

	.pro-made-r {
		width: 51.4vw;
		height: 41.65vw;
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	.pro-made-r img {
		/* width: 100%;
		height: auto; */
		width: auto;
		height: 100%;
		align-items: center;
	}

	.pro-change-img {
		position: absolute;
		width: 2vw;
		height: 2vw;
	}

	.pro-function-box {
		margin: 0 5px;
	}

	.pro-function-title {
		margin-top: 1.88vw;
		margin-top: 27px;
		font-size: 24px;
		color: #1A1A1A;
		font-weight: bold;
	}

	.pro-function-status {
		font-size: 18px;
		margin-top: 16px;
		color: #1A1A1A;
	}

	.pro-function-msg {
		margin: 1.88vw 0;
		margin: 27px 0;
		color: #1A1A1A;
		font-size: 18px;
		text-align: justify;
	}

	.pro-last {
		top: 50%;
		margin-top: -1vw;
		left: -3.2vw;
		transform: rotate(180deg);
	}

	.pro-next {
		top: 50%;
		margin-top: -1vw;
		right: -3.2vw;
	}

	.pro-function-list{
		width: 1200px!important;
	}

	.pro-function-list .ivu-carousel button {
		opacity: 0 !important;
	}

	.pro-function-list .ivu-carousel-arrow.left {
		left: -3vw !important;
	}

	.pro-function-list .ivu-carousel-arrow.right {
		right: -3vw !important;
	}

	.pro-function-box {
		width: 19.24vw;
		height: auto;
		max-width: 30%;
	}

	.pro-function-img {
		width: 11.76vw;
		height: 11.76vw;
		max-width: 300px;
		max-height: 300px;
		margin: 0 auto;
		border-radius: 11.76vw;
	}

	.pro-function-img img {
		width: 100%;
		height: auto;
	}

	.pro-function-list .ivu-carousel-track.higher {
		display: flex;
	}

	.pro-detail-line {
		width: 100px;
		height: 12px;
		background: #C25E4D;
		margin: 1.5vw auto 6.1vw auto;
	}
	.pro-title-msg{
		margin-top: 16px;
	}

	.prodetail {
		padding-bottom: 3.7vw;
		padding-bottom: 53px;
	}

	.prodetail-top {
		width: 100%;
		height: auto;
	}

	.pro-top-bg {
		width: 100%;
		height: auto;
		position: relative;
		background-color: #f5f5f5;
	}

	.pro-bg-url {
		width: 100%;
		height: auto;
	}

	.detail-name-box {
		width: 100%;
		height: auto;
		position: absolute;
		top: 50%;
	}

	.en-name {
		width: 100%;
		height: 0.8rem;
		line-height: 0.8rem;
		font-size: 0.43rem;
		font-weight: bold;
		color: #1A1A1A;
	}

	.zn-name {
		width: 100%;
		height: 0.8rem;
		line-height: 0.8rem;
		font-size: 0.59rem;
		font-weight: bold;
		color: #1A1A1A;
	}

	.detailprice {
		width: 32%;
		height: 0.6rem;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		margin-top: 0.5rem;
	}

	.detailprice span {
		width: auto;
		height: 0.42rem;
		font-size: 0.32rem;
		color: #1A1A1A;
	}

	.detail-cats {
		width: 100%;
		height: 1rem;
		display: flex;
		justify-content: center;
		margin-top: 1.1rem;
	}

	.detail-cat {
		width: 1.1rem;
		height: 1rem;
		text-align: center;
		margin: 0 0.2rem;
	}

	.detail-cat .cat-img {
		width: 0.56rem;
		height: 0.56rem;
		margin: 0 0.27rem;
	}

	.cat-img img {
		width: 100%;
		height: 100%;
	}

	.detail-cat .cat-name {
		width: 100%;
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.33rem;
		text-align: center;
	}

	.prodetail-main {
		width: 100%;
		height: auto;
		background: #FFFFFF;
		padding-top: 0.7rem;
	}

	.prodetail-txt {
		width: 84%;
		height: auto;
		line-height: 0.74rem;
		font-size: 0.37rem;
		text-align: left;
		color: #1A1A1A;
		margin: 0 auto;
	}


	.prodetail-imgs {
		width: 100%;
		height: auto;
		margin-top: 0.2rem;
	}

	.prodetail-imgs img {
		width: 100%;
		height: auto;
	}

	.detail-pro-title {
		width: 100%;
		height: auto;
		line-height: 1.1rem;
		font-size: 0.75rem;
		font-weight: bold;
		text-align: center;
		color: #1A1A1A;
		margin-top: 1.9rem;
		margin-bottom: 0.7rem;
	}

	.function-list {
		width: 100%;
		height: auto;
	}

	.function-box {
		width: 86%;
		height: 1.7rem;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		margin-top: 0.7rem;
	}

	.function-box img {
		width: 1.7rem;
		height: 1.7rem;
		border-radius: 1.7rem;
	}

	.function-txts {
		width: calc(100% - 2rem);
		height: 1.7rem;
	}

	.function-txts .function-title {
		width: 100%;
		height: 0.84rem;
		line-height: 0.84rem;
		font-size: 0.37rem;
		font-weight: bold;
		border-bottom: 0.02rem solid #000000;
		text-align: left;
		color: #1A1A1A;
	}

	.function-txts .function-msg {
		width: 100%;
		height: 0.84rem;
		line-height: 0.84rem;
		font-size: 0.32rem;
		text-align: left;
		color: #1A1A1A;
	}

	.advantage-list {
		width: 100%;
		height: auto;
		margin-top: 1.6rem;
	}

	.advantage-list .advantage-box {
		width: 86%;
		height: auto;
		margin: 0 auto;
		margin-bottom: 1rem;
	}

	.advantage-box .advantage-title {
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		font-weight: bold;
		color: #1A1A1A;
		font-size: 0.5rem;
		text-align: left;
	}

	.advantage-txt {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin-top: 0.2rem;
	}

	.advantage-txt .advantage-border {
		width: 0.7rem;
		height: 0.1rem;
		/* background: #C25E4D; */
		border-bottom: 0.13rem solid #C25E4D;
		margin-top: 0.17rem;
	}

	.advantage-txt .advantage-msg {
		width: calc(100% - 1.4rem);
		line-height: 0.62rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		text-align: left;
	}

	.pro-info-title {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
		margin: 1rem 0 0.6rem 0;
	}

	.pro-info-title .title-l-l1 {
		width: 12.5px;
		height: 30px;
		border-bottom: 4px solid #FAC1B7;
		margin-top: 7.5px;
		-webkit-transform: rotate(123deg);
	}

	.pro-info-title .title-l-l2 {
		width: 20px;
		height: 30px;
		border-bottom: 4px solid #C25E4D;
		margin-top: 7.5px;
		-webkit-transform: rotate(123deg);
	}

	.pro-info-title .title-r-r1 {
		width: 12.5px;
		height: 30px;
		border-bottom: 4px solid #FAC1B7;
		margin-top: 7.5px;
		-webkit-transform: rotate(-123deg);
	}

	.pro-info-title .title-r-r2 {
		width: 20px;
		height: 30px;
		border-bottom: 4px solid #C25E4D;
		margin-top: 7.5px;
		-webkit-transform: rotate(-123deg);
	}

	.pro-info-title .pro-info-box {
		width: auto;
		height: 1.39rem;
		margin: 0 0.35rem;
		position: relative;
	}

	.pro-info-name {
		font-size: 0.65rem;
		font-weight: bold;
		color: #1A1A1A;
		line-height: 1rem;
	}

	.pro-info-msg {
		width: 6rem;
		line-height: 0.6rem;
		font-size: 0.34rem;
		color: #1A1A1A;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -3rem;
	}

	.pro-infobg-box {
		width: 90%;
		height: auto;
		background: #FFFFFF;
		border-radius: 0.19rem;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		margin: 0 auto;
		padding: 0.6rem;
	}

	.infobg-title {
		width: 100%;
		height: auto;
	}

	.infobg-title div {
		text-align: left;
		font-weight: bold;
		line-height: 0.6rem;
		color: #1A1A1A;
		font-size: 0.33rem;
	}

	.infobg-title .t {
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		text-align: left;
		font-size: 0.65rem;
	}

	.infobg-cats {
		width: 100%;
		height: 0.65rem;
		display: flex;
		justify-content: left;
		margin: 0.5rem 0;
	}

	.infobg-cats img {
		width: 0.65rem;
		height: 0.65rem;
		margin-right: 0.3rem;
	}

	.goods_label-box {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		margin: 11.4vw 0;
		/* margin: 164px 0; */
	}
	.detail_pc .goods_label-box{
		padding: 41px 0!important;
	}
	.detail_pc .firstone{
		padding-top: 82px!important;
		justify-content: space-between!important;
	}
	.detail_pc .firstone1{
		padding-top: 82px!important;
		justify-content: space-between!important;
	}
	.detail_pc .lastone .goods_label-box{
		padding-bottom: 82px!important;
		justify-content: space-around!important;
	}

	.goods_label {
		width: 45%;
		height: 1rem;
		line-height: 1rem;
		display: flex;
		justify-content: left;
		font-size: 0.37rem;
		color: #1A1A1A;
		margin-bottom: 0.4rem;
	}

	.goods_label img {
		width: 1rem;
		height: 1rem;
		margin-right: 0.2rem;
	}

	.redayuse {
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.32rem;
		color: #1A1A1A;
		text-align: left;
	}

	.redayuse .b {
		font-size: 0.46rem;
		font-weight: bold;
		margin-right: 0.2rem;
	}

	.day-use-box {
		width: 100%;
		display: flex;
		height: auto;
		justify-content: center;
		flex-wrap: wrap;
	}

	.day-use-box img {
		width: 1.3rem;
		height: 1.3rem;
		margin: 0.2rem 0;
	}

	.day-use-box .day-use-num {
		width: 100%;
		height: 0.7rem;
		line-height: 0.7rem;
		font-size: 0.37rem;
		text-align: center;
	}

	.bg-title {
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.34rem;
		color: #1A1A1A;
		text-align: left;
		border-bottom: 0.08rem solid #000000;
	}

	.bg-title .b {
		font-size: 0.59rem;
		font-weight: bold;
		color: #1A1A1A;
	}

	.goods_unit-title {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		padding: 0.2rem 0;
		border-bottom: 0.02rem solid #000000;
		font-weight: bold;
		color: #1A1A1A;
	}

	.goods_unit-name {
		width: 56%;
		height: auto;
		line-height: 0.52rem;
		font-size: 0.28rem;
		color: #1A1A1A;
		text-align: left;
	}

	.goods_unit-num div {
		width: 100%;
		text-align: right;
	}

	.goods_unit-x {
		width: 22%;
		text-align: right;
	}

	.goods_unit-num {
		width: 22%;
		height: auto;
		text-align: right;
	}

	.goods_unit-nums {
		text-align: right;
	}

	.goods_unit-line {
		width: 100%;
		height: auto;
		line-height: 0.41rem;
		font-size: 0.26rem;
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		border-bottom: 0.02rem solid #000000;
		color: #1A1A1A;
		padding: 0.1rem 0;
	}

	.bborder {
		border-bottom: 0.04rem solid #000000;
	}

	.exclusive {
		width: 100%;
		height: auto;
		padding: 0.3rem;
		background: rgba(200, 208, 216, 0.5);
		border-radius: 0.19rem;
		text-align: left;
		margin-top: 0.5rem;
		font-size: 0.3rem;
	}

	.gochecklist {
		width: 70%;
		height: auto;
		border-radius: 1rem;
		line-height: 1rem;
		margin: 1rem auto 0.5rem auto;
		display: flex;
		justify-content: center;
		border: 0.07rem solid #C8D0D8;
		font-size: 0.37rem;
	}

	.gochecklist img {
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.35rem 0;
	}

	.populationlist {
		width: 86%;
		height: auto;
		margin: 0 auto;
		margin-bottom: 0.9rem;
	}

	.population-line {
		width: 100%;
		line-height: 0.8rem;
	}

	.population-title {
		width: 100%;
		display: flex;
		justify-content: left;
		line-height: 0.8rem;
		font-size: 0.39rem;
		font-weight: bold;
	}

	.population-title img {
		width: 0.4rem;
		height: 0.4rem;
		margin: 0.2rem 0;
		margin-right: 0.2rem;
	}

	.population-txt {
		width: 100%;
		line-height: 0.65rem;
		font-size: 0.38rem;
		text-align: left;
		margin-bottom: 0.5rem;
		margin-top: 0.1rem;
	}

	.yanjiu {
		width: 90%;
		height: auto;
		margin: 0 auto;
		margin-top: 1.8rem;
	}

	.yanjiu .yan-title {
		width: 100%;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.32rem;
		border-left: 0.07rem solid #C25E4D;
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	.yan-name {
		text-indent: 0.1rem;
	}

	.yan-name .b {
		font-weight: bold;
		font-size: 0.46rem;
		margin-right: 0.1rem;
	}

	.yan-title img {
		position: absolute;
		top: -0.8rem;
		width: 3.7rem;
		height: 2.66rem;
		right: 0;
	}

	.yan-line {
		width: 100%;
		height: auto;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		margin-top: 0.8rem;
		padding: 0.4rem;
	}

	.yan-line-title {
		width: 100%;
		/* height: 0.8rem; */
		height: auto;
		line-height: 0.8rem;
		font-size: 0.46rem;
		font-weight: bold;
		text-align: left;
		color: #1A1A1A;
	}

	.yan-line-txt {
		width: 100%;
		height: auto;
		line-height: 0.65rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		text-align: left;
		margin: 0.2rem 0 0.5rem 0;
	}

	.yan-detail {
		width: 100%;
		height: 0.6rem;
		display: flex;
		justify-content: flex-end;
		line-height: 0.6rem;
		color: #C25E4D;
		font-size: 12px;
	}

	.yan-detail img {
		width: 0.15rem;
		height: 0.2rem;
		margin: 0.2rem 0;
		margin-left: 0.15rem;
		margin-right: 0.15rem;
	}

	.hbg {
		width: 100%;
		height: auto;
		background: #FAFAFA;
		padding-top: 0.01rem;
		padding-bottom: 1rem;
	}

	.food_origin {
		width: 84%;
		height: auto;
		margin: 1rem auto 0 auto;
	}

	.food_origin .food_origin-title {
		width: 100%;
		height: 0.8rem;
		line-height: 0.8rem;
		text-align: left;
		font-size: 0.42rem;
		font-weight: bold;
		color: #1A1A1A;
	}

	.food_origin .food_origin-txt {
		width: 100%;
		height: auto;
		line-height: 0.72rem;
		text-align: left;
		font-size: 0.37rem;
		color: #1A1A1A;
	}

	/* pc */
	.detail_pc .prodetail-top {
		width: 100%;
		height: 7.41rem;
		background: #FFFFFF;
		margin: 0.74rem 0 1.19rem 0;
	}

	.detail_pc .pro-top-bg {
		width: 18.5rem;
		height: 7.41rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

	}

	.detail_pc .detail-name-box {
		position: relative;
		width: 30vw;
		height: 7.41rem;
		top: 0;
		margin: 0 5vw;
	}
	.detail_pc .pro-top-position{
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.detail_pc .pro-bg-url-img {
		position: relative;
		width: 8.98rem;
		height: 7.41rem;
		overflow: hidden;
	}

	.detail_pc .pro-bg-url {
		width: 100%;
		height: auto;
	}

	.detail_pc .en-name {
		width: 100%;
		height: 0.44rem;
		line-height: 0.44rem;
		font-size: 0.3rem;
		text-align: left;
		color: #1A1A1A;
	}

	.detail_pc .zn-name {
		width: 100%;
		height: 0.83rem;
		line-height: 0.83rem;
		font-size: 0.59rem;
		text-align: left;
		color: #1A1A1A;
		margin-top: 0.06rem;
	}

	.detail_pc .prodetail-txt {
		width: 5.8rem;
		margin: 0;
		height: auto;
		margin-top: 0.59rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #1A1A1A;
		text-align: justify;
	}

	.detail_pc .pro-dayprice{
		width: auto;
		font-size: 18px;
		margin-top: 2.2vw;
		margin-top: 40px;
		text-align: left;
	}
	.pro-dayprice span{
		margin-right: 2vw;
	}

	.detail_pc .detail-cats {
		width: 100%;
		height: 1.06rem;
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		justify-content: left;
	}

	.detail_pc .detail-cat {
		width: auto;
		height: auto;
		margin: 0;
		margin-right: 0.67rem;
	}

	.detail_pc .detail-cat .cat-img {
		width: 0.6rem;
		height: 0.6rem;
		margin: 0;
	}

	.detail_pc .detail-cat .cat-name {
		line-height: 0.31rem;
		font-size: 0.22rem;
		color: #1A1A1A;
		margin-top: 0.17rem;
	}

	.detail_pc .detailprice {
		position: absolute;
		margin: 0;
		bottom: 1.58rem;
		left: 0;
		width: 100%;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #1A1A1A;
		text-align: left;
		justify-content: left;
	}

	.detail_pc .detailprice span {
		margin-right: 0.59rem;
	}

	.detail_pc .goods_label-box {
		width: 75%;
		width: 1200px;
		margin: 0 auto;
		height: 2.24rem;
		/* background: #D5DDE6; */
		padding: 0 calc(50% - 9.25rem);
		justify-content: space-evenly;
	}

	.detail_pc .goods_label {
		width: 1.48rem;
		height: 1.06rem;
		width: 150px!important;
		display: grid;
		display: block;
		margin: 0.59rem 0;
	}

	.detail_pc .goods_label img {
		width: 0.6rem;
		height: 0.6rem;
		max-width: 100px;
		max-height: 100px;
		margin: 0 auto;
	}

	.detail_pc .goods_label div {
		width: 100%;
		height: auto;
		line-height: 1.5;
		color: #1A1A1A;
		font-size: 0.22rem;
		text-align: center;
		margin-top: 0.15rem;
	}

	.detail_pc .prodetail-main {
		width: 18.5rem;
		margin: 0 auto;
		padding-top: 0;
		position: relative;
		/* padding-bottom: 2.37rem; */
		background: none;
	}

	.dbottom-bg {
		width: 100vw;
		height: 8rem;
		position: absolute;
		bottom: 0;
		left: 0;
		margin-left: calc(9.25rem - 50vw);
		background: #F9FAFB;
		z-index: -1;
	}

	.tian-box {
		width: 18.5rem;
		width: 1200px!important;
		height: auto;
		display: flex;
		justify-content: space-between;
	}

	.tian-box1 {
		width: 18.5rem;
		width: 1200px!important;
		height: auto;
		display: flex;
		justify-content: center;
	}

	.detail_pc .food_origin {
		width: 7.39rem;
		margin-right: 0.52rem;
		height: 6.46rem;
		margin: 0;
	}

	.detail_pc .prodetail-imgs {
		width: 10.59rem;
		height: 6.46rem;
		width: 45%;
		height: auto;
		margin-top: 0;
	}

	.detail_pc .food_origin .food_origin-title {
		margin-top: 1.67rem;
		height: 0.61rem;
		font-size: 0.44rem;
		font-weight: bold;
		text-align: left;
		color: #1A1A1A;
		line-height: 1.2;
	}

	.detail_pc .food_origin .food_origin-txt {
		width: 100%;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #1A1A1A;
		margin-top: 0.59rem;
	}

	.detail_pc .detail-pro-title {
		margin-top: 5.5vw;
		margin-top: 8.8vw;
		margin-top: 126.6px;
		margin-bottom: 6.1vw;
		margin-bottom: 5.7vw;
		margin-bottom: 82px;
		line-height: 1.2;
		font-size: 0.89rem;
		color: #1A1919;
	}

	.prodetail-imgs1 {
		width: 8.96rem;
		height: 6.72rem;
		margin: 0 auto;
		margin-top: 1rem;
	}

	.prodetail-imgs1 img {
		width: 100%;
		height: 100%;
	}

	.detail_pc .function-list {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin-top: 2rem;
		flex-wrap: wrap;
	}

	.detail_pc .function-list1 {
		justify-content: center;
	}

	.detail_pc .function-box {
		margin: 0;
		margin-top: 0;
		width: 7.6rem;
		height: 1.48rem;
		margin-bottom: 1.19rem;
	}

	.detail_pc .function-box img {
		width: 1.48rem;
		height: 1.48rem;
	}

	.detail_pc .function-txts {
		width: calc(100% - 1.78rem);
	}

	.detail_pc .function-txts .function-title {
		font-size: 0.37rem;
		color: #1A1A1A;
		height: 0.7rem;
		line-height: 0.7rem;
		border-bottom: 0.07rem solid #000000;
	}

	.detail_pc .function-txts .function-msg {
		font-size: 0.26rem;
		height: 0.41rem;
		line-height: 0.41rem;
		margin-top: 0.3rem;
		color: #1A1A1A;
	}

	.prodetail-imgs2 {
		width: 100%;
		height: 11.31rem;
		z-index: 1;
		position: relative;
	}

	.img22 {
		width: 15.33rem;
		height: 9.33rem;
		margin-left: calc(100% - 15.33rem);
		overflow: hidden;
	}

	.img22 img {
		width: 15.33rem;
		height: auto;
		z-index: 1;
	}

	.prodetail-imgs2 .imgb {
		width: 5.8rem;
		height: 8.43rem;
		background: #D5DDE6;
		z-index: -1;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.detail_pc .advantage-list {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: left;
		flex-wrap: wrap;
		margin-top: 1.19rem;
	}

	.detail_pc .advantage-list .advantage-box {
		width: 5.19rem;
		margin: 0;
		margin-right: 1.45rem;
		margin-bottom: 1.19rem;
	}

	.detail_pc .advantage-box .advantage-title {
		width: 3.24rem;
		height: 1.22rem;
		line-height: 0.61rem;
		font-size: 0.44rem;
		color: #1A1A1A;
	}

	.detail_pc .advantage-txt {
		display: block;
		margin-top: 0.44rem;
	}

	.detail_pc .advantage-txt .advantage-border {
		width: 0.96rem;
		height: 0.11rem;
		background: #C25E4D;
		border: none;
		margin-top: 0;
		margin-bottom: 0.59rem;
	}

	.detail_pc .advantage-txt .advantage-msg {
		width: 100%;
		line-height: 0.59rem;
		font-size: 0.37rem;
		color: #1A1A1A;
	}

	#app .noright {
		margin-right: 0;
	}

	.detail_pc .pro-info-name {
		height: 0.83rem;
		font-size: 0.59rem;
		line-height: 0.83rem;
		color: #1A1A1A;
	}

	.detail_pc .pro-info-msg {
		height: 0.56rem;
		line-height: 0.56rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		margin-top: 0.15rem;
	}

	.detail_pc .pro-info-title .title-r-r2 {
		width: 30px;
		height: 30px;
		border-bottom: 5px solid #C25E4D;
		margin-top: 7.5px;
		-webkit-transform: rotate(-117deg);
	}

	.detail_pc .pro-info-title .title-l-l2 {
		width: 30px;
		height: 30px;
		border-bottom: 5px solid #C25E4D;
		margin-top: 7.5px;
		-webkit-transform: rotate(117deg);
	}

	.detail_pc .pro-info-title .title-r-r1 {
		width: 17.5px;
		height: 18px;
		border-bottom: 4px solid #FAC1B7;
		margin-top: 7.5px;
		-webkit-transform: rotate(-117deg);
	}

	.detail_pc .pro-info-title .title-l-l1 {
		width: 17.5px;
		height: 18px;
		border-bottom: 4px solid #FAC1B7;
		margin-top: 7.5px;
		-webkit-transform: rotate(117deg);
	}

	.detail_pc .pro-infobg-box {
		width: 100%;
		padding: 1.19rem;
		margin-bottom: 2.37rem;
	}

	.detail_pc .hbg {
		width: 100%;
		height: auto;
		padding: 0 calc(50% - 9.25rem);
	}

	.detail_pc .pro-info-title {
		margin-top: 0;
	}

	.detail_pc .infobg-title .t {
		height: 1.24rem;
		font-size: 0.89rem;
		line-height: 1.24rem;
		color: #1A1A1A;
	}

	.detail_pc .infobg-title div {
		line-height: 0.67rem;
		font-size: 0.44rem;
		color: #1A1A1A;
	}

	.detail_pc .infobg-cats {
		margin: 0.7rem 0;
		width: 5rem;
		margin-bottom: 1.31rem;
	}

	.detail_pc .infobg-cats img {
		margin-right: 0.59rem;
		margin-bottom: 0.8rem;
	}

	.detail_pc .redayuse {
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.26rem;
		color: #1A1A1A;
		font-weight: bold;
	}

	.detail_pc .redayuse .b {
		line-height: 0.61rem;
		font-size: 0.44rem;
		color: #1A1A1A;
	}

	.detail_pc .day-use-box img {
		margin: 0 0.33rem;
		margin-top: 0.8rem;
		width: 1.63rem;
		height: 1.63rem;
	}

	.detail_pc .day-use-box .day-use-num {
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		margin-top: 0.3rem;
	}

	.detail_pc .clist {
		width: 12.17rem;
		height: auto;
		margin: 0 auto;
		border: 0.04rem solid #000000;
		padding: 0.22rem;
		margin-top: 0.8rem;
	}

	.detail_pc .bg-title {
		height: 1.06rem;
		line-height: 1.06rem;
		font-size: 0.26rem;
		color: #1A1A1A;
		font-weight: bold;
	}

	.detail_pc .bg-title .b {
		line-height: 1.06rem;
		font-size: 0.44rem;
		color: #1A1A1A;
	}

	.detail_pc .goods_unit-title .goods_unit-name,
	.detail_pc .goods_unit-title .goods_unit-num {
		line-height: 0.41rem;
		font-size: 0.3rem;
	}

	.detail_pc .goods_unit-line .goods_unit-name div {
		height: auto;
		line-height: 0.41rem;
		font-size: 0.26rem;
	}

	.detail_pc .goods_unit-name {
		line-height: 0.41rem;
	}

	.detail_pc .exclusive {
		width: 100%;
		height: auto;
		line-height: 0.31rem;
		font-size: 0.22rem;
		color: #999999;
		margin-top: 0.15rem;
		padding: 0;
		background: none;
	}

	.detail_pc .gochecklist {
		width: 5.8rem;
		height: auto;
		line-height: 1.26rem;
		font-size: 0.3rem;
		margin: 0.59rem auto 0 auto;
		color: #1A1A1A;
		border: 0.04rem solid #C8D0D8;
	}

	.detail_pc .gochecklist img {
		width: 0.26rem;
		height: 0.26rem;
		margin: 0.52rem 0;
	}

	.detail_pc .populationlist {
		margin-top: 1.19rem;
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.detail_pc .population-line {
		width: 7.39rem;
		height: auto;
	}

	.detail_pc .population-title {
		height: 0.59rem;
		line-height: 0.59rem;
		font-size: 0.37rem;
		color: #1A1A1A;
	}

	.detail_pc .population-title img {
		width: 0.59rem;
		height: 0.59rem;
		margin: 0;
		margin-right: 0.15rem;
	}

	.detail_pc .population-txt {
		margin-top: 0.3rem;
		margin-bottom: 1.19rem;
		line-height: 0.52rem;
		font-size: 0.3rem;
		color: #1A1A1A;
	}

	.prodetail-imgs3 {
		width: 100%;
		height: 9.52rem;
		display: flex;
		position: relative;
	}

	.prodetail-imgs3 .img31 {
		width: 13.67rem;
		height: 8.33rem;
		z-index: 2;
	}

	.prodetail-imgs3 .img32 {
		width: 7.39rem;
		height: 5.35rem;
		background: #D5DDE6;
		position: absolute;
		top: 4.17rem;
		left: 11.06rem;
		z-index: 0;
	}

	.detail_pc .yanjiu {
		width: 100%;
		margin: 0;
		padding: 0;
		margin-top: 1.89rem;
	}

	.detail_pc .yan-title img {
		width: 3.7rem;
		height: 2.67rem;
		top: -0.57rem;
	}

	.detail_pc .yan-list {
		width: 100%;
		height: auto;
		margin-top: 1.19rem;
		display: flex;
		justify-content: left;
		flex-wrap: wrap;
		padding-bottom: 1.8rem;
	}

	.detail_pc .yan-line {
		width: 5.78rem;
		height: auto;
		margin-top: 0;
		margin-bottom: 0.57rem;
		margin-right: 0.55rem;
		padding: 0.44rem;
		position: relative;
	}

	.detail_pc .yan-line1 {
		width: 5.78rem;
		height: auto;
		margin-top: 0;
		margin-bottom: 0.57rem;
		margin-right: 0;
		padding: 0.44rem;
		position: relative;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
	}

	.detail_pc .yan-line-title {
		/* height: 0.41rem; */
		height: auto;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #1A1A1A;
	}

	.detail_pc .yan-line-txt {
		line-height: 0.37rem;
		font-size: 0.26rem;
		color: #666666;
		margin: 0;
		margin-top: 0.26rem;
		margin-bottom: 0.72rem;
	}

	.detail_pc .yan-detail {
		width: auto;
		height: 0.31rem;
		line-height: 0.31rem;
		font-size: 0.22rem;
		margin-top: 0.41rem;
		justify-content: left;
		cursor: pointer;
		position: absolute;
		bottom: 0.44rem;
		right: 0.44rem;
	}

	.detail_pc .yan-detail img {
		margin: 0.055rem 0.2rem;
	}

	.pro-bottom-list-l .change-one-btns .change-one-last img{
		margin: 0 0 0 20px;
	}
	@media  screen and (max-width:1200px) {

		.pro-bottom-list-bmsg{
			margin: 16px 0 0 0;
		}
	}
	@media  screen and (max-width:999px){
		.maintanc{
			background: #FFFFFF;
			padding-bottom: 20px;
		}
		.goods_unit-maintantxt{
			padding: 0;
			padding-top: 0!important;
		}
	}
		.pro-amount-th{
			border-bottom: 2px solid #1A1A1A;
		}
	@media screen and (min-width:1921px) {
		.detail_pc .en-name{
			font-size: 72px!important;
		}
		.detail_pc .zn-name{
			font-size: 40px!important;
		}
		.detail_pc .prodetail-txt{
			font-size: 1vw!important;
		}
		.detail_pc .pro-dayprice{
			width: auto;
			font-size:1vw;
		}
		.detail_pc .detail-cat .cat-img{
			width: 2.2vw!important;
			height: 2.2vw!important;
		}
		.cat-img img{
			width: 2.2vw!important;
			height: auto!important;
		}
		.detail_pc .detail-cat .cat-name{
			line-height: 2.2vw!important;
			margin-top: 0!important;
			font-size: 1vw!important;
		}
		.detail_pc .detail-cat{
			/* margin-bottom: 2.2vw!important; */
		}
		.detail_pc .goods_label div{
			font-size: 1vw!important;
			font-size: 24px!important;
		}
		.detail_pc .food_origin .food_origin-title{
			font-size: 1.5vw!important;
			font-size: 32px!important;
		}
		.detail_pc .food_origin .food_origin-txt{
			font-size: 1vw!important;
			font-size: 24px!important;
		}
		.detail_pc .detail-pro-title .pro-title1{
			font-size: 64px!important;
			line-height: 1!important;
		}
		.pro-function-title{
			font-size: 1.5vw;
			font-size: 32px!important;
		}
		.pro-function-status{
			font-size: 1vw;
			font-size: 20px!important;
		}
		.pro-function-msg{
			font-size: 1vw;
			font-size: 20px!important;
		}
		.pro-info-list-title{
			font-size: 1.5vw;
			font-size: 32px!important;
		}
		.pro-info-list-title:before{
			top: 0.9vw;
			top: 20px;
		}
		.pro-info-list-msg{
			font-size: 1vw;
			font-size: 24px!important;
			margin-left: 12px;
		}
		.pro-made-title{
			font-size: 64px;
		}
		.pro-made-txt{
			font-size: 1vw;
			font-size: 24px!important;
		}
		.pro-made-msg{
			font-size: 0.8vw;
			font-size: 18px!important;
		}
		.pro-made-l{
			width: 48.6vw;
			padding: 0 7.75vw 0 12.5vw;
		}
		.detail_pc .detail-pro-title .pro-title-msg{
			font-size: 1vw!important;
			font-size: 24px!important;
			margin-top: 0.55vw;
		}
		.pro-center-info-title{
			font-size: 32px;
		}
		.pro-center-info-msg{
			font-size: 24px;
		}
		.pro-center-info-icons{
			width: 17.25vw;
			max-width: 320px;
		}
		.pro-center-info-icons img{
			width: 3.35vw;
			height: 3.35vw;
			margin: 0 2.1vw 2.1vw 0;
			width: 50px;
			height: 50px;
			margin: 0 50px 50px 0;
		}
		.pro-center-info-lmsg{
			font-size: 1vw;
			font-size: 24px;
			margin: 2.2vw 0 0.55vw 0;
		}
		.pro-center-info-num{
			font-size: 1vw;
			font-size: 24px;
		}
		.pro-amount-title{
			font-size: 32px;
		}
		.pro-amount-th-l{
			font-size: 26px;
			margin: 1.1vw 0;
		}
		.pro-amount-th-r{
			font-size: 26px;
			margin: 1.1vw 0;
		}
		.pro-amount-td,.pro-amount-orther{
			font-size: 24px;
			padding: 0.55vw 0;
		}
		.pro-amount-more{
			margin: 0.55vw;
			font-size: 24px;
		}
		.showdetail{
			width: 16vw;
			font-size: 1vw;
			font-size: 24px;
			text-align: center;
			right: calc(280px - 8vw);
		}
		.pro-population-title div{
			font-size: 1vw;
			font-size: 24px;
			font-size: 18px;
		}
		.pro-population-txt{
			font-size: 0.8vw;
			font-size: 18px;
		}
		.pro-bottom-list-title{
			font-size: 64px;
		}
		.pro-bottom-list-msg{
			font-size: 1vw;
			font-size: 24px;
		}
		.pro-bottom-list-bname{
			/* font-size: 1vw; */
			/* line-height: 1.5; */
			/* height: 2.65vw; */
		}
		.pro-bottom-list-bmsg,.pro-bottom-list-bdetail{
			/* font-size: 0.8vw; */
		}
		/* .pro-bottom-list-bmsg{
			height: 10.24vw;
			line-height: 1.6;
			height: 10.2vw;
			line-height: 1.3vw;
			margin: 1.1vw 0 2.2vw 0;
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient:vertical;
			-webkit-line-clamp:8;
		} */
		.detail_pc .detail-pro-title{
			font-size: 64px;
		}
		.detail_pc .detail-pro-title{
			font-size: 3vw!important;
			font-size: 56px!important;
		}
		.detail_pc .detail-pro-msg{
			font-size: 1vw!important;
			font-size: 24px!important;
		}
	}



	.pro-function-listq1200{
		display: block;
	}
	.pro-function-listq1000{
		display: none;
	}
	@media screen and (min-width: 1000px) and (max-width: 1440px){
		.detail_pc .goods_label-box{
			width: 960px;
		}
		.tian-box{
			width: 960px!important;
		}
		.pro-function-list{
			width: 960px!important;
		}
		.pro-function-listq1200{
			display: none;
		}
		.pro-function-listq1000{
			display: block;
		}
		.pro-function-box{
			width: 29%;
		}
		.pro-last{
			left:  calc(25% - 25vw);
		}
		.pro-next{
			right: calc(25% - 25vw);
		}
		.pro-function-list .ivu-carousel-arrow.right{
			right: calc(25% - 25vw)!important;
		}
		.pro-function-list .ivu-carousel-arrow.left{
			left:  calc(25% - 25vw)!important;
		}
		.pro-info-list-box{
			width: 1000px;
		}
		.pro-info-list-l{
			width: 420px;
			height: 420px;
		}
		.pro-info-list-lbg{
			width: 360px;
			height: 360px;
			border-radius: 360px;
		}
		.pro-info-list-r{
			width: 460px;
			margin-right: 30px;
		}
		.pro-info-list-line{
			width: 460px;
		}
		.pro-center-info-box{
			width: 1000px;
		}
		.pro-center-info-maininfo{
			width: 500px;
		}
		.pro-center-info-img{
			width: 160px;
		}
		.pro-center-info-info{
			width: 280px;
		}
		.showdetail{
			right: calc(250px - 110px);
		}
		.pro-populationlist{
			width: 1000px;
		}
		.pro-bottom-list{
			width: calc(50vw + 500px);
			margin-left: calc(50vw - 500px);
		}
		.pro-bottom-list .noyincang{
			z-index: 0!important;
			opacity:1!important;
		}
		.pro-function-status{
			font-size: 16px;
		}
		.pro-function-msg{
			font-size: 16px;
		}
	}
	.main-left-p p{
		text-align: left!important;
	}

	.ingredients_info {
		display: flex;
		justify-content: space-between;
	}

	.ingredient_group_en {
  margin-left: 10px;
}

	.ingredient_item_one {
		flex: 1;
	}

	.ingredient_item_two {
		width: 2rem;
		text-align: right;
		margin-right: 1rem;
	}

	.ingredient_item_three {
		width: 1rem;
		text-align: right;
	}
	.ingredients_info_single {
		border-bottom: 1px solid #1A1A1A;
		padding: 0.2rem 0;
	}

	.ingredients_info_single:first-child {
		padding-top: 0;
	}

	.ingredients_info_single:last-child {
		border: none;
		padding-bottom: 0;
	}
</style>
