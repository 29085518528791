<template>
	<div ref="qusmain" class="qus main-page" v-if="qus">
		<div class="page-bg"></div>
		<div class="step-mb show_mb">
			<!-- <div class="step-mb-name">{{step==1?'基本信息':step==2?'您的需求':step==3?'生活模式':'其他'}}</div> -->
			<Progress :percent="100*qus.current_num/qus.question_num" stroke-color="#D5DDE6" stroke-width="7" hide-info />
		</div>
		<div class="lastpc show_pc cursor" v-if="qus.current_num!=1" @click="getlast"><img src="../../public/imgs/lastone.png" alt="">上一题</div>
		<div class="stepper show_pc">
			<div class="step">
				<div :class="step>=1?'circle':'noget'">
					<div></div>
				</div>
				<div class="name" v-if="step==1">基本信息 </div>
			</div>
			<div :class="step>=2?'tline':'nline'">
				<div class="linel" v-bind:style="{width: lpec+'%'}"></div>
			</div>
			<div class="step">
				<div :class="step>=2?'circle':'noget'">
					<div></div>
				</div>
				<div class="name" v-if="step==2">您的需求</div>
			</div>
			<div :class="step>=3?'tline':'nline'">
				<div :class="step==2?'linel':'linen'" v-bind:style="{width: lpec+'%'}"></div>
			</div>
			<div class="step">
				<div :class="step>=3?'circle':'noget'">
					<div></div>
				</div>
				<div class="name" v-if="step==3">生活模式</div>
			</div>
			<div :class="step>=4?'tline':'nline'">
				<div :class="step<3?'linen':'linel'" v-bind:style="{width: lpec+'%'}"></div>
			</div>
			<div class="step">
				<div :class="qus.is_end==1?'circle':'noget'">
					<div></div>
				</div>
				<div class="name" v-if="step==4">其他</div>
			</div>
		</div>
		<Radio v-if="type==1" :rdata="quss" @getback="getbackinfo"></Radio>
		<Checkbox v-if="type==3||type==2" :rdata="quss" @getback="getbackinfo"></Checkbox>
		<Special v-if="type==5" :rdata="quss" @getback="getbackinfo"></Special>
		<Pack v-if="type==4" :rdata="quss" @getback="getbackinfo"></Pack>
		<!-- <img class="leftimg" v-if="type==4" src="../../public/imgs/left.png" alt="">
		<img class="rightimg" v-if="type==4" src="../../public/imgs/right.png" alt="">
		<img class="radiol" v-if="type==1" src="../../public/imgs/radiol.png" alt="">
		<img class="radior" v-if="type==1" src="../../public/imgs/radior.png" alt="">
		<img class="checkl" v-if="type==3||type==2" src="../../public/imgs/checkl.png" alt="">
		<img class="checkr" v-if="type==3||type==2" src="../../public/imgs/checkr.png" alt="">
		<img class="speciall" v-if="type==5" src="../../public/imgs/sl.png" alt="">
		<img class="specialr" v-if="type==5" src="../../public/imgs/sr.png" alt=""> -->

		<alertmsg v-if="showalert" :rdata="alertdata" @getback="getbackalert"></alertmsg>

		<!-- <Modal v-model="modal1" title="离开评估？" @on-ok="ok" @on-cancel="cancel" okText='确定返回' cancelText='继续评估'>
			<p>我们强烈推荐你一次性完成本次评估，获得更完整的体验</p>
		</Modal> -->

		<Modal v-model="modal1" width="423.9" :styles="{top: '30%'}" @on-visible-change="changex">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>离开评估？</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>我们强烈推荐你一次性完成本次评估，获得更完整的体验</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="ok">确定返回</div>
				<div class="modalok modalbtn cursor" @click="cancel">继续评估</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
		<Modal v-model="chuqu" width="423.9" :styles="{top: '30%'}" :closable="false" :mask-closable="false">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>问卷失效</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>当前问卷已失效，请重新评估!</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="golist">返回报告列表</div>
				<div class="modalok modalbtn cursor" @click="requs">重新评估</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>

		<div class="lodding-box" v-if="showlodding">
			<div class="loadingmain" id="loading">
				<div class="loadEffect">
					<span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var changeq = '';
	// //console.log(this.mainshowalert)
	import {md5} from '../util/md5.js'
	import {
		Modal
	} from 'view-design';
	// import Vue from "vue";
	import Radio from '../components/radio.vue'
	import Pack from '../components/pack.vue'
	import Checkbox from '../components/checkbox.vue'
	import Special from '../components/special.vue'
	import alertmsg from '../components/alertmsg.vue'
	import Util from '../util/util.js'
	export default {
		name: 'questionnaire',
		components: {
			Radio,
			Checkbox,
			Special,
			Pack,
			Modal,
			alertmsg
		},
		data: function() {
			return {
				name: '',
				sho:false,
				serial: 1,
				subject: 1,
				qus: {},
				showalert: false,
				modal1: false,
				quss:'',
				alertdata: {
					title: '离开评估？',
					txt: '我们强烈推荐你一次性完成本次评估，获得更完整的体验',
					notxt: '继续评估',
					oktxt: '确定返回',
					showno: 1
				},
				showlodding: false,
				progress: 0.6,
				step: 3,
				lpec: 1,
				showPop: true,
				hasnew:false,
				rpec: 1,
				indexdata:'',
				url: '',
				type: 0,
				info: '',
				way: '',
				showlog: false,
				nowid:'',
				beforeUnloadTime: '',
				gapTime: '',
				tid:'',
				biaoshi:'',
				chuqu:false
			};
		},
		watch: {
			$route() {
				let qusmain = this.$refs.qusmain
				qusmain.scrollTo(0, 0)
			},
			'showalert': {
				handler: function() {
					//console.log('132')
				}
			}
		},
		// beforeCreate() {
		// 		window.addEventListener('popstate', this.test(), false)
		// },
		// beforeMount() {
		// 		window.addEventListener('popstate', this.test(), false)
		// },
		created() {
			//console.log('created')
			window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
			window.addEventListener('unload', e => this.unloadHandler(e))
			// history.pushState(null, null, document.URL);
			// window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
			const IS_B_SERVE = sessionStorage.getItem('is_b_serve')
			this.IS_B_SERVE = IS_B_SERVE
			console.log(IS_B_SERVE)
		},
		beforeDestroy() {
			//console.log('beforeDestroy')
			// window.addEventListener('popstate', this.test(), false)

		},
		destroyed() {
			window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
			window.removeEventListener('unload', e => this.unloadHandler(e))
			//console.log('destroyed')
			// window.addEventListener('popstate', this.test(), false)
			// window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
		},
		mounted() {
			var zhi =localStorage.getItem('zhi')?localStorage.getItem('zhi'):'';
			var ztimestamp = localStorage.getItem('ztimestamp')?localStorage.getItem('ztimestamp'):'';
			const shanghaishow = sessionStorage.getItem('shanghaishow')
			const isbserve = sessionStorage.getItem('is_b_serve')
			var that = this
			changeq = setInterval(function(){
					var q = localStorage.getItem('q1')?localStorage.getItem('q1'):false
					if(q=='true'){
						clearInterval(changeq)
						that.modal1 = true
						localStorage.setItem('q1',false)
					}else{
						// //console.log('noq')
					}
			}, 1000)
			// location.reload()
			// this.$router.go(0)
			// this.gettime()
			if(zhi && !shanghaishow && !isbserve){
				var tzhi = zhi+ztimestamp
				// //console.log(md5(tzhi).toUpperCase())
				that.biaoshi = md5(tzhi).toUpperCase()
			}else{
				that.biaoshi = sessionStorage.getItem('newBiaoshi')
				console.log(this.biaoshi)
			}
				this.tid = localStorage.getItem('tid')?localStorage.getItem('tid'):''
				if(!this.$route.params.way&&window.name == ""){
					//console.log('跳转首页')
					this.$router.replace({
						path: 'home'
					})
				}
				if(window.name == ""){
					//console.log("首次被加载");
					window.name = "isRefresh";
				}else if(window.name == "isRefresh"){
					//console.log("页面被刷新");
				}
				// if (window.history && window.history.pushState) {
				// 	//console.log('向历史记录中插入了当前页')
				// 	// 向历史记录中插入了当前页
				// 	// history.pushState(null, null, document.URL);
				// 	// window.addEventListener('popstate', this.test, false);
				// 	// //console.log(window.history)
				// }else{
				// 	window.removeEventListener('popstate', this.test, false);
				// }
				this.listenPage()
				var info = JSON.parse(localStorage.getItem('info'))
				//console.log(info)
				if (!info) {
					info = {token:''}
					// this.$Message.info('请先登录');
					// this.$router.push({
					// 	path: 'login',
					// 	query: {
					// 		way: 'questionnaire'
					// 	}
					// })
				}
				var way = this.$route.query.way ? this.$route.query.way : ''
				that.way = way
				//console.log(this.$route.params)
				//console.log(this.$route.query.way)
				that.info = info
				that.url = document.URL
				console.log(that)
				const IS_B_SERVE = that.IS_B_SERVE

				if (way == '') {
					console.log(IS_B_SERVE )
					that.$ajax.post(IS_B_SERVE ? that.API.api.apiurl.bgetQuestion : that.API.api.apiurl.ngetquestion, {
							is_up: '0',
							record_id: '0',
							member_id: that.info.member_id,
							giver_id: '',
							is_address: '0',
							sid: that.biaoshi,
							question: [],
							test_level:that.IS_B_SERVE ? '' : 27
						},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								var qus = data.data
								//console.log(qus)
								that.type = qus.question_type
								that.qus = qus
								that.quss = qus
								that.step = qus.type
								that.progress = qus.current_num / qus.question_num
								var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
								var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
								that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
					localStorage.setItem('tid',qus.id)
							}else if (data.code == 2001) {
									that.$Message.info('用户信息已过期，请重新登录')
									localStorage.removeItem('info')
									that.$router.replace('login')
								} else {
								that.$Message.info('错误：' + data.message)
								that.$router.replace({
									path:'home'
								})
							}
						})
						.catch(error => {
							that.$Message.info(error)
						})
				} else if (way == 'new') {
					that.$ajax.post(IS_B_SERVE ? that.API.api.apiurl.bgetQuestion : that.API.api.apiurl.nagainQuestion, {
							record_id: that.$route.query.id,
							sid: that.biaoshi,
							test_level:that.IS_B_SERVE ? '' : 27
						},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								var qus = data.data
								//console.log(qus)
								that.type = qus.question_type
								that.qus = qus
								localStorage.setItem('hasnewqus', that.$route.query.id)
								that.hasnew==true
								that.quss = qus
					localStorage.setItem('tid',qus.id)
								that.step = qus.type
								that.progress = qus.current_num / qus.question_num
								var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
								var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
								that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
							} else {
								that.$Message.info('错误：' + data.message)
								that.$router.replace({
									path:'home'
								})
							}
						console.log('当前问题',this.qus)
						})
						.catch(error => {
							that.$Message.info(error)
						})
					// this.$ajax.post(this.API.api.apiurl.nisQuestionRecord, {
					// 		// member_id: that.info.member_id,
					// 		sid: that.biaoshi,
					// 	},{
					// 		headers:{
					// 			'Authorization':'Bearer '+that.info.token,
					// 		}
					// 	})
					// 	.then(res => {
					// 		////console.log(res)
					// 		var data = res.data
					// 		if (data.code == 0) {

					// 			//console.log(data)
					// 			var dt = data.data
					// 			var hid = localStorage.getItem('hasnewqus')?localStorage.getItem('hasnewqus'):''
					// 			if(dt.is_record==1&&hid==dt.record_id){
					// 				that.$ajax.post(that.API.api.apiurl.ncontinueQuestion, {
					// 						record_id: dt.record_id,
					// 						sid: that.biaoshi,
					// 					},{
					// 						headers:{
					// 							'Authorization':'Bearer '+that.info.token,
					// 						}
					// 					})
					// 					.then(res => {
					// 						////console.log(res)
					// 						var data = res.data
					// 						if (data.code == 0) {
					// 							var qus = data.data
					// 							//console.log(qus)
					// 							localStorage.setItem('tid',qus.id)
					// 							that.type = qus.question_type
					// 							that.qus = qus
					// 							that.quss = qus
					// 							that.step = qus.type
					// 							that.progress = qus.current_num / qus.question_num
					// 							var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
					// 							var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
					// 							that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
					// 						} else {
					// 							that.$Message.info('错误：' + data.message)
					// 						}
					// 					})
					// 					.catch(error => {
					// 						that.$Message.info(error)
					// 					})
					// 			}else{
					// 				//问卷-重新问卷
					// 				that.$ajax.post(that.API.api.apiurl.nagainQuestion, {
					// 						record_id: dt.record_id,
					// 						sid: that.biaoshi,
					// 					},{
					// 						headers:{
					// 							'Authorization':'Bearer '+that.info.token,
					// 						}
					// 					})
					// 					.then(res => {
					// 						////console.log(res)
					// 						var data = res.data
					// 						if (data.code == 0) {
					// 							var qus = data.data
					// 							//console.log(qus)
					// 							that.type = qus.question_type
					// 							that.qus = qus
					// 							localStorage.setItem('hasnewqus', that.$route.query.id)
					// 							that.hasnew==true
					// 							that.quss = qus
					// 				localStorage.setItem('tid',qus.id)
					// 							that.step = qus.type
					// 							that.progress = qus.current_num / qus.question_num
					// 							var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
					// 							var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
					// 							that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
					// 						} else {
					// 							that.$Message.info('错误：' + data.message)
					// 						}
					// 					})
					// 					.catch(error => {
					// 						that.$Message.info(error)
					// 					})
					// 			}
					// 		} else {
					// 			that.$Message.info('错误：' + data.message)
					// 		}
					// 	})
					// 	.catch(error => {
					// 		//console.log(error)
					// 	})

				} else if (way == 'old') {
					//问卷-继续问卷请求接口
					this.$ajax.post(IS_B_SERVE ? that.API.api.apiurl.bgetQuestion :this.API.api.apiurl.ncontinueQuestion, {
							record_id: that.$route.query.id,
							sid: that.biaoshi,
							test_level:that.IS_B_SERVE ? '' : 27
						},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								var qus = data.data
								//console.log(qus)
								that.type = qus.question_type
								that.qus = qus
								that.quss = qus
								that.step = qus.type
								that.progress = qus.current_num / qus.question_num
								var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
								var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
								that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn,

								localStorage.setItem('tid',qus.id)
							} else {
								that.$Message.info('错误：' + data.message)
								that.$router.replace({
									path:'home'
								})
							}
						})
						.catch(error => {
							that.$Message.info(error)
						})
				}

		},
		methods: {
			changex(e){
					clearInterval(changeq)
					var that = this
				if(e==false){
					//console.log('取消')
					this.modal1 = false
					changeq = setInterval(function(){
							var q = localStorage.getItem('q1')?localStorage.getItem('q1'):false
							if(q=='true'){
								clearInterval(changeq)
								that.modal1 = true
								localStorage.setItem('q1',false)
							}else{
								// //console.log('noq')
							}
					}, 1000)
				}
			},
			golist(){
				this.$router.replace({
					path:'my-report'
				})
			},
			requs(){
				console.log('1523','------')
				this.chuqu = false
				var that = this
				that.$ajax.post(that.IS_B_SERVE ? that.API.api.apiurl.bgetQuestion : that.API.api.apiurl.ngetquestion, {
						is_up: '0',
						record_id: '0',
						member_id: that.info.member_id,
						giver_id: '',
						is_address: '0',
						sid: that.biaoshi,
						question: [],
						test_level:that.IS_B_SERVE ? '' : 27
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							var qus = data.data
							//console.log(qus)
							that.type = qus.question_type
							that.qus = qus
							that.quss = qus
							that.step = qus.type
							that.progress = qus.current_num / qus.question_num
							var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
							var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
							that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
				localStorage.setItem('tid',qus.id)
						}else if (data.code == 2001) {
							that.$Message.info('用户信息已过期，请重新登录')
							localStorage.removeItem('info')
							that.$router.replace('login')
						}  else {
							that.$Message.info('错误：' + data.message)
						}
					})
					.catch(error => {
						that.$Message.info(error)
					})
			},
			unloadHandler(){
				//console.log('关闭',e)
				this.gapTime=new Date().getTime()-this.beforeUnloadTime;
				//判断是窗口关闭还是刷新
				if(this.gapTime<=5){//浏览器关闭
					localStorage.setItem('guan',0)
				}else{
					//console.log('??')
				}
			},
			beforeunloadFn() {
				//console.log('刷新或关闭',e)
				this.beforeUnloadTime=new Date().getTime();
			},
			getPop() {
				this.showPop = true;
				// window.history.pushState(null, null, "#");
			},
			test() {
				//console.log(history)
				this.modal1 = true
				// sessionStorage.clear();
				// window.history.back();
				// history.pushState(null, null, document.URL);
			},
			ok() {
				// history.pushState(null, null, document.URL);
				//console.log('确定')
				clearInterval(changeq)
				localStorage.setItem('q2',true)
				this.$router.push({
					path: 'home'
				})
			},
			cancel() {
				localStorage.setItem('q2',false)
				var that = this
				//console.log('取消')
				this.modal1 = false
				this.sho = false
				changeq = setInterval(function(){
						var q = localStorage.getItem('q1')?localStorage.getItem('q1'):false
						if(q=='true'){
							clearInterval(changeq)
							that.modal1 = true
							localStorage.setItem('q1',false)
						}else{
							// //console.log('noq')
						}
				}, 1000)
			},
			listenPage() {
				// var that = this
				// window.onbeforeunload = function(e) {
				// 	//console.log('shuaxin',e)
				// 	e = e || window.event;
				// 	if (e) {
				// 		e.returnValue = '关闭提示';
				// 	}
				// 	return that.$router.push({path: 'report'});
				// };
			},
			//请求下一题
			getnext(e) {
				var that = this
				var tid = localStorage.getItem('tid')?localStorage.getItem('tid'):''
				that.quss = ''
				this.golast=false
				setTimeout(function(){
					that.golast=true
				},500)
				const shanghaishow = sessionStorage.getItem('shanghaishow')
				//console.log(e,this.qus)
				if(that.qus.title=='请问怎么称呼你？'){
					sessionStorage.setItem('judaiName',e)
				}
				if(tid!=''){
					if(this.qus.id==tid){
						//console.log('noerr')
						if (this.qus.is_end == 1) {
							this.$ajax.post(that.IS_B_SERVE ? that.API.api.apiurl.bsavequestion : this.API.api.apiurl.nsavequestion, {
									record_id: that.qus.record_id,
									question_id: that.qus.id,
									answer_ids: that.qus.question_type == 4 ? [] : e,
									answer: that.qus.question_type == 4 ? e : '',
									sid: that.biaoshi,
									shanghaishow: shanghaishow ? 1 : '',
									IS_B_SERVE :that.IS_B_SERVE ? 1 : ''
								},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
								.then(res => {
									console.log(res,'----------------')
									var data = res.data
									if (data.code == 0) {
										if(data.data.url){
											sessionStorage.setItem('qrCode',data.data.url)
											sessionStorage.setItem('count_id',data.data.count_id)
										}
										that.getCountInfoNew(data.data.count_id)
										if(shanghaishow) {
											sessionStorage.setItem('showAI',1)
										}


									} else {
										that.$Message.info('错误：' + data.message)
									}
								})
								.catch(error => {
									that.$Message.info(error)
								})
						} else {
							// if(that.nowid==''){
								this.$ajax.post(that.IS_B_SERVE ? that.API.api.apiurl.bgetQuestion :this.API.api.apiurl.ngetquestion, {
									is_up: '0',
									record_id: that.qus.record_id,
									member_id: that.info.member_id,
									giver_id: '',
									sid: that.biaoshi,
									is_address: '0',
									question: {
										question_id: that.qus.id,
										answer_ids: that.qus.question_type == 4 ? [] : e,
										answer: that.qus.question_type == 4 ? e : ''
									},
									test_level:that.IS_B_SERVE ? '' : 27
								},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
								.then(res => {
									////console.log(res)
									var data = res.data
									if (data.code == 0) {
										that.nowid = 1
										setTimeout(function(){
											that.nowid=''
										},500)
										var qus = data.data
										//console.log(qus)

										localStorage.setItem('tid',qus.id)
									that.tid = qus.id
										that.type = qus.question_type
										that.qus = qus
										that.quss = qus
										that.step = qus.type
										that.progress = qus.current_num / qus.question_num
										var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
										var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
										that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
										window.scrollTo(0, 0)
									}else if (data.code == 135||data.code == 136) {
										that.chuqu = true
									}else if (data.code == 2001) {
										that.$Message.info('用户信息已过期，请重新登录')
										localStorage.removeItem('info')
										that.$router.replace('login')
									} else {
										that.$Message.info('错误：' + data.message)
									}
								})
								.catch(error => {
									that.$Message.info(error)
								})
							// }else{
							// 	that.$Message.info('请不要点的太快！')
							// }
						}
					}else{
						//console.log('err!')
						that.$Message.info('请不要打开多个窗口进行问券')
					}
				}else{
					//console.log('noerr2')
					if (this.qus.is_end == 1) {
						this.$ajax.post(that.IS_B_SERVE ? that.API.api.apiurl.bsavequestion : this.API.api.apiurl.nsavequestion, {
								record_id: that.qus.record_id,
								question_id: that.qus.id,
								answer_ids: that.qus.question_type == 4 ? [] : e,
								answer: that.qus.question_type == 4 ? e : '',
								sid: that.biaoshi,
								IS_B_SERVE :that.IS_B_SERVE ? 1 : ''
							},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
							.then(res => {
								////console.log(res)
								var data = res.data
								if (data.code == 0) {
									that.getCountInfoNew(data.data.count_id)
								} else {
									that.$Message.info('错误：' + data.message)
								}
							})
							.catch(error => {
								that.$Message.info(error)
							})
					} else {
						// if(that.nowid==''){
							this.$ajax.post(that.IS_B_SERVE ? that.API.api.apiurl.bgetQuestion : this.API.api.apiurl.ngetquestion, {
								is_up: '0',
								record_id: that.qus.record_id,
								member_id: that.info.member_id,
								giver_id: '',
									sid: that.biaoshi,
								is_address: '0',
								question: {
									question_id: that.qus.id,
									answer_ids: that.qus.question_type == 4 ? [] : e,
									answer: that.qus.question_type == 4 ? e : ''
								},
								test_level:that.IS_B_SERVE ? '' : 27
							},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
							.then(res => {
								////console.log(res)
								var data = res.data
								if (data.code == 0) {
									that.nowid = 1
									setTimeout(function(){
										that.nowid=''
									},500)
									var qus = data.data
									//console.log(qus)

									localStorage.setItem('tid',qus.id)
									that.tid = qus.id
									that.type = qus.question_type
									that.qus = qus
									that.quss = qus
									that.step = qus.type
									that.progress = qus.current_num / qus.question_num
									var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
									var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
									that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
									window.scrollTo(0, 0)
								} else if (data.code == 135||data.code == 136) {
										that.chuqu = true
									}else if (data.code == 2001) {
									that.$Message.info('用户信息已过期，请重新登录')
									localStorage.removeItem('info')
									that.$router.replace('login')
								} else {
									that.$Message.info('错误：' + data.message)
								}
							})
							.catch(error => {
								that.$Message.info(error)
							})
						// }else{
						// 	that.$Message.info('请不要点的太快！')
						// }
					}
				}

			},
			getCountInfoNew(e) {
				var that = this
				// this.showlodding=true
				//console.log(e)
				const shanghaishow = sessionStorage.getItem('shanghaishow')
				const IS_B_SERVE = sessionStorage.getItem('is_b_serve')
				localStorage.setItem('tid','')
				// setTimeout(function(){
				that.showlodding = false
				// window.location.href='https://store.nutri-packs.cn/report?id='+e
				var info = localStorage.getItem('info')
				if(shanghaishow){
					that.$router.replace({
						path: 'qrcode',
					})
					return
				}
				if(info && !IS_B_SERVE){
					that.$router.replace({
						path: 'report',
						query: {
							id: e
						}
					})
				}else{
					if(Util.checkIsLogin() && IS_B_SERVE){
                        this.$router.push({
                            path: 'qrcode',
                            query:{
                                way:'questionnaire'
                            }
                        })
                        return;
                    }else if(!Util.checkIsLogin() && IS_B_SERVE){
						this.$router.push({
                            path: 'login',
                            query:{
                                way:'questionnaire'
                            }
                        })
                        return;
					}
					that.$router.replace({
						path: 'get-report',
						query: {
							id: e
						}
					})
				}
				// },1000)
				// this.$ajax.post(this.API.api.apiurl.getCountInfoNew,{
				// 	count_id:e,
				// })
				// .then(res=> {
				// 	////console.log(res)
				// 	var data = res.data
				// 	if(data.code==200){
				// 		var qus = data.data
				// 		//console.log(qus)
				// 	}else{
				// 		//console.log('错误：'+data.msg)
				// 	}
				// })
				// .catch(error=>{
				// 	//console.log(error)
				// })
			},
			getbackinfo(e) {
				//console.log(e)
				if (e.way == 0) {
					this.getlast()
				} else {
					//console.log(e.value)
					this.getnext(e.value)
				}
			},
			getbackalert(e) {
				//console.log(e)
				if (e.value == '1') {
					this.showalert = false
					this.$router.push({
						path: 'home'
					})
				} else {
					this.showalert = false
				}
			},
			//请求上一题
			getlast() {
				var that = this
				var tid = localStorage.getItem('tid')?localStorage.getItem('tid'):''
				if(this.golast==false){
					//console.log('别点太快')
				}else{
					//console.log('132')
					that.quss = ''

					if(tid!=''){
						if(this.qus.id==tid){
							//console.log('noerr')
							this.$ajax.post(this.API.api.apiurl.ngetquestion, {
								is_up: '1',
								record_id: that.qus.record_id,
								member_id: that.info.member_id,
								giver_id: '',
									sid: that.biaoshi,
								is_address: '0',
								question: [],
								test_level:that.IS_B_SERVE ? '' : 27
							},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
							.then(res => {
								////console.log(res)
								var data = res.data
								if (data.code == 0) {
									var qus = data.data
									//console.log(qus)
									that.type = qus.question_type
									that.qus = qus
									that.quss = qus
									that.step = qus.type
									localStorage.setItem('tid',qus.id)
									that.tid = qus.id
									that.progress = qus.current_num / qus.question_num
									var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
									var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
									that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
										window.scrollTo(0, 0)
								} else if (data.code == 135||data.code == 136) {
										that.chuqu = true
									}else if (data.code == 2001) {
									that.$Message.info('用户信息已过期，请重新登录')
									localStorage.removeItem('info')
									that.$router.replace('login')
								} else {
									that.$Message.info('错误：' + data.message)
								}
							})
							.catch(error => {
								that.$Message.info(error)
							})
						}else{
							//console.log('err!')
							that.$Message.info('请不要打开多个窗口进行问券')
						}
					}else{
						//console.log('noerr2')
						this.$ajax.post(this.API.api.apiurl.ngetquestion, {
							is_up: '1',
							record_id: that.qus.record_id,
							member_id: that.info.member_id,
							giver_id: '',
									sid: that.biaoshi,
							is_address: '0',
							question: [],
							test_level:that.IS_B_SERVE ? '' : 27
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								var qus = data.data
								//console.log(qus)
								that.type = qus.question_type
								that.qus = qus
								that.quss = qus
								that.step = qus.type
								localStorage.setItem('tid',qus.id)
									that.tid = qus.id
								that.progress = qus.current_num / qus.question_num
								var qn = qus.type == 1 ? 6 : qus.type == 2 ? 8 : qus.question_num
								var qc = qus.type == 2 ? qus.current_num - 6 : qus.current_num
								that.lpec = qus.type == 2 ? 100 * qc / qn : 100 * qus.current_num / qn
									window.scrollTo(0, 0)
							} else if (data.code == 135||data.code == 136) {
										that.chuqu = true
									} else if (data.code == 2001) {
									that.$Message.info('用户信息已过期，请重新登录')
									localStorage.removeItem('info')
									that.$router.replace('login')
								}else {
								that.$Message.info('错误：' + data.message)
							}
						})
						.catch(error => {
							that.$Message.info(error)
						})
					}

				}
			}
		}
	}
</script>

<style>
	.ivu-btn-primary{
		background-color: #C25E4D;
		border-color: #C25E4D;
	}
	.ivu-btn-text:hover{
		color: #C25E4D;
	}
	.ivu-btn-primary:hover{
		background-color: #C25E4D;
		border-color: #C25E4D;
	}
	.qus {
		width: 100%;
		height: 100%;
		overflow: hidden;
		margin: 0;
		padding: 0;
		padding-bottom: 1.77rem;
		position: relative;
	}

	.stepper {
		width: 12rem;
		height: 1rem;
		display: flex;
		margin: 10px auto;
		justify-content: space-between;
	}

	.stepper .step {
		width: 0.5rem;
		height: 1rem;
		z-index: 3;
		/* overflow: hidden; */
	}

	.step .circle {
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 0.5rem;
		border: 0.1rem solid rgba(194, 94, 77, 0.2);
		overflow: hidden;
	}

	.step .noget {
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 0.5rem;
		border: 0.1rem solid rgba(216, 216, 216, 0.2);
		overflow: hidden;
	}

	.noget div {
		width: 100%;
		height: 100%;
		border-radius: 0.5rem;
		background: rgb(216, 216, 216);
		z-index: 15;
	}

	.circle div {
		width: 100%;
		height: 100%;
		border-radius: 0.5rem;
		background: #C25E4D;
		z-index: 15;
	}

	.step .name {
		width: 1.5rem;
		height: 0.4rem;
		line-height: 0.4rem;
		text-align: center;
		font-size: 0.22rem;
		margin-left: -0.5rem;
		margin-top: 0.15rem;
		color: #C25E4D;
	}

	.stepper .tline {
		width: 4rem;
		display: flex;
		height: 0.1rem;
		margin-top: 0.2rem;
		background: #C25E4D;
	}

	.stepper .nline {
		width: 4rem;
		display: flex;
		height: 0.1rem;
		margin-top: 0.2rem;
		background: rgb(216, 216, 216);
	}

	.stepper .linel {
		height: 0.1rem;
		background: #C25E4D;
	}

	.nline .linen {
		background: rgb(216, 216, 216);
	}

	.chose {
		z-index: 2;
	}

	.btns {
		width: 100%;
		max-width: 18.5rem;
		height: 1.11rem;
		display: flex;
		justify-content: center;
		margin: 1rem auto;
		/* position: absolute;
		bottom: 1.19rem; */
		/* padding: 0 1.44rem; */

		/* max-width: 26rem; */
	}

	.btnsp {
		/* position: absolute; */
		bottom: 1.19rem;
		z-index: 1;
	}

	.btns div {
		width: 4.22rem;
		height: 1.11rem;
		line-height: 1.11rem;
		border-radius: 1.11rem;
		background: #C25E4D;
		/* letter-spacing: 0.2rem; */
		text-align: center;
		font-size: 0.3rem;
		color: #fff;
		cursor: pointer;
		margin: 0 0.5rem;
		text-indent: 0.1rem;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.24);
	}
	.btns button {
		width: 4.22rem;
		height: 1.11rem;
		line-height: 1.11rem;
		border-radius: 1.11rem;
		background: #C25E4D;
		/* letter-spacing: 0.2rem; */
		text-align: center;
		font-size: 0.3rem;
		color: #fff;
		cursor: pointer;
		margin: 0 0.5rem;
		text-indent: 0.1rem;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.24);
	}

	.btns .last {
		/* letter-spacing: 0.125rem; */
		background: #FFFFFF;
		color: #C25E4D;
		box-shadow: 0rem 0.09rem 0.18rem 0rem rgba(0, 0, 0, 0.1)
	}

	.btns .next {
		/* text-indent: 0.23rem; */
	}

	.leftimg {
		width: 3.96rem;
		height: 3.09rem;
		position: fixed;
		z-index: -1;
		left: 0;
		bottom: 1.19rem;
	}

	.rightimg {
		width: 3rem;
		height: 4.06rem;
		position: fixed;
		z-index: -1;
		top: 2.81rem;
		right: 0;
	}

	.radiol {
		width: 2.41rem;
		height: 4.22rem;
		position: fixed;
		z-index: -1;
		left: 0;
		bottom: 2.67rem;
	}

	.radior {
		width: 5.22rem;
		height: 4.65rem;
		position: fixed;
		z-index: -1;
		top: 2.57rem;
		right: -2.11rem;
	}

	.checkl {
		width: 2.85rem;
		height: 4.43rem;
		position: fixed;
		z-index: -1;
		bottom: 0;
		left: 0;
	}

	.checkr {
		width: 2.04rem;
		height: 4.22rem;
		position: fixed;
		z-index: -1;
		top: 1.8rem;
		right: 0;
	}

	.speciall {
		width: 4.93rem;
		height: 4.24rem;
		position: fixed;
		z-index: -1;
		bottom: 0;
		left: -2.2rem;
	}

	.specialr {
		width: 1.8rem;
		height: 4.22rem;
		position: fixed;
		z-index: -1;
		top: 4.07rem;
		right: 0;
	}

	.icon img {
		width: 100%;
		height: 100%;
	}

	.step-mb{
		width: calc( 100% - 0.38rem );
		margin: 0 auto;
	}
	.step-mb-name{
		width: 100%;
		height: 0.37rem;
		text-align: right;
	}

	.lastpc{
		position: absolute;
		width: 1.7rem;
		display: flex;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #666666;
		top: 0.2rem;
	}

	@media screen and (max-width:650px) {
		.stepper {
			width: 8rem;
		}

		.stepper .tline {
			width: 3rem;
		}

		.stepper .nline {
			width: 3rem;
		}

		.btns {
			width: 94%;
		}

		.btns div {
			width: 3.44rem;
			height: 1rem;
			line-height: 1rem;
			margin: 0.28rem 0.1rem;
			font-size: 0.37rem;
		}

		.btns {
			height: 1.58rem;
			position: fixed;
			bottom: 0.29rem;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
		}
	}

	input {
		-webkit-appearance: none !important;
	}

	.topicon {
		width: 100%;
		height: 1.39rem;
		margin: 0.59rem 0 0.3rem 0
	}

	.topicon img {
		width: auto;
		height: 1.39rem;
		margin: 0 auto;
	}

	@media screen and (max-width:1000px) {
		.radiobox,.specialboxs{
			padding: 0 0.19rem;
		}
		.noicon{
			height: 0.79rem;
			margin: 0;
		}
		.checkboxs .chose,.packboxs .chose{
			width: calc( 100% - 0.38rem );
			margin: 0 auto;
		}
		.thepack{
			width: 100%;
		}
		.thepack .packinput{
			width: 100%;
			padding: 0.1rem 0;
			height: 1.31rem;
			line-height: 1.31rem;
			border-radius: 2rem;
			font-size: 0.37rem;
		}
		.chose .chosebox,.checkboxs .chose .chosebox,.more .chosebox{
			width: 100%;
			max-width: 100%;
			height: auto;
			line-height: 1.11rem;
			border-radius: 2rem;
			font-size: 0.37rem;
			padding: 0.1rem 0;
		}
		.checkboxs .chosebox .txt,.chosebox .centertxt{
			width: 100%;
			height: 1.11rem;
			line-height: 1.11rem;
			font-size: 0.37rem;
			text-align: center;
			position: relative;
		}
		.firstti{
			justify-content: flex-end;
		}
		.btns .nums{
			display: none;
		}
		.specialboxs .chose{
			width: 100%;
		}
		.specialboxs .chose .chosebox{
			padding: 0.47rem 0.47rem;
			margin: 0 0.6rem 1.2rem 0.6rem;
		}
		.specialboxs .chosebox .icon{
			margin: 0;
			display: flex;
		}
		.specialboxs .chosebox .txt{
			margin-top: 0.3rem;
			font-size: 0.37rem;
		}
		.checkboxs .chosebox .txt{
			margin: 0;
		}
	}
</style>
