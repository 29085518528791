<template>
	<div class="personal main-page">
		<div class="page-bg"></div>
		<div class="personal-title">个人中心</div>
		<div class="personinfo-box">
			<div class="personinfo-title fontsize">头像</div>
			<div class="personinfo-main personinfo-head-box">
				<img class="head-img" :src="headimgurl?headimgurl:publicimg" alt="">
				<!-- <div class="personinfo-head">
					<div class="upload-box">
						<img src="../../public/imgs/upload.png" alt="">
						<div class="upload-txt">更换头像</div>
					</div>
					<div class="upload-msg">支持图片类型：png, jpg</div>
				</div> -->
			</div>
			<div class="personinfo-title fontsize">用户名</div>
			<div class="personinfo-main personinfo-name">
				<input type="text" maxlength="16" v-model="name">
			</div>
			<div class="personinfo-title fontsize">手机号码</div>
			<div class="personinfo-main personinfo-name">
				<input type="text" disabled="disabled" v-model="phone">
			</div>
			<div class="personbtns" v-if="name!=info.nickName">
				<div class="nook" v-if="name!=info.nickName" @click="nosave">取消</div>
				<div class="save public-btn" v-if="name!=info.nickName" @click="saveinfo">保存</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'personal',
		components:{
		},
		data:()=>({
			info:'',
			name:'',
			publicimg:require('../../public/imgs/tou.png'),
			phone:'',
			headimgurl:'',
		}),
		mounted() {
			var info = JSON.parse(localStorage.getItem('info'))
			// //console.log(info)
			if (!info) {
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login?way=personal'
				})
			}
			this.phone = info.phone
			this.name = info.nickName
			this.headimgurl = info.headimgurl=='/static/admin/images/default_headimg.jpg'?'':info.headimgurl
			var that = this
			that.info = info
		},
		methods:{
			goqus(){
				this.$router.push({ path: 'questionnaire'})
			},
			gocart(){
				this.$router.push({ path: 'cart'})
			},
			saveinfo(){
				var name = this.name
				//console.log(name)
				var that = this
				//批量删除橘袋产品
				this.$ajax.post(this.API.api.apiurl.changeUserInfo, {
						member_id: that.info.member_id,
						nickname: name
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							//console.log(data)
							that.$Message.success('修改昵称成功');
							var inf = that.info
							inf.nickName = name
							that.info = inf
							localStorage.setItem('info',JSON.stringify(inf))
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			nosave(){
				this.name = this.info.nickName
			}
		}
	}
</script>

<style>
	.personal-title{
		width: 100%;
		height: 0.83rem;
		font-size: 0.59rem;
		line-height: 0.83rem;
		font-weight: bold;
		margin: 0.87rem 0 0.72rem 0;
		text-align: left;
	}
	.personinfo-box{
		width: 100%;
		height: auto;
		max-width: 18.5rem;
		/* background: #FFFFFF; */
		border-radius: 0.19rem;
		/* padding: 0.59rem; */
	}
	.personinfo-title{
		width: 100%;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		text-align: left;
		margin-bottom: 0.3rem;
	}
	.personinfo-main{
		width: 100%;
		height: auto;
		margin-bottom: 0.59rem;
		display: flex;
		justify-content: left;
	}
	.personinfo-main input{
		width: 6.59rem;
		height: 0.89rem;
		background: #F5F5F5;
		border-radius: 0.63rem;
		line-height: 0.89rem;
		font-size: 0.28rem;
		text-indent: 0.59rem;
		border: none;
		color: #666666;
	}
	.personinfo-head-box{
		width: 100%;
		height: 1.63rem;
		display: flex;
	}
	.personinfo-head-box .head-img{
		width: 1.63rem;
		height: 1.63rem;
		border-radius: 1.63rem;
	}
	.personinfo-head{
		width: auto;
		height: 1.63rem;
		margin-left: 0.3rem;
	}
	.upload-box{
		width: 2.52rem;
		height: 0.67rem;
		display: flex;
		justify-content: center;
		background: #FFFFFF;
		border-radius: 0.33rem;
		border: 0.02rem solid #CCCCCC;
		margin-top: 0.2rem;
		cursor: pointer;
	}
	.upload-box img{
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.185rem 0.11rem;
	}
	.upload-txt{
		width: auto;
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		margin: 0.075rem 0;
		color: #1A1A1A;
	}
	.upload-msg{
		width: auto;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #999999;
		margin-top: 0.15rem;
	}
	.personbtns{
		width: 100%;
		height: 1.11rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 3rem;
	}
	.personbtns div{
		cursor: pointer;
	}
	.nook{
		width: auto;
		height: 1.11rem;
		line-height: 1.11rem;
		margin: 0 0.6rem;
		font-size: 0.3rem;
		color: #999999;
	}
	
	@media screen and (max-width:1000px) {
		.personal-title{
			padding: 0 0.19rem;
			margin: 0.56rem 0;
		}
		.personinfo-box{
			padding: 0.59rem 0.19rem;
		}
		.personinfo-title{
			font-size: 0.41rem;
		}
	}
</style>
