<template>
	<div class="navs" v-if="!checkIsInApp()" :class="isindex?'isindexnav':''">
		<div class="h5logo show_mb">
			<!-- <img @click="gogindex" class="cursor nav-img" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/logo.png" alt=""> -->
			<img @click="gogindex" class="cursor nav-img" src="../../public/imgs/index/logo.png" alt="">
		</div>

		<div class="gocart gocarth5 show_mb" @click="gocart">
			<img src="../../public/imgs/cart.png" alt="">
			<div class="clength" v-if="clength>0">{{clength}}</div>
		</div>
		<div class="menu-list">
			<!-- <Icon type="ios-menu" /> -->
			<img src="../../public/imgs/menu3.png" alt="" @click="showthisandmohu">
			<div class="topbg" v-if="showmenu" @click="delmohu"></div>
			<div class="m-list animate__animated animate__fadeInRight" style="animate-delay: 0s;animate-duration: 0.2s" :class="!info?'nologin':''" v-if="showmenu">
				<div class="list-top-btn">
					<div class="list-close-btn" id="listclose" @click="mohu">
						<img src="../../public/imgs/listclose.png" alt="">
					</div>
					<div class="list-close-btn" id="listmore" style="display:none;" @click="mohu">
						<img class="back-tab-list" src="../../public/imgs/more.png" alt="">
					</div>
					<div class="pagename" id="pagename"></div>
					<div class="list-login-btn" v-if="!info" @click="gologin">
						<div class="list-login-txt">登录</div>
						<div class="list-login-img">
							<img src="../../public/imgs/listhead.png" alt="">
						</div>
					</div>
				</div>

				<!-- <div class="m-tab" @click="gologin" v-if="!info">请先登录</div> -->
				<div class="" id="page1">
					<div class="m-tab" @click="gogindex"><div>首页</div></div>
					<div v-if="false" class="m-tab" @click="getpage2">
						<div>商品列表</div>
						<img src="../../public/imgs/more.png" alt="">
					</div>
					<div class="m-tab" @click="gocart"><div>购物车</div></div>
					<div class="m-tab" @click="goQueryPage" v-if="!isAlipay">
						<div>食物营养查询</div>
						<img src="../../public/imgs/more.png" alt="">
					</div>
					<!-- <div class="m-tab" @click="goreport"><div>我的报告</div></div> -->
					<!-- <div class="m-tab" @click="goxcx"><div>跳转小程序</div></div> -->
					<div class="m-tab" @click="getpage3">
						<div>关于我们</div>
						<img src="../../public/imgs/more.png" alt="">
					</div>
					<div class="m-tab" @click="getpage4">
						<div>个人中心</div>
						<img src="../../public/imgs/more.png" alt="">
					</div>
					<div class="m-tab" @click="logout" v-if="info && !isAlipay"><div>退出登录</div></div>

					<!-- <button class="go-dingzhi" @click="gogetreport">开始 AI 定制</button> -->
					<button class="go-dingzhi" @click="goxcx" v-if="false">开始 AI 定制</button>


				</div>
				<div class="" id="page2" style="display:none;">
					<div class="m-tab" @click="pz"><div>补剂列表</div></div>
					<div class="m-tab" @click="meal"><div>应急营养包</div></div>
				</div>
				<div class="" id="page3"  style="display:none;">
					<div class="m-tab" @click="aboutus">公司介绍</div>
					<div class="m-tab" @click="jkyjzx" v-if="!isAlipay">HPV橘丝带</div>
					<div class="m-tab" @click="fulfillment" v-if="!isAlipay">行业解决方案</div>
					<div class="m-tab" @click="blog" v-if="!isAlipay&&false">橘袋动态</div>
				</div>
				<div class="" id="page4"  style="display:none;">
					<div class="m-tab" @click="goaddress"><div>地址管理</div></div>
					<div class="m-tab" @click="goorders"><div>订单管理</div></div>
					<div class="m-tab" @click="goreport" v-if="isAlipay"><div>我的报告</div></div>
					<div class="m-tab" @click="gopersonal"><div>个人中心</div></div>
				</div>
			</div>
		</div>
		<div class="navs-list" :class="isindex?'isindex':''">
			<div v-if="false" class="nav-tab cursor" style="position: relative;" @click="toshowcplist">
				产品列表

				<div class="showcplist" v-if="showcplist">
					<div class="cplist-line">
						<a @click="pz">补剂列表</a>
					</div>
					<div class="cplist-line">
						<a @click="meal">应急营养包</a>
					</div>
				</div>
			</div>
			<div class="nav-tab cursor" style="position: relative;" @click="goaboutus">
				关于我们

				<div class="showcplist" v-if="showabout">
					<div class="cplist-line">
						<a href="/publichtml/about.html">公司介绍</a>
					</div>
					<div class="cplist-line">
						<a href="/publichtml/hpv.html">HPV 橘丝带</a>
					</div>
				</div>
			</div>
			<div class="nav-tab cursor" style="position: relative;" @click="golisttab">
				品质保障

				<div class="showcplist" v-if="showlisttab">
					<div class="cplist-line">
						<a target="view_window" href="https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100000609&idx=1&sn=b535cb2ddf905f24858f5210adccb940&chksm=7d84cbcc4af342da7a2bcbd12503a97dc716dae5f47f681b7daa19e9b1e348fea4096f860b7c#rd">100%透明承诺</a>
					</div>
					<div class="cplist-line">
						<a target="view_window" href="https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007377&idx=1&sn=e25d8a0a26da362bc19ac35a590092a2&chksm=7d84d57c4af35c6a384ea4d29de9bb5439742736374c7537b9a3370db19b10c3fce57629c387#rd">效力保障</a>
					</div>
					<div class="cplist-line">
						<a target="view_window" href="https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007379&idx=1&sn=9794dc1e06a82abae9ee680fdbd09ff9&chksm=7d84d57e4af35c68e8d8d4d67ed132cc533a30f8e1940b094519da2909d7f7e46284b9ce836d#rd">专利和工艺</a>
					</div>
					<div class="cplist-line">
						<a target="view_window" href="https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007378&idx=1&sn=1e5486f679f0096f4acec15f6538188e&chksm=7d84d57f4af35c69eee04db176a1a89867057b029b5b7077bdac99ae3eec99e86bcd492410ec#rd">全链认证</a>
					</div>
					<div class="cplist-line">
						<a target="view_window" href="https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247507699&idx=1&sn=35a6c7b550e6e5d316f8a3aea2cfdf45&chksm=fd87165ecaf09f487fcfba5a0e23e6676594a24716f0eb19f367069d6835d13d1508705d0e00#rd">自动化分装</a>
					</div>
				</div>
			</div>
			<div class="nav-tab cursor nav-tabimgs">
				<!-- <img class="cursor nav-img" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/logo.png" alt="" @click="gogindex"> -->
				<img @click="gogindex" class="cursor nav-img" src="../../public/imgs/index/logo.png" alt="">
				<!-- <img @click="gogindex" class="cursor nav-img" src="../../public/imgs/logo0.png" alt=""> -->
			</div>
			<a v-if="false" class="nav-tab cursor nav-center" @click="blog">品牌动态</a>
			<a class="nav-tab cursor" @click="fulfillment">行业解决方案</a>
			<button class="nav-tab goqus-btn cursor" @click="gogetreport" v-if="!showAI&&false">
				开始 AI 定制
			</button>
			<!-- <div class="goindexnav nav-tab cursor" @click="gogindex">
				首页
			</div> -->
			<div class="info-navs">
				<div class="gocart" @click="gocart" v-if="clength>=1&&info">
					<img src="../../public/imgs/cart.png" alt="">
					<div class="clength" v-if="clength>=1">{{clength}}</div>
				</div>
				<div class="gocart" @click="gocart" v-if="clength<1">
					<img src="../../public/imgs/cart.png" alt="">
				</div>
				<div class="mine" @click="showperson=!showperson">
					<img :src="info.headimgurl?info.headimgurl:publicimg" alt="">
					<div class="person-box" :class="!info?'nologin':''" v-if="showperson">
						<a class="goperson cursor" @click="goreport">
							<img class="img1" src="../../public/imgs/navs/myreport_1.png" alt="">
							<img class="img2" src="../../public/imgs/navs/myreport_2.png" alt="">
							<div>我的报告</div>
						</a>
						<a class="goperson cursor" @click="goorders">
							<img class="img1" src="../../public/imgs/navs/order_1.png" alt="">
							<img class="img2" src="../../public/imgs/navs/order_2.png" alt="">
							<div>订单管理</div>
						</a>
						<a class="goperson cursor" @click="goaddress">
							<img class="img1" src="../../public/imgs/navs/address_1.png" alt="">
							<img class="img2" src="../../public/imgs/navs/address_2.png" alt="">
							<div>地址管理</div>
						</a>
						<a class="goperson cursor" @click="gopersonal">
							<img class="img1" src="../../public/imgs/navs/center_1.png" alt="">
							<img class="img2" src="../../public/imgs/navs/center_2.png" alt="">
							<div>个人中心</div>
						</a>
						<a class="goperson cursor" @click="logout" v-if="info">
							<img class="img1" src="../../public/imgs/navs/logout_1.png" alt="">
							<img class="img2" src="../../public/imgs/navs/logout_2.png" alt="">
							<div>退出登录</div>
						</a>
						<!-- <div class="goperson" @click="gologin" v-if="!info">
							<img class="img1" src="../../public/imgs/navs/login_1.png" alt="">
							<img class="img2" src="../../public/imgs/navs/login_2.png" alt="">
							<div>请先登录</div>
						</div> -->
					</div>
				</div>
				<div class="topbg" v-if="aboutlist" @click="aboutlist=false"></div>
				<div class="topbg" v-if="showcplist" @click="showcplist=false"></div>
				<div class="topbg" v-if="showperson" @click="showperson=false"></div>
				<div class="topbg" v-if="showabout" @click="showabout=false"></div>
				<div class="topbg" v-if="showlisttab" @click="showlisttab=false"></div>
			</div>
		</div>
		<!-- 导航条 -->
	</div>
</template>

<script>
  import checkIsInApp from '../util/app.js'
	var changel = '';
	var changeinfo = '';
	export default {
		name: 'navs',
		components: {},
		data: () => ({
			showabout:false,
			showlisttab:false,
			showmenu: false,
			showcplist:false,
			clength: 0,
			publicimg: require('../../public/imgs/tou.png'),
			showperson: false,
			login: true,
			info: '',
			aboutlist:false,
			showmohu:false,
			pagenum:1,
			isindex:false,
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		mounted() {
			var that = this
			// var changepath = setInterval(function(){
			// 	if(that.$route.path=='/index'){
			// 		that.isindex=true
			// 	}else{
			// 		that.isindex=false
			// 	}
			// }, 500)
			// //console.log(changepath.length?'':'')
			var info = JSON.parse(localStorage.getItem('info'))
			this.showAI = sessionStorage.getItem('showAI')
			console.log(sessionStorage.getItem('showAI')=== '0')
			if (info) {
				var iurl = info.headimgurl
				var t = iurl.substr(0, 5)
				if (t != 'https') {
					info.headimgurl = ''
				}
			}else{
				localStorage.setItem('clength',-1)
			}
			that.info = info ? info : ''

			changel = setInterval(function() {
				if (!JSON.parse(localStorage.getItem('info'))) {
					that.login = false
					info = ''
				}
				var l = localStorage.getItem('clength') ? localStorage.getItem('clength') : -1
				if (l == -1) {
					that.login = false
					info = ''
				} else {
					info = JSON.parse(localStorage.getItem('info'))
				}
				that.clength = l
			}, 2000)
			// changel = setInterval(function(){
			if (!JSON.parse(localStorage.getItem('info'))) {
				//console.log('用户未登录')
				changel = setInterval(function() {
					var info = JSON.parse(localStorage.getItem('info'))
					if (info) {
						var iurl = info.headimgurl
						var t = iurl.substr(0, 5)
						if (t != 'https') {
							info.headimgurl = ''
						}
					}
					that.info = info ? info : ''
					var l = localStorage.getItem('clength') ? localStorage.getItem('clength') : -1
					if (!info) {
						that.clength = 0
					} else {
						that.clength = l
					}
				}, 2000)
			} else {
				//console.log('用户已登录')
				changel = setInterval(function() {
					var info = JSON.parse(localStorage.getItem('info'))
					if (info) {
						var iurl = info.headimgurl
						var t = iurl.substr(0, 5)
						if (t != 'https') {
							info.headimgurl = ''
						}
					}
					that.info = info ? info : ''
					var l = localStorage.getItem('clength') ? localStorage.getItem('clength') : -1
					if (!info) {
						that.clength = 0
					} else {
						that.clength = l
					}
				}, 2000)
				var lg = localStorage.getItem('clength') ? localStorage.getItem('clength') : -1
				if (lg == '-1') {
					that.$ajax.post(that.API.api.apiurl.getCartList, {
							member_id: that.info.member_id,
						}, {
							headers: {
								'Authorization': 'Bearer ' + that.info.token,
							}
						})
						.then(res => {
							//console.log(res, 'res')
							var data = res.data.data
							var clist = []
							if (res.data.code == 0) {
								var cartlist = data
								for (var i = 0; i < cartlist.length; i++) {
									if (cartlist[i].source == 1 || cartlist[i].source == 2) {
										clist.push(cartlist[i])
									}
								}
								var ll = 0
								clist.forEach((item) => {
									ll += item.num
								})
								//console.log(ll)
								localStorage.setItem('clength', ll)
								that.clength = ll

								//缓存
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							// clearInterval(changel)
							console.log(error)
						})
				} else {
					//console.log('不刷新')
				}
			}
			// }, 2000)
		},
		methods: {
            checkIsInApp(){
                return checkIsInApp();
            },
			goxcx(){
				if(navigator.userAgent.indexOf('AliApp') > -1) {
						console.log('支付宝小程序')
						window.location.href = '/home'
					} else {
						const sourceId = localStorage.getItem('sourceId')
						window.location.href= sourceId ? "https://api.xiaojudai.cn/api/s/27?source_id=" + sourceId : "https://api.xiaojudai.cn/api/s/2"
					}
			},
				getpage1(){
					this.pagenum=1
					document.getElementById("pagename").innerHTML='';
					document.getElementById("page1").style="display:block"
					document.getElementById("page2").style="display:none"
					document.getElementById("page3").style="display:none"
					document.getElementById("page4").style="display:none"
					document.getElementById("listclose").style="display:block"
					document.getElementById("listmore").style="display:none"
				},
				getpage2(){
					document.getElementById("pagename").innerHTML='商品列表'
					this.pagenum=2
					document.getElementById("page1").style="display:none"
					document.getElementById("page2").style="display:block"
					document.getElementById("page3").style="display:none"
					document.getElementById("page4").style="display:none"
					document.getElementById("listclose").style="display:none"
					document.getElementById("listmore").style="display:block"
				},
				getpage3(){
					document.getElementById("pagename").innerHTML='关于我们'
					this.pagenum=3
					document.getElementById("page1").style="display:none"
					document.getElementById("page2").style="display:none"
					document.getElementById("page3").style="display:block"
					document.getElementById("page4").style="display:none"
					document.getElementById("listclose").style="display:none"
					document.getElementById("listmore").style="display:block"
				},
				getpage4(){
					document.getElementById("pagename").innerHTML='个人中心'
					this.pagenum=4
					document.getElementById("page1").style="display:none"
					document.getElementById("page2").style="display:none"
					document.getElementById("page3").style="display:none"
					document.getElementById("page4").style="display:block"
					document.getElementById("listclose").style="display:none"
					document.getElementById("listmore").style="display:block"
				},
			// mohu(){
			// 	if(this.pagenum==1){
			// 		this.delmohu()
			// 	}else{
			// 		this.pagenum = 1
			// 	}
			// },
			// delmohu(){
			// 	this.pagenum = 1
			// 	this.showmenu = false;
			// 	this.$emit('getback',{value:'',way:'0'})
			// },
				mohu(){
					if(this.pagenum==1){
						this.delmohu()
					}else{
						this.pagenum = 1
						this.getpage1()
					}
				},
				delmohu(){
					this.pagenum = 1
					this.showmenu = false;
						// this.getpage1()
					this.$emit('getback',{value:'',way:'0'})
				},
			showthisandmohu(){
				this.showmenu = !this.showmenu;
				this.$emit('getback',{value:'',way:'1'})
			},
			showaboutlist(){
				this.aboutlist = !this.aboutlist
			},
			toshowcplist(){
				this.showcplist = !this.showcplist
				//console.log(this.showcplist)
			},
			goaboutus(){
				this.showabout = !this.showabout
			},
			golisttab(){
				this.showlisttab = !this.showlisttab
			},
			changelength() {
				clearInterval(changel)
			},
			getlength() {
				//console.log(this)
				var that = this
				changeinfo = setInterval(function() {
					that.$ajax.post(that.API.api.apiurl.getCartList, {
							member_id: that.info.member_id,
						}, {
							headers: {
								'Authorization': 'Bearer ' + that.info.token,
							}
						})
						.then(res => {
							////console.log(res)
							var data = res.data.data
							var clist = []
							if (res.data.code == 0) {
								var cartlist = data
								for (var i = 0; i < cartlist.length; i++) {
									if (cartlist[i].source == 1 || cartlist[i].source == 2) {
										clist.push(cartlist[i])
									}
								}
								var ll = 0
								clist.forEach((item) => {
									ll += item.num
								})
								//console.log(ll)
								localStorage.setItem('clength', ll)
								that.clength = ll
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}, 2000)
			},
			aboutus() {
				this.delmohu()
				window.location.href = '/publichtml/about.html'
			},
			jkyjzx() {
				this.delmohu()
				window.location.href = '/publichtml/hpv.html'
			},
			meal() {
				this.delmohu()
				this.showmenu = false
				this.$router.push({
					path: 'care-packs'
				})
			},
			pz() {
				this.delmohu()
				this.showmenu = false
				this.$router.push({
					path: 'product-list'
				})
			},
			blog() {
				this.delmohu()
				window.location.href = '/publichtml/blog.html'
			},
			fulfillment() {
				this.delmohu()
				window.location.href = '/publichtml/fulfillment.html'
			},
			goindex() {
				this.delmohu()
				if (this.API.api.apiurl.way == 'admin') {
					this.showmenu = false
					window.location.href = 'https://store.nutri-packs.cn'
				}
			},
			pinzhi() {
				this.delmohu()
				if (this.API.api.apiurl.way == 'admin') {
					this.showmenu = false
					window.location.href = 'https://store.nutri-packs.cn/quality'
				}
			},
			taocan() {
				this.delmohu()
				if (this.API.api.apiurl.way == 'admin') {
					this.showmenu = false
					window.location.href = 'https://yishawanju.taobao.com/?spm=2013.1.0.0.9e344649zXrFOV'
				}
			},
			gohome() {
				this.delmohu()
				this.showmenu = false
				// window.location.href='https://nutri-packs.cn/index'

				localStorage.setItem('goq', 1)
				this.$router.push({
					path: 'home'
				})
			},
			goqus() {
				this.delmohu()
				this.showmenu = false
				// this.$router.push({ path: 'questionnaire'})
			},
			gocart() {
				this.delmohu()
				this.showmenu = false
				this.$router.push({
					path: 'cart'
				})
			},
			goQueryPage() {
				this.delmohu()
				this.showmenu = false
				this.$router.push({
					path: 'food-index'
				})
			},

			gopersonal() {
				this.delmohu()
				this.showmenu = false
				this.$router.push({
					path: 'personal'
				})
			},
			goaddress() {
				this.delmohu()
				this.showmenu = false
				this.$router.push({
					path: 'address-list'
				})
			},
			goorders() {
				this.delmohu()
				this.showmenu = false
				this.$router.push({
					path: 'order-list'
				})
			},
			goreport() {
				this.delmohu()
				this.showmenu = false
				this.$router.push({
					path: 'my-report'
				})
			},
			gogetreport() {
				this.delmohu()
				this.showmenu = false
				localStorage.setItem('goq', 1)
				this.$router.push({
					path: 'home'
				})
			},
			gologin() {
				this.delmohu()
				// var that = this
				// changeinfo = setInterval(function() {
				// 	var info = JSON.parse(localStorage.getItem('info'))
				// 	that.info = info ? info : ''
				// 	if (info) {
				// 		clearInterval(changeinfo)
				// 	}
				// }, 2000)
				this.$router.push({
					path: 'login'
				})
			},
			gogindex() {
				this.delmohu()
				this.$router.push({
					path: '/'
				})
			},
			logout() {
				this.delmohu()
				var timestamp = (new Date()).getTime();
				localStorage.setItem('ztimestamp', timestamp)
				var that = this
				changeinfo = setInterval(function() {
					var info = JSON.parse(localStorage.getItem('info'))
					that.info = info ? info : ''
					if (info) {
						clearInterval(changeinfo)
					}
				}, 2000)
				this.info = ''
				this.showmenu = false
				// localStorage.clear();

				localStorage.removeItem("info")
				this.$router.push({
					path: 'login'
				})
			}
		}
	}
</script>

<style>
	.showcplist{
		width: 2.76rem;
		height: auto;
		position: absolute;
		background: #FFFFFF;
		top: 80px;
		border-radius: 0.19rem;
		z-index: 9;
		left: 50%;
		margin-left: -1.53rem;
		overflow: hidden;
		padding: 0.2rem 0;
	box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
	}
	.showcplist .cplist-line{
		height: 0.8rem;
		line-height: 0.8rem;
		/* padding: 0 0.33rem; */
		color: #1A1A1A;
	}
	.cplist-line a{
		display: inline-block;
		color: #1A1A1A;
		width: 100%;
		font-size: 14px;
		text-align: left;
		text-indent: 20%;
		text-decoration: blink;
	}
	.cplist-line div{
		display: inline-block;
		color: #1A1A1A;
		width: 100%;
		font-size: 14px;
		text-align: left;
		text-indent: 20%;
		text-decoration: blink;
	}
	.cplist-line div:hover{
		color: #FFFFFF;
		background: #C25E4D;
	}
	.cplist-line a:hover{
		color: #FFFFFF;
		background: #C25E4D;
	}
	.navs {
		width: 100%;
		width: 100vw;
		height: 80px;
		background: #FFFFFF;
		/* box-shadow: 0rem 0rem 0.15rem 0rem rgba(0, 0, 0, 0.1); */
		/* padding: 0.3rem 0.59rem; */
		display: flex;
		justify-content: space-between;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.menu-list {
		display: none;
		width: 1.02rem;
		height: 1.02rem;
		position: relative;
	}

	.menu-list img {
		width: 0.82rem;
		height: 0.82rem;
		margin: 0.1rem 0.35rem;
	}

	.menu-list .m-list {
		position: absolute;
		bottom: -15rem;
		right: 0;
		/* left: -0.5rem; */
		width: 4rem;
		height: 15rem;
		padding: 0.39rem;
		background: #FFFFFF;
		text-align: left;
		line-height: 1rem;
		font-size: 0.37rem;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		z-index: 9;
	}

	.m-tab {
		cursor: pointer;
		margin-top: 0.04rem;
	}

	.logo {
		width: 4.17rem;
		height: 0.89rem;
		margin: 0 0;
	}

	.logo img {
		width: 100%;
		height: 100%;
	}

	.navs-list {
		width: 100%;
		max-width: 18.5rem;
		height: 80px;
		display: flex;
		justify-content: space-between;
		/* justify-content: space-around; */
		margin: 0 auto;
		position: relative;
		/* margin-right: calc(50vw - 500px + 1.25rem); */
	}

	@media screen and (min-width: 1000px) and (max-width: 1439px) {

		.navs-list {
			max-width: calc(90vw - 3rem);
		}

		.goindexnav{
			position: absolute;
			left: -1.5rem;
		}
	}
	@media screen and (min-width: 1921px){
		.goindexnav{
			position: absolute;
			left: -1.8rem;
		}
		.navs-list {
			max-width: calc(1920px - 5rem);
			max-width: 1200px;
			justify-content: space-around;
			justify-content: space-between;
		}
	}
	@media screen and (max-width:2561px) {
		.goindexnav{
			position: absolute;
			left: -1.8rem;
		}
	}
	@media screen and (min-width: 1440px) and (max-width: 1920px) {

		.navs-list {
			max-width: calc(1440px - 5rem);
			max-width: 1200px;
			justify-content: space-around;
			justify-content: space-between;
		}
		.goindexnav{
			position: absolute;
			left: -1.8rem;
		}

	}
	.isindexnav{
		height: 7vw;
	}
	.isindex{
		max-width: 80vw;
		height: 7vw;
		margin: 0 0 0 7vw;
	}

	.navs-list .nav-tab {
		width: 1.83rem;
		width: 2rem;
		height: 80px;
		line-height: 80px;
		text-align: left;
		color: #1A1A1A;
		font-size: 14px;
		cursor: pointer;
	}
	.isindex .nav-tab{
		font-size: 1.1vw;
		line-height: 7vw;
		width: 7.2vw;
	}

	.navs-list .nav-tabimgs {
		width: 188px;
		height: 80px;
		/* margin-top: 20px; */
	}

	.navs-list .nav-center {
		text-align: center;
	}

	.navs-list .goqus-btn {
		background: #C25E4D;
		border: none;
		color: #FFFFFF;
		width: 1.83rem;
		width: 2rem;
		height: 0.65rem;
		height: auto;
		line-height: 0.65rem;
		/* border: 0.02rem solid #333333; */
		margin: calc(40px - 0.325rem) 0;
		cursor: pointer;
		border-radius: 0.65rem;
		text-align: center;
	}
	.isindex .goqus-btn{
		width: 8.37vw;
		height: 2.65vw;
		border-radius: 2.65vw;
		margin: calc( 3.5vw - 1.325vw ) 0;
	}

	.navs-list .goqus-btn:hover {
		background: #d5dde6;
		border: none;
		color: #1A1A1A;
	}

	.info-navs {
		width: 2rem;
		height: 0.59rem;
		display: flex;
		position: absolute;
		right: -2.5rem;
		top: calc(40px - 0.295rem);
	}
	.isindexnav .info-navs{
		width: 9.5vw;
		top: calc( 3.5vw - 0.295rem );
		right: -11vw;
	}

	.info-navs .mine,
	.info-navs .gocart {
		width: 0.59rem;
		height: 0.59rem;
		position: relative;
	}

	.gocarth5 {
		width: 0.59rem;
		height: 0.59rem;
		position: absolute;
		right: 1.45rem;
		/* top: 0.4rem; */
		width: 27px;
		height: 27px;
		right: 75px;
	}

	.gocart .clength {
		width: auto;
		min-width: 16px;
		/* height: 15px; */
		line-height: 16px;
		font-size: 12px;
		color: #FFFFFF;
		background: #C25E4D;
		text-align: center;
		border-radius: 16px;
		position: absolute;
		padding: 0 3px;
		top: -6px;
		right: -6px;
	}

	.info-navs .mine {
		margin-left: 0.59rem;
	}

	.mine img,
	.gocart img {
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	.mine img {
		border-radius: 0.59rem;
		overflow: hidden;
	}

	.person-box {
		width: 3.26rem;
		height: 5rem;
		overflow: hidden;
		background: #FFFFFF;
		border-radius: 0.19rem;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		position: absolute;
		right: 0;
		bottom: -5.74rem;
		z-index: 9;
	}
	.person-box a{
		display: flex!important;
	}

	.goperson {
		width: 100%;
		height: 1rem;
		display: flex;
		padding: 0 0.33rem;
		/* line-height: 1rem; */
		font-size: 0.3rem;
		color: #1A1A1A;
	}

	.goperson img {
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.35rem 0.07rem 0.35rem 0;
		border-radius: 0;
	}

	.goperson .img2 {
		display: none;
	}

	.person-box .goperson:hover {
		background: #C25E4D;
		color: #FFFFFF;
	}

	.person-box .goperson:hover>.img1 {
		display: none;
	}

	.person-box .goperson:hover>.img2 {
		display: block;
	}

	.person-box .goperson div {
		height: 1rem;
		line-height: 1rem;
		margin-left: 0.1rem;
	}

	.navs .nav-img {
		width: 188px;
		height: auto;
		/* margin: 0.31rem 0; */
		height: auto;
		margin: 20px auto;
	}
	.isindexnav .nav-img{
		width: 14.7vw;
		height: auto;
	}

	.topbg {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: none;
		z-index: 8;
	}

	.nologin {
		height: 4rem;
		bottom: -4.74rem;
	}
	@media screen and (min-width:1440px) and (max-width:1500px) {
		.navs-list .info-navs {
			width: auto;
			height: 0.59rem;
			display: flex;
			position: absolute;
			right: calc(50% - 50vw + 20px);
			top: calc(40px - 0.295rem);
		}
	}
	@media screen and (max-width:999px) {

		.navs-list,
		.info-navs {
			display: none;
		}

		.navs {
			box-shadow: 0rem 0rem 0.15rem 0rem rgba(0, 0, 0, 0.1);
			height: 1.35rem;
			height: 72px;
			align-items: center;
		}
		.gocart img{
			width: 25px!important;
			height: 25px!important;
			margin: 1px!important;
			margin-top: 1px!important;
		}

		.logo {
			margin: 0 auto;
		}

		.menu-list {
			display: block;
			position: absolute;
			right: 0.3rem;
			width: 32px;
			height: 32px;
			right: 15px;
		}

		.h5logo {
			width: 3.56rem;
			/* height: 0.8rem; */
		margin: calc(0.675rem - 17px) 0;
			/* position: absolute; */
			top: 0;
			left: 0.45rem;
			margin-left: 15px;
			/* margin-left: -1.53rem; */
		}

		.navs .nav-img {
			height: 35px;
			width: 188px;
			margin: 0;
		}

		.menu-list img {
		height: 0.6rem;
		width: 0.6rem;
		width: 32px;
		height: 33px;
		margin: 0;
		/* margin: 0.375rem 0.3rem; */
		}

		.menu-list .nologin {
			height: 14rem;
			bottom: -14rem;
		}
	}


	.erji{
		width: 150px;
		position: absolute;
		left: -150px;
		background: #FFFFFF;
		top: 0;
		text-align: left;
		padding: 10px 0.39rem;
		/* margin: 10px 0; */
		line-height: 40px;
		font-size: 15px;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		z-index: 9;
	}
	.second-tab{
		width: 100%;
	}
	.topbg{
		/* background: rgba(0,0,0,0.6); */
		-webkit-filter: blur(5px);
		-moz-filter: blur(5px);
		-ms-filter: blur(5px);
		filter: blur(5px);

	}
	.menu-list .m-list{
		position: fixed;
		width: 84vw;
		width: 100vw;
		height: 100vh;
		padding: 30px 0;
		right: 0;
		/* left: 0; */
		top: 0;
		background: #FFFFFF;
		color: #1A1A1A;
		/* max-width: 340px; */
	}
	.erji{
		width: 80vw;
		max-width: 340px;
		position: relative;
		left: -30px;
		box-shadow: none;
		/* padding: 10px 30px; */
		/* background: #EEEEEE; */
		font-weight: normal;
	}
	.list-top-btn{
		width:100%;
		width: calc(100% - 60px);
		margin: 0 auto;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin-bottom: 37px;
	}
	.list-close-btn{
		width: 30px;
		height: 30px;
		margin-left: -5px;
	}
	.list-login-btn{
		width: auto;
		display: flex;
	}
	.list-login-btn .list-login-img{
		width: 30px;
		height: 30px;
	}
	.list-close-btn img,.list-login-img img{
		width: 100%;
		height: 100%;
		margin: 0;
		display: block;
	}
	.list-login-txt{
		width: auto;
		line-height: 30px;
		height: 30px;
		font-size: 12px;
		margin-right: 10px;
		font-weight: medium;
		display: block;
	}
	.go-dingzhi{
		width: 100%;
		width: calc(100% - 60px);
		display: block;
		/* height: 40px; */
		line-height: 40px;
		text-align: center;
		border-radius: 40px;
		font-size: 16px;
		color: #FFFFFF;
		background: #C25E4D;
		margin: 37px auto 24px auto;
	}
	.m-list .m-tab{
		width: calc(100% - 60px);
		margin: 0 auto;
		font-size: 16px;
		/* font-weight: bold; */
		line-height: 50px;
		margin-top: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.menu-list .m-tab img{
		width: 16px;
		height: 16px;
		margin: 0 0;
	}
	.list-close-btn .back-tab-list{
		/* width: 20px;
		height: 20px;
		margin: 5px 10px 5px 0px; */
		width: 25px;
		height: 25px;
		margin: 2.5px 6.5px 2.5px 1px;
		transform: rotate(180deg);
	}
	.pagename{
		line-height: 30px;
		font-size: 20px;
		font-weight: bold;
	}
</style>
