<template>
	<div class="specialboxs" v-if="qusdata">
		<!-- <div class="checkicon"></div> -->
		<div class="topicon" :class="qusdata.icon_url?'yesicon':'noicon'">
			<img v-if="qusdata.icon_url" :src="qusdata.icon_url" alt="">
		</div>
		<div class="title">{{title}}</div>
		<div class="msg">{{msg}}</div>
		<div class="tab"></div>
		<div class="chose">
			<div class="chosebox" :class="item.type?'thisone':''" v-for="(item,j) in qusdata.answer" :key="j" @click="getthisspecial(j,item)">
				<!-- <div class="icon"> -->
					<img :src="item.thumb_url" alt="">
				<!-- </div> -->
				<div class="txt">{{item.item}}</div>
			</div>
		</div>
		<div class="btns">
			<p class="nums">你已选择了<span style="color:#C25E4D ;">{{num}}项</span></p>
			<button class="last show_mb" @click="last">上一题</button>
			<button class="next" @click="ok">下一题</button>
		</div>
	</div>
</template>

<script>
  import checkIsInApp from '../util/app.js'
	export default{
		name:'special',
		props: {
			rdata: Object
		},
		watch:{
				rdata(n,o){ //n为新值,o为旧值;
					this.o = o
					//console.log(n,o)
					var data = n
					var answer = data.answer
					for(var i= 0;i<answer.length;i++){
						answer[i].type=false
					}
					data.answer = answer
					this.qusdata = data;
				}
		},
		data:()=>({
			title:'请选择你当下最关注的健康目标 ',
			msg:'建议选择1-3项',
			icon:'',
			o:'',
			num:0,
			qusdata:'',
			getids:[],
			cchoses:[],
			get:0
		}),
		mounted() {
			//console.log(this.rdata)
					var data = this.rdata
					var answer = data.answer
                    var answerFilter = [];
					for(var i= 0;i<answer.length;i++){
                        if(checkIsInApp() && ["精力", "睡眠", "头发", "皮肤", "眼睛" ].indexOf(answer[i].item) == -1){
                           // continue;
                        }
						answer[i].type=false
                        answerFilter.push(answer[i])
					}
					data.answer = answerFilter
					this.qusdata = data;
		},
		methods:{
            checkIsInApp(){
                return checkIsInApp();
            },
			getthisspecial(e,item){
				var that = this
				var qusdata = this.qusdata
				var chose = qusdata.answer
				var num = this.num
				if(item.item=='HPV'&&item.type==false){
					//console.log('HPV')
					that.$Message.info({
                content: '建议HPV人群将HPV作为唯一健康目标',
                duration: 1.5
            })
				}
				if(!chose[e].type){
					for(var i = 0;i<chose.length;i++){
						if(chose[i].group!=chose[e].group){
							if(chose[i].type==true){
								chose[i].type=false
								num --
							}
						}
					}
					if(num==3){
						that.$Message.info('最多选择三项')
					}else{
						num++
						chose[e].type=!chose[e].type
						qusdata.answer = chose
						that.qusdata =qusdata
						that.num = num
				that.$forceUpdate()
					}
				}else{
					num--
					chose[e].type=!chose[e].type
						qusdata.answer = chose
						that.qusdata =qusdata
					that.num = num
				}
			},
			ok(){
				var that = this
				var qusdata = this.qusdata
				var answer = qusdata.answer
				var arr = []
				for(var i=0;i<answer.length;i++){
					if(answer[i].type){
						arr.push(answer[i].id)
					}
				}
				if(arr.length==0){
					that.$Message.info('请选择后再提交')
				}else{
					this.$emit('getback',{value:arr,way:'1'})
				}
			},
			last(){
				this.$emit('getback',{value:'',way:'0'})
			}
		}
	}
</script>

<style>
	.specialboxs{
		width: 100%;
		max-width: 26rem;
		height: auto;
		margin: 0 auto;
		/* margin-top: 1.5rem; */
	}
	.specialboxs .title{
		width: 100%;
		height: auto;
		font-size: 0.52rem;
		line-height: 1rem;
		font-weight: bold;
	}
	.specialboxs .msg{
		width: 100%;
		font-size: 0.3rem;
		line-height: 0.52rem;
		color: #1A1A1A;
	}
	.specialboxs .tab{
		background: #C25E4D;
		width: 1.11rem;
		height: 0.145rem;
		border-radius: 0.145rem;
		margin: 0.59rem auto 1.11rem auto;
	}
	.specialboxs .chose{
		width: 75%;
		margin: 0 auto;
		height: auto;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.specialboxs .chose .chosebox{
		width: 1.83rem;
		height: 1.83rem;
		min-width: auto;
		border-radius: 1.83rem;
		padding: 0.22rem 0.47rem;
		/* border: 0.01rem solid #eee; */
		background: #FFFFFF;
		margin: 0.2rem;
		cursor: pointer;
		align-items: center;
	}
	.specialboxs .chosebox .icon{
		width: 0.89rem;
		height: 0.89rem;
		/* margin: 0 0.47rem; */

				display: table-cell;
				vertical-align: middle;
				text-align: center;
	}
	.specialboxs .chosebox img{
		width: 100%;
		height:100%;
		max-height: 0.89rem;
		vertical-align:inherit;
		/* max-width: 0.89rem;
		max-height: 0.89rem; */
		margin: 0 auto;
		/* position: absolute;
		top: 50%;
    transform: translateY(-50%); */
	}
	.specialboxs .chosebox .txt{
		width: 100%;
		height: 0.37rem;
		line-height: 0.37rem;
		font-size: 0.26rem;
		/* margin-top: 0.15rem; */
		text-align: center;
	}
	.specialboxs .chosebox .centertxt{
		width: 100%;
		height: 1.4rem;
		line-height: 1.4rem;
		font-size: 0.2rem;
	}
	.specialboxs .chose .thisone{
		background: #FFFFFF;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(216, 118, 101, 0.26);
		border-radius: 0.92rem;
		border: 0.04rem solid #C25E4D;
	}
	.specialboxs .thisone .centertxt{
		color: #C25E4D;
	}
	.btns .nums{
		font-size: 0.41rem;
		line-height: 0.56rem;
		margin-top: 0.27rem;
		margin-right: 0.56rem;
		color: #666666;
	}
</style>
