<template>
  <div id="userList">
    <div class="boxTitle">用户橘袋列表</div>
	<div style="display:flex;align-items:center"><input type="text" class="user-input" v-model="userName" placeholder="请输入橘袋名称"/><button class="serchButton" @click="onSearch">查询</button></div>
	<div class="listTitle"><div style="width:3rem">橘袋名称</div><div style="width:2rem;text-align:left">打卡状态</div><div class="rightIcon"></div></div>
	<div v-for="(item,index) in judaiList" :key="index" class="ListItem" @click="onBind(item)"><div style="width:3rem">{{item.name}}</div><div style="width:2rem;text-align:left">{{item.status_title}}</div><img class="rightIcon" src="../../public/imgs/chevron-down.png"></div>
	<div class="pop" v-show="popShow">
		<div class="popMain">
			<div class="popTitle">{{popTitle}}</div>
			<div class="popContent">{{popContent}}</div>
			<div class="popbotton">
				<button class="cancel" @click="cancel">取消</button>
				<button class="submit" @click="submit">确定</button>
			</div>
		</div>
	</div>
  </div>
</template>

<script>
export default {
	data() {
		return {
			userName: "",
			judaiList:[],
			popShow:false,
			popTitle:'',
			popContent:''
		};
	},
	created(){
		this.user_id = window.location.search.split('=')[1]
		this.getCartList(this.userName)
		console.location(this.judaiList)
	},
	methods:{
		onSearch(){
			this.getCartList(this.userName)
		},
		getCartList(key_word){
			this.$ajax.post(this.API.api.apiurl.getUserCartList,{user_id:this.user_id,key_word}).then(res=>{				
				const {data,code} = res.data
				if(code === 0){					
					this.judaiList = data					
				}				
			})
		},
		onBind(item){
			if(item.status_title == '已完成'){
				this.popShow=true;
				this.popTitle = '已完成';
				this.popContent = '该橘袋已完成打卡';
				this.code = 153
			}else if(item.status_title == '未打卡'){
				if(item.toast){
					this.popShow=true;
					this.popTitle = '切换橘袋打卡';
					this.popContent = item.toast;
					this.cart_id = item.id;
					this.code = 150;
				}else{
					this.setBind(item.id)
				}
			}else if(item.status_title == '打卡中'){
				if(item.toast){
					this.popShow = true;
					this.popTitle = '打卡中';
					this.popContent = '该橘袋已由他人开启打卡，无法重复打卡'
					this.code = 151
				}else{
					window.location.href = `https://www.nutri-packs.cn/checkInEveryDay`
				}
				
			}else if(item.status_title == '已解绑'){
				if(item.toast){
					this.popShow = true;
					this.popTitle = '已解绑';
					this.popContent = item.toast
					this.code = 153
				}
			}			
		},
		setBind(id){
			this.$ajax.post(this.API.api.apiurl.onBind,{user_id:this.user_id,cart_id:id}).then(res=>{
				if(res.data.code == 0){
					window.alert('成功开启打卡')
					setTimeout(()=>{
						window.location.href = 'https://www.nutri-packs.cn/checkInEveryDay'
					},1000)					
				}
			})
		},
		changeBind(){
			this.$ajax.post(this.API.api.apiurl.changeBind,{user_id:this.user_id,cart_id:this.cart_id}).then(res=>{
				console.log(res,'------------')
				const {code} = res.data
				if(code == 0 ){
					window.alert('切换成功')
					setTimeout(()=>{
						window.location.href = 'https://www.nutri-packs.cn/checkInEveryDay'
					},1000)	
				}
			})
		},
		cancel(){
			this.popShow=false
		},
		submit(){
			if(this.code == 150){
				this.changeBind()
			}else if(this.code == 151){
				this.popShow=false
			}else if(this.code == 153){
				this.popShow=false
			}
		},
	}
};
</script>

<style scoped>
#userList{
	text-align:left!important;
	padding:0.8rem 0.2rem
}
.boxTitle{
	font-size:0.35rem;
	color:#C25E4D;
	margin-bottom:0.3rem
}
.user-input{
	width:78%;
	height:0.8rem;
	border:1px solid #cccccc;
	font-size:0.35rem;
	color:#cccccc;
	font-weight:500;
	padding-left:0.2rem;
}
.serchButton{
	display:inline;
	width:2rem;
	height:0.8rem;
	background:#333333;
	color:white;
	font-size:0.35rem;
	font-weight:500
}
.listTitle{
	font-size:0.35rem;	
	border-bottom:2px solid #cccccc;
	width:100%;
	font-weight:600;
	margin-top:0.3rem;
	display:flex;
	justify-content:space-between;
	line-height:1rem
}
.ListItem{
	border-bottom:1px solid #cccccc;
	width:100%;
	display:flex;
	justify-content:space-between;
	height:1rem;
	line-height:1rem;
	font-size:0.35rem;	
}
.rightIcon{
	width:1rem;
	height:1rem;
	transform:rotate(270deg)
}
.pop{
	position: fixed;
	top:0;
	left:0;
	background:rgba(0,0,0,0.5);
	width:100vw;
	height:100vh
}
.popMain{
	position: absolute;
	width:80%;
	background:white;
	top:32%;
	left:50%;
	transform: translate(-50%,-50%);
	padding:10px 5px
}
.popTitle{
	width:100%;
	text-align:center;
	height: 1rem;
	line-height:1rem;
	font-size:0.5rem;
}
.popContent{
	width:100%;
	text-align:center;
	font-size:0.4rem;
}
.popbotton{
	width:100%;
	display:flex;
	justify-content:space-between;
}
.cancel{
	width:2rem;
	border:1px solid #cccccc!important;
	border-radius:10px;
	font-size:0.4rem;
	font-weight:bold;
	line-height:1rem;
	background:white
}
.submit{
	width:2rem;
	border-radius:10px;
	font-size:0.4rem;
	font-weight:500;
	background:#C25E4D;
	color:white;
	font-weight:bold;
	line-height:1rem
}
</style>

