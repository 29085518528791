<template>
	<div class="myreport main-page" v-if="list">
		<div class="page-bg"></div>
		<div class="myreport-title">
			我的报告
		</div>
		<div class="reportlistbox">
			<div class="nomsg" v-if="list.data.length==0">暂无报告</div>
			<div class="thereport" v-for="(item,index) in list.data" :key="index" @click="godetail(item.id)">
				<div class="myreport-name">
					<span>{{item.fill_name}}</span>
					<!-- <img class="sactimg" src="../../public/imgs/report_act.png" alt="" @click="showit(item,index)"> -->
					<Dropdown trigger="click">
						<img href="javascript:void(0)" class="sactimg cursor" src="../../public/imgs/report_act.png" alt="" @click="showit(item,index)">
						<DropdownMenu slot="list">
							<DropdownItem>
								<div class="action-line" @click="delthisreport(item.id,index)">
									<img class="action-img1" src="../../public/imgs/two/del_1.png" alt="">
									<img class="action-img2" src="../../public/imgs/two/del_2.png" alt="">
									<div class="action-name">删除报告</div>
								</div>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
				<div class="myreport-main">
					<div class="myreport-imgs">
						<img :src="items.url" alt="" v-for="(items,indexs) in item.re_class" :key="indexs">
					</div>
					<div class="myreport-line">
						<div class="myreport-msg">每日服用：{{item.goods_count}} 种补剂</div>
						<div class="myreport-time">{{item.date}}</div>
					</div>
				</div>
			</div>
			<div class="getmorelist" v-if="list.data.length!=0">
				<div class="nomorelist" v-if="page==last_page">没有更多数据了</div>
				<div class="havemorelist cursor" v-else @click="getmore">点击加载更多</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'myreport',
		components: {},
		data: () => ({
			list: '',
			last_page:1,
			info: '',
			page: 1,
			cangodetail:true,
			hei: 0
		}),
		mounted() {
			var hh = Number(window.innerHeight) - 243
			this.hei = hh

			var that = this
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				console.log('用户未登录')
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login?way=my-report',
					way:'my-report'
				})
			} else {
				this.info = info
				this.$ajax.post(this.API.api.apiurl.getCountListNew, {
						member_id: info.member_id,
						type: 0,
						page: 1,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						//console.log(res)
						var data = res.data
						if (data.code == 0) {
							// console.log(data.data)
							var date = ''
							var data1 = data.data.list
							var list = data1.data
							var arr = []
							for (var i = 0; i < list.length; i++) {
								var time = new Date(list[i].create_date * 1000)
								var y = time.getFullYear()
								var m = time.getMonth() + 1
								var d = time.getDate()
								m = m < 10 ? '0' + m : m
								d = d < 10 ? '0' + d : d
								date = y + '-' + m + '-' + d;
								list[i].date = date
								list[i].showact = false
								if(list[i].is_new==1){
									arr.push(list[i])
								}
							}
							data1.data = arr
							that.list = data1
							that.last_page = data1.last_page
							// that.goreportdetail(data.data.data[0].id)
						} else {
							console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
		},
		methods: {
			getmore(){
				var that = this
				var page = this.page + 1
				var last_page = that.last_page
				if (page > last_page) {
					this.$Message.info('没有更多数据了!');
				} else {
						that.$ajax.post(that.API.api.apiurl.getCountListNew, {
								member_id: that.info.member_id,
								type: 0,
								page: page,
							},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
							.then(res => {
								//console.log(res)
								var data = res.data
								if (data.code == 0) {
									that.page = page
									console.log(data.data)
									var date = ''
									var data1 = data.data.list
									var list = data1.data
									var thelist = that.list
									var thearr = thelist.data
									// var arr = []
									for (var i = 0; i < list.length; i++) {
										var time = new Date(list[i].create_date * 1000)
										var y = time.getFullYear()
										var m = time.getMonth() + 1
										var d = time.getDate()
										m = m < 10 ? '0' + m : m
										d = d < 10 ? '0' + d : d
										date = y + '-' + m + '-' + d;
										list[i].date = date
										if(list[i].is_new==1){
											thearr.push(list[i])
										}
									}
									// data1.data = list
									// thearr.concat(list)
									// console.log(thearr)
									data1.data = thearr
									that.list = data1
									that.last_page = data1.last_page
									// that.goreportdetail(data.data.data[0].id)
								} else {
									console.log('错误：' + res.data.message)
								}
							})
							.catch(error => {
								console.log(error)
							})
				}
			},
			showit(item,index){
				var that = this
				var z = item.showact
				var list = this.list
				var datal = list.data
				this.cangodetail = item.id		
				if(datal[index].showact){
					setTimeout(function(){
						that.cangodetail = ''		
					},100)
				}
				for(var i=0;i<datal.length;i++){
					datal[i].showact = false
				}
				datal[index].showact = !z
				list.data = datal
				this.list = list
				this.$forceUpdate()
			},
			noshowit(index){
				var list = this.list
				var datal = list.data
				datal[index].showact = false
				list.data = datal
				this.list = list
				var that = this
				setTimeout(function(){
					that.cangodetail = ''
				},1000)
				this.$forceUpdate()
			},
			delthisreport(id,index){
				this.cangodetail = id
				var that = this
				console.log(id)
				this.$ajax.post(this.API.api.apiurl.delCount, {
						count_id: id,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						//console.log(res)
						var data = res.data
						if (data.code == 0) {
							console.log(data)
							var list = that.list
							var datal = list.data
							datal[index].showact = false
							datal.splice(index,1)
							list.data = datal
							console.log(list)
							that.list = list
							that.$Message.info('删除成功')
						} else {
							console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			goreportdetail(id) {
				this.$router.push({
					path: 'report',
					query: {
						id: id
					}
				})
			},
			godetail(id) {
				if(this.cangodetail == id	){
					console.log('不能跳转')
					this.cangodetail = ''
				}else{
					this.$router.push({
						path: 'report',
						query: {
							id: id,
							way: 'list'
						}
					})
				}
			},

		}
	}
</script>

<style>
	.myreport-title {
		width: 100%;
		height: 0.83rem;
		font-size: 0.59rem;
		line-height: 0.83rem;
		font-weight: bold;
		margin: 0.87rem 0 0.72rem 0;
		padding: 0 0.19rem;
		text-align: left;
		display: flex;
		justify-content: space-between;
	}

	.myreport-box {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.thereport {
		width: 8.7rem;
		width: calc(50% - 0.295rem);
		height:3.74rem;
		background: #FFFFFF;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		padding: 0.59rem;
		margin-bottom: 0.59rem;
	}

	.myreport-name {
		width: 100%;
		height: 0.82rem;
		border-bottom: 0.04rem solid #333333;
		line-height: 0.52rem;
		color: #1A1A1A;
		font-size: 0.37rem;
		text-align: left;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	.myreport-name img{
	}
	.myreport-name .sactimg{
		width: 0.4rem;
		height: 0.4rem;
		margin: 0.1rem -0.17rem 0.21rem 0;
		/* position: absolute; */
		/* right: -0.3rem; */
		/* top: 0;
		left: 0; */
	}

	.myreport-imgs {
		width: 100%;
		display: flex;
		justify-content: left;
		height: 0.44rem;
		margin-top: 0.59rem;
	}

	.myreport-imgs img {
		width: 0.44rem;
		height: 0.44rem;
		margin-right: 0.3rem;
	}

	.myreport-line {
		width: 100%;
		height: 0.41rem;
		margin-top: 0.26rem;
		display: flex;
		justify-content: space-between;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #666666;
	}

	.myreport-box .orderList-page {
		margin-bottom: 0.5rem;
	}

	.ivu-scroll-container {
		height: calc(100vh - 4rem) !important;
	}

	.ivu-scroll-content {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0.15rem;
	}
	.ivu-scroll-container{
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
	}

	.ivu-scroll-container::-webkit-scrollbar {
		display: none;
	}
	
	.pic-bg{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		/* background: rgba(0,0,0,0.1); */
	}
	.report-actions{
		/* display: none; */
		width: 3rem;
		border-radius: 0.19rem;
		overflow: hidden;
		height: auto;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(0,0,0, 0.25);
		position: absolute;
		top: 0.73rem;
		right: 0;
	}
	.action-line{
		width: 100%;
		height: 1.02rem;
		line-height: 1.02rem;
		font-size: 0.37rem;
		background: #FFFFFF;
		display: flex;
		z-index: 20;
		cursor: pointer;
	}
	.action-line:hover{
		background: #C25E4D;
	}
	.action-line .action-name{
		width: auto;
		height: 1.02rem;
		line-height: 1.02rem;
		font-size: 0.32rem;
		color: #1A1A1A;
		font-weight: 400;
		margin-right: 0.5rem;
		/* margin-left: 0.6rem; */
	}
	.action-line:hover>.action-name{
		color: #FFFFFF;
	}
	.action-line .action-img2{
		display: none;
	}
	.action-line img{
		position: relative;
		width: 0.36rem;
		height: 0.36rem;
		margin: 0.33rem 0.15rem 0.33rem 0.33rem;
		right: 0;
	}
	.action-line:hover>.action-img1{
		display: none;
	}
	.action-line:hover>.action-img2{
		display: block;
	}
	
	.reportlistbox{
		padding: 0.19rem;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.getmorelist{
		width: 100%;
		height: 2.4rem;
		margin: 0 auto;
	}
	.getmorelist .nomorelist{
		width: 100%;
		height: 1.2rem;
		line-height: 1.2rem;
		text-align: center;
		font-size: 0.37rem;
		color: #999999;
	}
	.getmorelist .havemorelist{
		width: 3rem;
		height: 0.8rem;
		line-height: 0.8rem;
		/* padding: 0 0.2rem; */
		margin: 0.2rem auto;
		font-size: 0.37rem;
		text-align: center;
		color: #1A1A1A;
		/* border: 0.01rem solid #999999; */
		color: #C25E4D;
		border-radius: 0.19rem;
	}



	@media screen and (max-width:1000px) {
		.myreport-box {
			justify-content: center;
		}

		.thereport {
			margin: 0 auto;
			width: 100%;
			margin-bottom: 0.59rem;
			padding: 0.29rem 0.59rem;
			height: auto;
		}
		.myreport-title{
			margin: 0.59rem auto;
		}
		.myreport-imgs{
			margin-top: 0.6rem;
		}
		.myreport-name{
			font-size: 0.45rem;
			height: 1.13rem;
			line-height: 1.13rem;
		}
		.reportlistbox{
			padding: 0.19rem;
		}
	}
	.myreport .ivu-select-dropdown{
		left: auto!important;
		right: 0!important;
		padding: 0;
	}
	.myreport .ivu-dropdown-item{
		padding: 0;
	}
	.myreport .action-line{
		border-radius: 0.19rem;
	}
</style>
