<template>
    <div class="shanghaishow">
        <div class="show_pc">
            <div class="qrheader">
                <div class="pc_qrmessge">{{ judaiName }}, 你已完成AI问卷!</div>
                <div class="pc_qrtime" v-if="!isbserve">{{ time }}s</div>
            </div>
            <div class="pc_content">
                <div>
                    <div class="pc_qrtext">扫码查看</div>
                    <div class="pc_qrtext">你的专属定制方案</div>
                    <div class="pc_qrtips">请使用微信扫码</div>
                    <button class="pcQrButton" @click="goBackIndex">
                        <div>返回首页</div>
                        <img src="../../public/imgs/index/more.png" alt="">
                    </button>
                </div>
                <div class="pcImagebox">
                    <img :src="url" class="pcImage" v-if="time > 0" />
                    <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/Home/images/qr/disable.png"
                        alt="loading" class="pcImage" v-else />
                </div>
            </div>
        </div>
        <div class="show_mb shanghaimb_qr">
            <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/Home/images/qr/shanghaiqrMb.png"
                class="mbBack" />
            <div class="qr_message">
                <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/Home/images/qr/mb_back.png"
                    alt="" class="mb_contentBack">
                <div class="qr_content">
                    <div class="qr_title">
                        <div>{{ judaiName }}, 你已完成AI问卷!</div><div v-if="IS_B_SERVE">{{time }}s</div>
                    </div>
                    <div class="qrtip">
                        <div class="qrtipMessage">扫码查看</div>
                        <div class="qrtipMessage">你的专属定制方案</div>
                    </div>
                    <div class="imgbox">
                        <div style="width: 4rem;height:4rem;background-color: #ffffff;border-radius: 50%;display: flex;justify-content: center;align-items: center;" v-if="time>0">
                        <img :src="url" alt="loading" class="qrimage" />
                        </div>
                        <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/Home/images/qr/disable.png"
                            alt="loading" class="disableqrimage" v-else />
                    </div>
                    <div style="font-size: 0.37rem;color: #ffffff;text-align: center;margin-top: 0.2rem;">微信扫码查看</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var changeinfo = '';
export default {
    data() {
        return {
            url: '',
            time: 120,
            count_id: '',
            judaiName: '',
            isbserve:0
        }
    },
    created() {
        const url = sessionStorage.getItem('qrCode');
        const count_id = sessionStorage.getItem('count_id');
        const oldcount = sessionStorage.getItem('oldcount');
        this.judaiName = sessionStorage.getItem('judaiName')
        this.isbserve = sessionStorage.getItem('is_b_serve')
        console.log(sessionStorage.getItem('is_b_serve'))
        this.url = url
        this.count_id = count_id
        if (oldcount && count_id && (oldcount == count_id)) {
            this.time = 0
        } else {
            this.countdown()
        }
    },
    watch: {
        time(value) {
            if (value === 0) {
                clearInterval(this.timer)
            }
        }
    },
    methods: {
        countdown() {
           const shanghaishow = sessionStorage.getItem('shanghaishow')
            if(shanghaishow){
                    sessionStorage.setItem('oldcount', this.count_id)
                this.timer = setInterval(() => {
                    this.time = this.time - 1
                }, 1000);
            }           
        },
        goBackIndex() {
            var timestamp = (new Date()).getTime();
            localStorage.setItem('ztimestamp', timestamp)
            var that = this
            changeinfo = setInterval(function() {
                var info = JSON.parse(localStorage.getItem('info'))
                that.info = info ? info : ''
                if (info) {
                    clearInterval(changeinfo)
                }
            }, 2000)
            localStorage.removeItem("info")
            this.showAI = false
            sessionStorage.removeItem('showAI');
            this.$router.push({
                path: '/'
            })           
        },
    }
}
</script>
<style>
.qr_title {
    font-size: 0.3rem;
    color: #FFFFFF;
    line-height: 1rem;
    padding: 0 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.qrtip {
    margin-top: 0.5rem;
    text-align: center;
}

.qrtipMessage {
    font-size: 0.6rem;
    margin: 0 auto;
    line-height: 0.7rem;
    font-weight: 700;
}

.imgbox {
    margin-top: 0.1rem;
}

.qrimage {
    width: 3.6rem;
    height: 3.6rem;
}
.disableqrimage{
    margin-top: 0.1rem;
    width: 4rem;
    height: 4rem;
}
.shanghaimb_qr {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.qr_message {
    position: relative;
    width: 9.5rem;
    height: 8.5rem;
    top: 3rem;
}

.mbBack {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

.mb_contentBack {
    width: 9.5rem;
    height: 8.5rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}
.qr_content{
    position: relative;
    top: 0;
    left: 0;
    width: 9.5rem;
    height: 8.5rem;
    display: flex;
    flex-direction:column;
    align-items: center;
    z-index: 999;
}
.qrheader {
    display: flex;
    background: #0E6D9F;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 400;
    align-items: center;
    height: 60px;
    padding-right: 64px;
    box-sizing: border-box;
}

.pc_qrmessge {
    width: auto;
    margin: 0 auto;
}

style attribute {
    overflow-x: none;
}

.pc_qrtext {
    text-align: left;
    font-size: 54px;
    font-weight: bold;
}

.pc_content {    
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding:0 100px;
    z-index: 99999;
    top: 100px;
}

.pc_qrtips {
    text-align: left;
    margin-top: 18px;
    font-size: 24px;
    color: #1A1A1A;
    ;
}

.pcQrButton {
    display: flex;
    width: 193px;
    height: 60px;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 63px;
    font-size: 18px;
    color: #FFFFFF;
    border-radius: 44px;
    background: #0E6D9F;
}

.pcQrButton img {
    width: 24px;
    height: 24px;
}

.pcImage {
    width: 321px;
    height: 321px;
}</style>