<template>
	<div ref="reportmain" class="report main-page" v-if="report">
		<div class="btns" v-if="checkIsInApp()">
            <button class="go-to-buy" v-if="checkIsInApp()" @click="goToBuy">{{blur == true ? "点击查看报告":"点击购买补剂"}}</button>
        </div>
		<div class="page-bg"></div>
        <div :class="{ 'reportbox': true, 'report-blur': blur }">
			<div class="reporttitle">
				<div class="reportname" v-if="!checkIsInApp()">
                    <div class="name-top">个人定制</div>健康评估报告
				</div>
				<div class="reportname" v-if="checkIsInApp()">
                    <div class="name-top"></div>评估报告
                      <el-button type="text" @click="modalReportInfo=true"><img src="/imgs/question-mark.png" style="margin-top:-4px;width:20px;"></el-button>
				</div>

                <Modal
                v-model="modalReportInfo"
                title="信息来源">
                    <p><b>《China Health and Nutrition Survey》</b><br/> http://www.cpc.unc.edu/projects/china/</p>
                </Modal>

				<div class="reporttime">{{report.count_info.create_date}}</div>
			</div>
			<div class="personinfo" v-if="report.special.type==0||report.special.type>3">
				<div class="info-topbox">
					<div class="user">
						<img :src="report.count_info.headimage=='/static/admin/images/default_headimg.jpg'?require('../../public/imgs/tou.png'):report.count_info.headimage"
							alt="">
						<div class="name-module">
							<div class="username">{{report.count_info.nickname}}</div>
							<div class="usermodule"><span v-for="(it,inde) in report.count_info.recommend_class"
									:key="inde">{{it}}{{inde+1==report.count_info.recommend_class.length?'':'/'}}</span>
							</div>
						</div>
					</div>
					<div class="userinfo">
						<div class="userinfo-box">
							<div class="info-name">身高/CM</div>
							<div class="info-value">{{report.count_info.height}}</div>
						</div>
						<div class="userinfo-box">
							<div class="info-name">体重/KG</div>
							<div class="info-value">{{report.count_info.body_weight}}</div>
						</div>
						<div class="userinfo-box">
                            <div class="info-name">BMI
                                <el-button type="text" @click="modalBMIInfo=true"><img src="/imgs/question-mark.png" style="margin-top:-4px;width:20px;"></el-button>
                <Modal
                v-model="modalBMIInfo"
                title="信息来源">
                <p><b>《Body Mass Index: Obesity, BMI, and Health: A Critical Review》</b>https://pubmed.ncbi.nlm.nih.gov/27340299/</p>
                </Modal>
                            </div>
							<div class="info-value">{{report.count_info.bmi}}</div>
						</div>
					</div>
				</div>
				<div class="specialtext" v-html="report.special.text" v-if="report.special.text"></div>
				<div class="user-report"
					v-if="report.tabnum!=0&&report.special.type!=6&&report.special.type!=7&&report.special.type!=5&&report.special.type!=4">
					<div class="tline">
						<div class="msg-title">
							<div class="title1">根据你提供的信息</div>
							<div class="title2">
								<div>我们发现</div>
								<div class="title-style">你存在以下问题</div>
							</div>
						</div>
					</div>
					<div class="user-body">
						<div class="report-mode model1">
							<div class="txt" v-for="(item,i) in report.tag_list[0].tag" :key="i">{{item}}</div>
							<div class="txt notxt" v-if="report.tag_list[0].tag.length==0">暂时没发现问题</div>
							<div class="model-line"></div>
							<div class="model-box">
								<img :src="report.tag_list[0].demand_icon.thumb_url" alt="">
								<div class="model-name">{{report.tag_list[0].demand_name}}</div>
							</div>
						</div>
						<img class="bodyimg" :src="imgurl" alt="">
						<div class="report-mode model2" v-if="report.tag_list[1]">
							<div class="txt" v-for="(item,i1) in report.tag_list[1].tag" :key="i1">{{item}}</div>
							<div class="txt notxt" v-if="report.tag_list[1].tag.length==0">暂时没发现问题</div>
							<div class="model-line"></div>
							<div class="model-box">
								<img :src="report.tag_list[1].demand_icon.thumb_url" alt="">
								<div class="model-name">{{report.tag_list[1].demand_name}}</div>
							</div>
						</div>
						<div class="report-mode model3" v-if="report.tag_list[2]">
							<div class="txt" v-for="(item,i2) in report.tag_list[2].tag" :key="i2">{{item}}</div>
							<div class="txt notxt" v-if="report.tag_list[2].tag.length==0">暂时没发现问题</div>
							<div class="model-line"></div>
							<div class="model-box">
								<img :src="report.tag_list[2].demand_icon.thumb_url" alt="">
								<div class="model-name">{{report.tag_list[2].demand_name}}</div>
							</div>
						</div>
					</div>
					<div class="report-msg">
						<div class="msg-detail">
							<p v-if="report.label_list.length>0">
								你在
								<span v-for="(thespan,sindex0) in report.label_list"
									:key="'sindex'+sindex0">{{sindex0==0?thespan:sindex0+1==report.label_list.length?'和'+thespan:'、'+thespan}}</span>方面存在问题，这会导致你存在或加重
								<span v-for="(item,j) in report.tlist"
									:key="j">{{j==0?item.demand_name:j+1==report.tlist.length?'和'+item.demand_name:'、'+item.demand_name}}</span>
                                的问题。{{ !checkIsInApp() ? "针对这些情况，我们做出以下具体分析，并给出相应建议。": ''}}
							</p>
							<p v-if="report.label_list.length==0">
								你目前的生活习惯对
								<span v-for="(item,j1) in report.tag_list"
									:key="'j'+j1">{{j==0?item.demand_name:j+1==report.tag_list.length?'和'+item.demand_name:'、'+item.demand_name}}</span>
								没有造成影响。针对这些情况，我们做出以下具体分析，并给出相应建议。
							</p>
						</div>

                            <div style="text-align:left;" v-if="checkIsInApp()">
                                <b>信息来源:</b><br>
                            评估报告依据:《China Health and Nutrition Survey》 http://www.cpc.unc.edu/projects/china/
                            <br>
                            BMI依据: 《Body Mass Index: Obesity, BMI, and Health: A Critical Review》https://pubmed.ncbi.nlm.nih.gov/27340299/
                            </div>
					</div>
				</div>
				<div class="user-report"
					v-if="report.tabnum==0&&report.special.type!=6&&report.special.type!=7&&report.special.type!=5&&report.special.type!=4">
					<div class="adviser-txts">
						根据你提供的信息，你目前的生活习惯对
						<span v-for="(thespan,sindex1) in report.analysis_list"
							:key="sindex1">{{sindex1==0?thespan.demand_name:sindex1+1==report.analysis_list.length?'和'+thespan.demand_name:'、'+thespan.demand_name}}</span>
							没有造成任何影响。但是我们在你的生活方式部分，
						{{report.label_list.length>0?'发现你在':'发现了一些问题。针对这些情况，我们做出以下具体分析，并给出相应建议。'}}
						<span
							v-for="(thespan,sindex2) in report.label_list"
							:key="'s'+sindex2">{{sindex2==0?thespan:sindex2+1==report.label_list.length?'和'+thespan:'、'+thespan}}</span>
							{{report.label_list.length>0?'方面存在问题。针对这些情况，我们做出以下具体分析，并给出相应建议。':''}}

					</div>
					<div class="adviser-detail adviser-detail1" v-if="report.label_list.length==0">
						<img class="adviser-img" :src="report.bind_team.member_img" alt="">
						<div class="adviser-box">
							<div class="adviser-name">{{report.bind_team.member_name}}</div>
							<div class="adviser-position">{{report.bind_team.member_title}}</div>
							<div class="adviser-imgs">
								<img :src="iimg.goods_img" alt="" :key="img1" v-for="(iimg,img1) in report.select_re">
							</div>
							<div class="adviser-msg">
								<div v-for="(items,indexs1) in report.select_re" :key="indexs1">
									<div v-for="(itemss,indexss1) in items.extend" :key="indexss1">
										<div v-for="(itemsss,indexsss1) in itemss.txt" :key="indexsss1">
											<div v-if="itemsss.it"><span
													v-on:click="goshowdetail(itemsss.id,itemsss.value)"><b>{{itemsss.value}}
														<div class="reportqusimg">?</div>
													</b></span></div>
											<div v-if="!itemsss.it">{{itemsss.value}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="adviser-txt">
							购买方案后，健康顾问VC将1对1为你解读报告并帮助你解决相关健康问题。
						</div>
					</div>
				</div>
			</div>


			<div class="personinfo"
				:class="report.special.type==1&&report.special.type==2&&report.special.type==3&&report.tabnum==0?'disnone':''"
				v-if="report.special.type==1||report.special.type==2||report.special.type==3">
				<div class="info-topbox">
					<div class="user">
						<img :src="report.count_info.headimage=='/static/admin/images/default_headimg.jpg'?require('../../public/imgs/tou.png'):report.count_info.headimage"
							alt="">
						<div class="name-module">
							<div class="username">{{report.count_info.nickname}}</div>
							<div class="usermodule"><span v-for="(it,inde) in report.count_info.recommend_class"
									:key="inde">{{it}}{{inde+1==report.count_info.recommend_class.length?'':'/'}}</span>
							</div>
						</div>
					</div>
					<div class="userinfo">
						<div class="userinfo-box">
							<div class="info-name">身高/CM</div>
							<div class="info-value">{{report.count_info.height}}</div>
						</div>
						<div class="userinfo-box">
							<div class="info-name">体重/KG</div>
							<div class="info-value">{{report.count_info.body_weight}}</div>
						</div>
						<div class="userinfo-box">
							<div class="info-name">BMI</div>
							<div class="info-value">{{report.count_info.bmi}}</div>
						</div>
					</div>
				</div>
				<div class="yimg">
					<img :src="report.special.type==1||report.special.type==2?yimg:bimg" alt="">
				</div>
				<div class="nogoodstxt">
					由于你正处于
					<span v-if="report.special.type==1"><b>备孕期</b></span>
					<span v-if="report.special.type==2"><b>孕期</b></span>
					<span v-if="report.special.type==3"><b>哺乳期</b></span>
					，建议你服用
					<span v-for="(ritem,index) in report.pregnancy_re_goods" :key="index">
						<b>{{index==0?'':index+1==report.pregnancy_re_goods.length?'和':'、'}}
							{{ritem.goods_name| replaceCommaWithBlank}}</b>
					</span>
					。因处于特殊时期，当前你的
					<span v-for="(ritem,indexl) in report.analysis_list" :key="indexl">
						<b>{{index==0?'':index+1==report.analysis_list.length?'和':'、'}}
							{{ritem.demand_name}}</b>
					</span>
					涉及到的补剂暂未推荐给你。你可以预约我们的健康咨询服务，营养师将在充分了解你当前的身体状态后，再根据你的需求，确定你每日方案的补剂内容。
				</div>
				<div class="adviser-detail adviser-detail1">
					<img class="adviser-img" :src="report.bind_team.member_img" alt="">
					<div class="adviser-box">
						<div class="adviser-name">{{report.bind_team.member_name}}</div>
						<div class="adviser-position">{{report.bind_team.member_title}}</div>
						<div class="adviser-imgs">
							<img :src="iimg.goods_img" alt="" :key="img"
								v-for="(iimg,img) in report.pregnancy_re_goods">
						</div>
						<div class="adviser-msg">
							<div v-for="(items,indexs) in report.pregnancy_re_goods" :key="indexs">
								<div v-for="(itemss,indexss) in items.extend" :key="indexss">
									<div v-for="(itemsss,indexsss) in itemss.txt" :key="indexsss">
										<div v-if="itemsss.it"><span
												v-on:click="goshowdetail(itemsss.id,itemsss.value)"><b>{{itemsss.value}}
													<div class="reportqusimg">?</div>
												</b></span></div>
										<div v-if="!itemsss.it">{{itemsss.value}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="nouse" v-if="report.pregnancy_not_goods.length!=0">
							<img src="../../public/imgs/mis.png" alt="">
							<div class="no-txt">由于你的身体特殊情况，暂时不建议你服用以下补剂：<span
									v-for="(item,index) in report.not_select_re" :key="index">
									<span v-for="(items,indexs) in item.reason" :key="indexs">{{items}}</span>
								</span></div>
						</div>
					</div>
					<div class="adviser-txt">
						购买方案后，健康顾问VC将1对1为你解读报告并帮助你解决<b><span v-if="report.special.type==1">备孕期</span><span
								v-if="report.special.type==2">孕期</span><span
								v-if="report.special.type==3">哺乳期</span></b>相关健康问题。
					</div>
				</div>
			</div>
			<div class="personinfo margintop"
				:class="report.special.type==1&&report.special.type==2&&report.special.type==3&&report.tabnum==0?'disnone':''"
				v-if="report.non_pregnancy_re_goods&&report.non_pregnancy_re_goods.length>0">
				<div class="report-detail" v-if="report.non_pregnancy_re_goods&&report.non_pregnancy_re_goods.length>0">
					<div class="detail-title">
						<div class="title-name">其它营养推荐</div>
						<div class="title-icon"><img src="../../public/imgs/other.svg" alt=""></div>
					</div>
					<div class="main-detail">
						<div class="adviser-detail nogoods">
							<div class="nogoodstxt">除了必备的营养补剂推荐之外，针对特殊生理时期，营养师特别为您提供以下健康建议:</div>
							<img class="adviser-img" :src="report.bind_team.member_img" alt="">
							<div class="adviser-box">
								<div class="adviser-name">{{report.bind_team.member_name}}</div>
								<div class="adviser-position">{{report.bind_team.member_title}}</div>
								<div class="adviser-imgs">
									<img :src="iimg.goods_img" alt="" :key="img"
										v-for="(iimg,img) in report.non_pregnancy_re_goods">
								</div>
								<div class="adviser-msg">
									<div v-for="(items,indexs) in report.non_pregnancy_re_goods" :key="indexs">
										<div v-for="(itemss,indexss) in items.extend" :key="indexss">
											<div v-for="(itemsss,indexsss) in itemss.txt" :key="indexsss">
												<div v-if="itemsss.it"><span
														v-on:click="goshowdetail(itemsss.id,itemsss.value)"><b>{{itemsss.value}}
															<div class="reportqusimg">?</div>
														</b></span></div>
												<div v-if="!itemsss.it">{{itemsss.value}}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="nouse" v-if="report.non_pregnancy_not_goods.length!=0">
									<img src="../../public/imgs/mis.png" alt="">
									<div class="no-txt">由于你的身体特殊情况，暂时不建议你服用以下补剂：<span
											v-for="(item,index) in report.non_pregnancy_not_goods" :key="index">
											<span v-for="(items,indexs) in item.reason" :key="indexs">{{items}}</span>
										</span></div>
								</div>
							</div>
							<div class="adviser-txt">购买方案后，健康顾问VC将1对1为你解读报告并帮助你解决其他相关健康问题。</div>
						</div>
					</div>
				</div>
			</div>


			<div class="moduleinfo"
				:class="report.special.type!=1&&report.special.type!=2&&report.special.type!=3&&report.tabnum==0&&report.label_list.length==0?'disnone':''"
				v-if="report.special.text==''">
				<div class="report-detail" :key="o" v-for="(item,o) in report.analysis_list">
					<div class="detail-title">
						<div class="title-name">0{{o+1}} {{item.demand_name}}</div>
						<!-- <div class="title-icon"><img :src="item.demand_icon.thumb_url" alt=""></div> -->
					</div>
					<div class="main-detail">
						<div class="detail-list" :class="item.goods_re.length==0?'nogoods':''">
							<div class="nogoodstxt" v-if="item.goods_re.length==0">
								尽管当前你的<span><b>{{item.demand_name}}</b></span>没有特别大的问题，但是我们发现你的生活方式仍存在一定的健康隐患:</div>
							<div class="list-box" v-for="(item,j) in item.style_list" :key="j">
								<!-- <img :src="item.icon_url.thumb_url" alt=""> -->
								<div class="list-msg">
									<div class="tabs">
										<div class="tab" v-for="(itm,k) in item.copywriting_list.copywriting_title"
											:key="k">{{itm}}</div>
									</div>
									<div class="fontsize" v-html="item.txt"></div>
								</div>
							</div>
							<div class="nogoodstxt" v-if="item.style_list.length>0&&item.goods_re.length>0">
								这些<span><b>不良的生活方式</b></span>会导致或加重你<span v-for="(tem,ind) in item.tag"
									:key="ind"><b>{{ind==0?tem:ind+1==item.tag.length?'和'+ tem:'、'+tem}}</b></span>的问题。针对以上问题，营养师给出如下补剂建议:
							</div>
							<div class="nogoodstxt" v-if="item.style_list.length==0&&item.goods_re.length>0">
								根据你提供的信息，你目前的生活习惯对<span><b>{{item.demand_name}}</b></span>没有造成任何影响。但是，由于你有<span
									v-for="(tem,ind1) in item.tag"
									:key="ind1"><b>{{ind==0?tem:ind1+1==item.tag.length?'和'+ tem:'、'+tem}}</b></span>的情况，营养师给出如下补剂建议:
							</div>
						</div>
						<div class="adviser-detail" v-if="item.goods_re.length>0">
							<!-- <img class="adviser-img" :src="item.team.member_img.thumb_url" alt=""> -->
							<div class="adviser-box">
								<div class="adviser-name">{{item.team.member_name}}</div>
								<div class="adviser-position">{{item.team.member_title}}</div>
								<div class="adviser-imgs">
									<img :src="iimg.goods_img" alt="" :key="img" v-for="(iimg,img) in item.goods_re">
								</div>
								<div class="adviser-msg">
									<div v-for="(items,indexs) in item.goods_re" :key="indexs">
										<div v-for="(itemss,indexss) in items.txt" :key="indexss">
											<div v-if="itemss.it"><span
													v-on:click="goshowdetail(itemss.id,itemss.value)"><b>{{itemss.value}}
														<div class="reportqusimg">?</div>
													</b></span></div>
											<div v-if="!itemss.it">{{itemss.value}}</div>
										</div>
									</div>
								</div>
								<div class="nouse" v-if="item.not_goods_re.length!=0">
									<img src="../../public/imgs/mis.png" alt="">
									<div class="no-txt">由于你的身体特殊情况，暂时不建议你服用以下补剂：<span
											v-for="(ritem,index) in item.not_goods_re" :key="index">
											<span v-for="(items,indexs) in ritem.reason" :key="indexs">{{items}}</span>
										</span></div>
								</div>
							</div>
							<div class="adviser-txt" v-if="!checkIsInApp()">购买方案后，健康顾问VC将1对1为你解读报告并帮助你解决{{item.demand_name}}方面的问题。</div>
						</div>
					</div>
				</div>


				<div class="report-detail" v-if="report.select_re.length>0">
					<div class="detail-title">
						<div class="title-name">0{{report.analysis_list.length+1}} 其它潜在隐患</div>
						<div class="title-icon"><img src="../../public/imgs/other.svg" alt=""></div>
					</div>
					<div class="nogoodstxt" style="margin-top: 1.19rem;">
						除你关心的需求外，我们发现你存在一些会导致健康隐患的<b><span>不良生活方式</span></b>。营养师针对这些潜在问题，给出以下补剂建议:</div>

					<div class="main-detail">
						<div class="adviser-detail nogoods">
							<img class="adviser-img" :src="report.bind_team.member_img" alt="">
							<div class="adviser-box">
								<div class="adviser-name">{{report.bind_team.member_name}}</div>
								<div class="adviser-position">{{report.bind_team.member_title}}</div>
								<div class="adviser-imgs">
									<img :src="iimg.goods_img" alt="" :key="img" v-for="(iimg,img) in report.select_re">
								</div>
								<div class="adviser-msg">
									<div v-for="(items,indexs) in report.select_re" :key="indexs">
										<div v-for="(itemss,indexss) in items.extend" :key="indexss">
											<div v-for="(itemsss,indexsss) in itemss.txt" :key="indexsss">
												<div v-if="itemsss.it"><span
														v-on:click="goshowdetail(itemsss.id,itemsss.value)"><b>{{itemsss.value}}
															<div class="reportqusimg">?</div>
														</b></span></div>
												<div v-if="!itemsss.it">{{itemsss.value}}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="nouse" v-if="report.not_select_re.length!=0">
									<img src="../../public/imgs/mis.png" alt="">
									<div class="no-txt">由于你的身体特殊情况，暂时不建议你服用以下补剂：<span
											v-for="(item,index) in report.not_select_re" :key="index">
											<span v-for="(items,indexs) in item.reason" :key="indexs">{{items}}</span>
										</span></div>
								</div>
							</div>
							<div class="adviser-txt" v-if="!checkIsInApp()">购买方案后，健康顾问VC将1对1为你解读报告并帮助你解决其他相关健康问题。</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    <div v-if="!checkIsInApp()">
		<div class="bottom-box" v-if="!showlodding&&report.special.type<4">
			<a class="bottom-btn public-btn  cursor" @click="godetail">查看健康方案</a>
		</div>
    </div>
		<div class="bottom-box"
			v-if="report.special.type==7||report.special.type==5||report.special.type==6||report.special.type==4">
			<div class="bottom-btn public-btn  cursor" @click="reportagain">再次测试</div>
		</div>

		<div class="notice" v-if="showinfo" @click="showinfo=!showinfo">

		</div>
		<div class="notice-box" v-if="showinfo&&reportinfoway==1">
			<div class="notice-title"><img class="closeit" @click="showinfo=!showinfo" src="../../public/imgs/close.png"
					alt=""></div>
			<div class="notice-msg">
				<div class="msg-line" v-html="reportinfo.content"></div>
			</div>
		</div>
		<div class="notice-box" v-if="showinfo&&reportinfoway==2">
			<div class="notice-title">{{reportinfoname}}的研究报告<img class="closeit" @click="showinfo=!showinfo"
					src="../../public/imgs/close.png" alt=""></div>
			<div class="notice-msg">
				<div class="msg-line" v-for="(item,index) in reportlist" :key="index">
					<div class="rline-name">{{item.title}}</div>
					<div class="rline-intro">{{item.intro}}</div>
					<div class="gordetail cursor" @click="goshowdetails(item.id)">详细研究报告</div>
				</div>
			</div>
		</div>
		<div class="notice-box notice-box2" v-if="showinfo&&reportinfoway==2&&showtwo==true">
			<div class="notice-title"><img class="closeit" @click="showtwo=!showtwo" src="../../public/imgs/close.png"
					alt=""></div>
			<div class="notice-msg">
				<div class="msg-line" v-html="reportinfo.content"></div>
			</div>
		</div>
		<div class="lodding-box" v-if="showlodding">
			<div class="loadingmain" id="loading">
				<div class="loadEffect">
					<div class="loadimg">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/rec_generating.gif"
							alt="">
						<div class="progressnum">{{progressnum}}%</div>
					</div>
					<div class="txts">
						<transition name="slide-fade">
							<p v-if="show1">同态加密处理</p>
						</transition>
						<transition name="slide-fade">
							<p v-if="show2">深度算法处理</p>
						</transition>
						<transition name="slide-fade">
							<p v-if="show3">正在生成报告</p>
						</transition>
					</div>
					<div class="reportv">算法版本号：V301.237</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import checkIsInApp from '../util/app.js'
	export default {
		name: 'report',
		components: {},
		data: () => ({
            modalReportInfo:false,
            modalBMIInfo:false,
			time: '2020/12/12',
			id: '',
			report: '',
			show1: false,
			show2: false,
			show3: false,
			indexdata: '',
			showlodding: true,
			showtwo: false,
			reportinfoname: '',
			reportinfoway: 1,
			hasshow: false,
			reportlist: '',
			progressnum: 0,
			home_img: '',
			yimg: '',
			bimg: '',
			reportinfo: '',
			showinfo: false,
			info: '',
            blur:false,
			imgurl: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/Banner/images/20200806/2edf2abb4d60a65591d74dc58d66a43f.jpg'
		}),
		watch: {
			$route() {
				// let reportmain = this.$refs.reportmain
				// reportmain.scrollTo(0, 0)
			}
		},
		mounted() {

            if(checkIsInApp()){
                this.blur = true;
            }

            setTimeout(() => {
                this.blur = false
            }, 3000)

			//console.log(this.$route.path,history.length)
			var that = this
			var info = JSON.parse(localStorage.getItem('info'))
			var hasshow = localStorage.getItem('hasshow') ? localStorage.getItem('hasshow') : ''
			if (!info) {
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login'
				})
			}
			var indexdata = JSON.parse(localStorage.getItem('indexdata'))
			this.indexdata = indexdata
			// //console.log(indexdata)
			if (indexdata) {
				var home_img = indexdata.home_img
				this.home_img = home_img
				home_img.forEach((item) => {
					if (item.image_no == '301') {
						that.yimg = item.url
					} else if (item.image_no == '302') {
						that.bimg = item.url
					}
				})
			}

			if (that.$route.query.way && that.$route.query.way == 'list') {
				that.showlodding = false
			} else {
				if (hasshow == that.$route.query.id) {
					//console.log('展示过了')
					that.showlodding = false
				} else {
					var progressnum = that.progressnum
					var timer = setInterval(function() {
						if (progressnum < 100) {
							progressnum++
							that.progressnum = progressnum
						}
					}, 50)
					var txtnum = 0
					var timer1 = setInterval(function() {
						// setTimeout(function(){
						that.show1 = false
						that.show2 = false
						that.show3 = false
						// },200)
						if (txtnum == 0 || txtnum == 3) {
							setTimeout(function() {
								that.show1 = true
								that.show2 = false
								that.show3 = false
							}, 300)
						} else if (txtnum == 1 || txtnum == 4) {
							setTimeout(function() {
								that.show1 = false
								that.show2 = true
								that.show3 = false
							}, 300)
						} else if (txtnum == 2 || txtnum == 5) {
							setTimeout(function() {
								that.show1 = false
								that.show2 = false
								that.show3 = true
							}, 300)
						}
						txtnum++
					}, 1000)

					setTimeout(function() {
						clearInterval(timer);
						clearInterval(timer1);
						txtnum = 0
						that.show1 = true
						that.showlodding = false
						// that.hasshow = true
						localStorage.setItem('hasshow', that.$route.query.id)
						let reportmain = that.$refs.reportmain
						reportmain.scrollTo(0, 0)
						// if(that.$route.query.way=='get_my_report'){
						// 	//console.log('123')
						// }else{
						// 	location.reload();
						// }
						// location.reload();
					}, 5000)
				}
			}
			// //console.log(hasshow)

			that.info = info
			//console.log(this.$route.query)
			this.id = this.$route.query.id

			if (this.$route.query.way == 'get_my_report') {

				that.goreportdetail(this.$route.query.id)
			} else {
				this.$ajax.post(this.API.api.apiurl.getCountInfoNew, {
						count_id: that.$route.query.id,
						member_id: that.info.member_id,
						// count_id: '5636',
					}, {
						headers: {
							'Authorization': 'Bearer ' + that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
							var data = res.data.data
							var analysis_list = data.analysis_list
							var select_re = data.select_re
							var othertxt = ''
							for (var k = 0; k < select_re.length; k++) {
								var extend = select_re[k].extend
								extend.forEach((item, index) => {
									// //console.log(index)
									extend[index].txt = that.changww(that.gettxt(item.reason), item.report)
									othertxt += item.reason
								})
								select_re[k].extend = extend
							}
							data.select_re = select_re
							data.special.text = that.changweight(data.special.text)
							if (data.special.type == 1 || data.special.type == 2 || data.special.type == 3) { //孕期
								var ylist = data.pregnancy_re_goods
								var nylist = data.non_pregnancy_re_goods
								var yuntxt = ''
								var nyuntxt = ''
								for (var a = 0; a < ylist.length; a++) {
									var yextend = ylist[a].extend
									yextend.forEach((item, index) => {
										yuntxt += item.reason
										yextend[index].txt = that.changww(that.gettxt(item.reason), item
											.report)
									})
									ylist[a].extend = yextend
								}
								for (var b = 0; b < nylist.length; b++) {
									var yextends = nylist[b].extend
									yextends.forEach((item, index) => {
										nyuntxt += item.reason
										yextends[index].txt = that.changww(that.gettxt(item.reason), item
											.report)
									})
									nylist[b].extend = yextends
								}
								data.pregnancy_re_goods = ylist
								data.non_pregnancy_re_goods = nylist
								// //console.log(data)
								data.special.yuntxt = that.changweight(that.gettxt(yuntxt))
								data.special.nyuntxt = that.changweight(that.gettxt(nyuntxt))
							}
							var tabnum = 0
							var tag_list = data.tag_list
							var tlist = []
							for (var p = 0; p < tag_list.length; p++) {
								var tags = tag_list[p].tag
								tabnum += tags.length
								if (tag_list[p].tag.length == 0) {
									//console.log('notag')
								} else {
									tlist.push(tag_list[p])
								}
							}
							data.tlist = tlist
							data.tabnum = tabnum
							data.othertxt = that.changww(that.gettxt(othertxt))
							var anallist = []
							for (var i = 0; i < analysis_list.length; i++) {
								var list = analysis_list[i].style_list
								var goods_re = analysis_list[i].goods_re
								var txt1 = ''
								goods_re.forEach((item, index) => {
									//console.log(index)
									goods_re[index].txt = that.changww(that.gettxt(item.reason), item.report)
									txt1 += item.reason
								})
								// analysis_list[i].txt = that.changweight(that.gettxt(txt1))
								analysis_list[i].txt = txt1
								for (var j = 0; j < list.length; j++) {
									var txt = ''
									list[j].copywriting_list.copywriting_content.forEach((item) => {
										//console.log(index)
										txt += item
									})
									list[j].txt = that.changweight(txt)
								}
								analysis_list[i].style_list = list
								if (analysis_list[i].goods_re.length == 0 && analysis_list[i].tag.length == 0 &&
									analysis_list[i].style_list.length == 0) {
									//console.log('no')
								} else {
									anallist.push(analysis_list[i])
								}
							}
							// data.analysis_list = analysis_list
							data.analysis_list = anallist
							// //console.log(data)
							that.report = data
						} else if (res.data.code == '无效报告！') {
							that.$router.replace({
								path: '404'
							})
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
            this.goToBuy();
		},
		methods: {
            checkIsInApp(){
                return checkIsInApp();
            },
            goToBuy(){
				//this.$Message.info('正在前往微信小程序查看完整报告....');
                var id = this.$route.query.id;
                var wechatUrl = "https://api.xiaojudai.cn/api/s/12?count_id="+id;
                if(this.blur){
                    wechatUrl = "https://api.xiaojudai.cn/api/s/13?count_id="+id;
                }
                window.ReactNativeWebView.postMessage(JSON.stringify({type:"openUrl", url:wechatUrl}))
            },
			goreportdetail(id) {
				var that = this
				this.$ajax.post(this.API.api.apiurl.getCountInfoNew, {
						count_id: id,
						member_id: that.info.member_id,
						// count_id: '5636',
					}, {
						headers: {
							'Authorization': 'Bearer ' + that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
							var data = res.data.data
							var analysis_list = data.analysis_list
							var select_re = data.select_re
							var othertxt = ''
							for (var k = 0; k < select_re.length; k++) {
								var extend = select_re[k].extend
								extend.forEach((item, index) => {
									// //console.log(index)
									extend[index].txt = that.changww(that.gettxt(item.reason), item.report)
									othertxt += item.reason
								})
								select_re[k].extend = extend
							}
							data.select_re = select_re
							data.special.text = that.changweight(data.special.text)
							if (data.special.type == 1 || data.special.type == 2 || data.special.type == 3) { //孕期
								var ylist = data.pregnancy_re_goods
								var nylist = data.non_pregnancy_re_goods
								var yuntxt = ''
								var nyuntxt = ''
								for (var a = 0; a < ylist.length; a++) {
									var yextend = ylist[a].extend
									yextend.forEach((item, index) => {
										yuntxt += item.reason
										yextend[index].txt = that.changww(that.gettxt(item.reason), item
											.report)
									})
									ylist[a].extend = yextend
								}
								for (var b = 0; b < nylist.length; b++) {
									var yextends = nylist[b].extend
									yextends.forEach((item, index) => {
										nyuntxt += item.reason
										yextends[index].txt = that.changww(that.gettxt(item.reason), item
											.report)
									})
									nylist[b].extend = yextends
								}
								data.pregnancy_re_goods = ylist
								data.non_pregnancy_re_goods = nylist
								// //console.log(data)
								data.special.yuntxt = that.changweight(that.gettxt(yuntxt))
								data.special.nyuntxt = that.changweight(that.gettxt(nyuntxt))
							}
							var tabnum = 0
							var tag_list = data.tag_list
							for (var p = 0; p < tag_list.length; p++) {
								var tags = tag_list[p].tag
								tabnum += tags.length
							}
							data.tabnum = tabnum
							data.othertxt = that.changww(that.gettxt(othertxt))
							var anallist = []
							for (var i = 0; i < analysis_list.length; i++) {
								var list = analysis_list[i].style_list
								var goods_re = analysis_list[i].goods_re
								var txt1 = ''
								goods_re.forEach((item, index) => {
									//console.log(index)
									goods_re[index].txt = that.changww(that.gettxt(item.reason), item.report)
									txt1 += item.reason
								})
								// analysis_list[i].txt = that.changweight(that.gettxt(txt1))
								analysis_list[i].txt = txt1
								for (var j = 0; j < list.length; j++) {
									var txt = ''
									list[j].copywriting_list.copywriting_content.forEach((item) => {
										//console.log(index)
										txt += item
									})
									list[j].txt = that.changweight(txt)
								}
								analysis_list[i].style_list = list
								if (analysis_list[i].goods_re.length == 0 && analysis_list[i].tag.length == 0 &&
									analysis_list[i].style_list.length == 0) {
									//console.log('no')
								} else {
									anallist.push(analysis_list[i])
								}
							}
							// data.analysis_list = analysis_list
							data.analysis_list = anallist
							// //console.log(data)
							that.report = data
						} else if (res.data.code == '无效报告！') {
							that.$router.replace({
								path: '404'
							})
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			changweight(e) {
				let _reg = /\$\$[\D\d]*?\$\$/g;
				var str = e
				let str1 = str.replace(_reg, function(v) {
					return '<span><b>' + v.substr(2, v.length - 4) + '</b></span>';
				})
				return str1
			},
			reportagain() {
				localStorage.setItem('goq', 1)
				this.$router.push({
					path: 'home'
				})
			},
			goshowdetail(id, name) {
				var that = this
				that.reportinfoname = name
				// //console.log(id)
				if (id.length == 1) {
					this.$ajax.post(this.API.api.apiurl.getReportInfo, {
							id: id[0],
						})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								// //console.log(data)
								that.reportinfoway = 1
								that.reportinfo = data.data
								that.showinfo = true
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					that.getreportlist(id)
				}
			},
			goshowdetails(id) {
				var that = this
				this.$ajax.post(this.API.api.apiurl.getReportInfo, {
						id: id,
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							// //console.log(data)
							that.reportinfoway = 2
							that.reportinfo = data.data
							that.showtwo = true
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getreportlist(id) {
				var that = this
				// //console.log(id)
				this.$ajax.post(this.API.api.apiurl.getReportList, {
						report_ids: id,
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							// //console.log(data)
							that.reportinfoway = 2
							that.reportlist = data.data
							that.showinfo = true
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			changww(e, id) {
				var arr = e.split("$$")
				// //console.log(arr)
				// var l = arr.length % 2
				// //console.log(l)
				var t = []

				arr.forEach((item, index) => {
					var it = false
					if (index % 2 == 1) {
						it = true
					} else {
						it = false
					}
					t[index] = {
						value: item,
						id: id,
						it: it
					}
				})
				// //console.log(t)
				return t
			},
			gettxt(e) {
				var arr = e.split("##")
				var txt = ''
				for (var i = 0; i < arr.length; i++) {
					txt += i == 0 ? arr[i] : '$$' + arr[i]
				}
				return txt
			},
			godetail() {
				var that = this
				this.$router.push({
					path: 'report-detail',
					query: {
						id: that.report.count_info.id
					}
				})
			}
		}
	}
</script>

<style>
	.report {
		width: 100%;
		max-width: 22.78rem;
		margin: 0 auto;
		padding-bottom: 2.5rem;
		/* padding:0 1.9rem; */
	}

	.report .reportbox {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 auto;
	}

	.reportbox .reporttitle {
		width: 96%;
		max-width: 22.78rem;
		margin: 0 auto;
		height: auto;
		text-align: left;
		padding: 0.8rem 0 0.59rem 0;
		background-image: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/report-bg.png);
		background-repeat: no-repeat;
		background-position: 100% 70%;
		background-size: 10rem 5.06rem;
	}

	.reporttitle .reportname {
		width: auto;
		height: 0.83rem;
		line-height: 0.83rem;
		font-size: 0.59rem;
		color: #1A1A1A;
		font-weight: bold;
		display: flex;
	}

	.reporttitle .reporttime {
		width: auto;
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		margin-top: 0.3rem;
	}

	.personinfo {
		width: 96%;
		height: auto;
		margin: 0 auto;
		padding: 0.59rem;
		background: #ffffff;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
	}

	.info-topbox {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		border-bottom: 0.04rem solid #000000;
		padding-bottom: 0.89rem;
		margin-bottom: 0.59rem;
	}

	.info-topbox .user {
		width: auto;
		height: 1.33rem;
		display: flex;
	}

	.user img {
		width: 1.33rem;
		height: 1.33rem;
		border-radius: 1.33rem;
		overflow: hidden;
		background: #EEEEEE;
	}

	.user .name-module {
		width: auto;
		height: 1.33rem;
		color: #1A1A1A;
		text-align: left;
		margin-left: 0.3rem;
	}

	.name-module .username {
		height: 0.61rem;
		font-size: 0.44rem;
		font-weight: bold;
	}

	.name-module .usermodule {
		height: 0.52rem;
		font-size: 0.37rem;
		margin-top: 0.2rem;
	}

	.userinfo {
		width: 7rem;
		height: 1.33rem;
		display: flex;
		justify-content: space-between;
	}

	.userinfo .userinfo-box {
		width: auto;
		height: 1.33rem;
		text-align: center;
	}

	.userinfo-box .info-name {
		width: auto;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #666666;
	}

	.userinfo-box .info-value {
		width: auto;
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.44rem;
		color: #1A1A1A;
		font-weight: bold;
		margin-top: 0.24rem;
	}

	.user-report {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.user-report .user-body {
		width: 100%;
		height: 9.09rem;
		position: relative;
		display: flex;
	}

	.user-body .bodyimg {
		width: auto;
		height: 8rem;
		margin: 0 auto;
		margin-top: 2rem;
	}

	.report-mode {
		width: 4rem;
		height: auto;
		position: absolute;
	}

	.report-mode .txt {
		width: 3rem;
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.3rem;
		text-align: left;
		color: #787878;
	}

	.report-mode .notxt {
		width: 3rem;
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.3rem;
		text-align: left;
		color: #1A1A1A;
		font-weight: bold;
	}

	.model2 .txt,
	.model3 .txt {
		text-align: right;
	}

	.model2 .model-box,
	.model3 .model-box {
		width: 2rem;
		margin-left: 1.5rem;
	}

	.model-line {
		margin-top: 0.3rem;
		width: 3.5rem;
		height: 0.02rem;
		background-color: #979797;
		position: relative;
	}

	.model-box {
		width: 100%;
		display: flex;
		height: 0.73rem;
		text-align: left;
		margin-top: 0.11rem;
	}

	.model-box img {
		width: 0.73rem;
		height: 0.73rem;
	}

	.model-box .model-name {
		width: auto;
		height: 0.73rem;
		line-height: 0.73rem;
		margin-left: 0.13rem;
		font-size: 0.3rem;
		color: #1A1A1A;
		font-weight: bold;
	}

	.model1 {
		bottom: 0;
		left: calc(50% - 5.5rem);
	}

	.model2 {
		top: 1rem;
		left: calc(50% + 2.5rem);
		/* width: 2.1rem; */
	}

	.model3 {
		bottom: 0;
		left: calc(50% + 2.5rem);
		/* width: 2.1rem; */
	}

	.model2 .model-line,
	.model3 .model-line {
		margin-left: -0.4rem;
	}

	.model1 .model-line::after {
		content: "";
		position: absolute;
		height: 0.02rem;
		width: 0.5rem;
		right: -0.5rem;
		bottom: 0;
		background-color: #979797;
		transform: rotate(306deg);
		transform-origin: left;
	}

	.model2 .model-line::after {
		content: "";
		position: absolute;
		height: 0.02rem;
		width: 0.5rem;
		left: -0.3rem;
		bottom: -0.4rem;
		background-color: #979797;
		transform: rotate(306deg);
		transform-origin: left;
	}

	.model3 .model-line::after {
		content: "";
		position: absolute;
		height: 0.02rem;
		width: 0.5rem;
		left: 0;
		bottom: 0;
		background-color: #979797;
		transform: rotate(240deg);
		transform-origin: left;
	}

	.report-msg {
		width: 96%;
		max-width: 13.76rem;
		height: auto;
		margin: 0 auto;
		position: relative;
	}

	.tline {
		width: 100%;
		height: auto;
		margin-top: 0.6rem;
	}

	.msg-title {
		width: 100%;
		height: auto;
		line-height: 0.74rem;
		font-size: 0.52rem;
		color: #1A1A1A;
		text-align: left;
		font-weight: bold;
	}

	.title-style {
		position: relative;
		z-index: 2;
		/* color: #ffffff; */
	}

	.title-style::before {
		content: "";
		position: absolute;
		bottom: 0.05rem;
		width: 100%;
		height: 0.16rem;
		background: rgba(194, 94, 77, 1);
		left: 0.05rem;
		z-index: -1;
	}

	.title-style::after {
		/* content: "你存在以下问题"; */
		position: absolute;
		font-size: 0.52rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: bold;
		color: rgba(20, 20, 20, 1);
		line-height: 0.65rem;
		left: 0;
		top: 0;
		z-index: 1;
	}

	.title2 {
		width: auto;
		display: flex;
	}

	.msg-detail {
		margin-top: 1.19rem;
		margin-bottom: 1.2rem;
		line-height: 0.67rem;
		font-size: 0.37rem;
		text-align: left;
	}

	.msg-detail p {
		color: #1A1A1A;
	}

	.msg-detail span {
		color: #1A1A1A;
		font-weight: bold;
	}

	.godetail {
		width: 4.56rem;
		border-radius: 1.48rem;
		height: 1.48rem;
		line-height: 1.48rem;
		background: #C25E4D;
		color: #ffffff;
		font-size: 0.52rem;
		text-align: center;
		position: absolute;
		bottom: 0;
		right: 0;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.24);
	}

	.moduleinfo {
		width: 100%;
		margin-bottom: 2.5rem;
	}

	.moduleinfo .report-detail {
		width: 96%;
		height: auto;
		margin: 0 auto;
		padding: 0.59rem;
		background: #ffffff;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		margin-top: 1.19rem;
	}

	.report .detail-title {
		width: 100%;
		height: 1.11rem;
		border-bottom: 0.04rem solid #000000;
		display: flex;
		justify-content: space-between;
	}

	.detail-title .title-name {
		width: auto;
		height: 0.74rem;
		font-size: 0.52rem;
		line-height: 0.74rem;
		color: #1a1a1a;
		font-weight: bold;
	}

	.detail-title .title-icon {
		width: 0.89rem;
		height: 0.89rem;
	}

	.title-icon img {
		width: 100%;
		height: 100%;
	}

	.main-detail {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 1.19rem;
	}

	.detail-list {
		width: 100%;
		height: auto;
	}

	.nogoods {
		margin: 0 auto;
	}

	.list-box {
		width: 100%;
		height: auto;
		display: flex;
		margin-bottom: 1.19rem;
	}

	.list-box img {
		width: 1.11rem;
		height: 1.11rem;
		border-radius: 1.11rem;
		background: #efefef;
	}

	.list-box .list-msg {
		width: calc(100% - 2.82rem);
		height: auto;
		line-height: 0.7rem;
		font-size: 0.3rem;
		color: #666666;
		text-align: left;
		margin-left: 0.3rem;
	}

	.list-msg span {
		color: #1A1A1A;
		font-weight: bold;
	}

	.list-msg .tabs {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
	}

	.tabs .tab {
		width: auto;
		height: 0.78rem;
		line-height: 0.78rem;
		font-size: 0.3rem;
		text-align: center;
		padding: 0 0.44rem;
		border-radius: 0.78rem;
		color: #1A1A1A;
		background: rgba(0, 0, 0, 0.07);
		margin: 0 0.3rem 0.3rem 0;
	}

	.adviser-detail {
		width: 100%;
		max-width: 10.09rem;
		height: auto;
		margin: 0 auto;
	}

	.adviser-detail1 {
		width: 100%;
		height: auto;
		margin: 0 auto;
	}

	.adviser-detail .adviser-img {
		width: 2.59rem;
		height: 2.59rem;
		border-radius: 2.59rem;
		background: #efefef;
		overflow: hidden;
		margin: 0 auto;
	}

	.adviser-detail .adviser-box {
		width: 100%;
		height: auto;
		background: rgba(200, 208, 216, 0.5);
		border-radius: 0.19rem;
		margin-top: -1.295rem;
		padding: 1.445rem 0.56rem 0.56rem 0.56rem;
	}

	.adviser-box .adviser-name {
		width: 100%;
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.44rem;
		color: #1A1A1A;
		text-align: center;
	}

	.adviser-position {
		width: 100%;
		height: 0.41rem;
		font-size: 0.3rem;
		line-height: 0.41rem;
		text-align: center;
		color: #1A1A1A;
		margin-top: 0.07rem;
	}

	.adviser-msg {
		width: 100%;
		height: auto;
		color: #1A1A1A;
		line-height: 0.93rem;
		font-size: 0.37rem;
		text-align: left;
	}

	.adviser-msg span {
		color: #1A1A1A;
		font-weight: bold;
		text-decoration: underline;
		cursor: pointer;
	}

	.adviser-imgs {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
		margin: 0.59rem 0;
		flex-wrap: wrap;
	}

	.adviser-imgs img {
		max-width: 1.6rem;
		max-height: 1.6rem;
	}

	.adviser-txt {
		width: 100%;
		height: auto;
		line-height: 0.37rem;
		text-align: center;
		font-size: 0.26rem;
		color: #898989;
		padding: 0 19.5%;
		margin-top: 0.59rem;
	}

	.adviser-txts {
		width: 100%;
		height: auto;
		line-height: 0.37rem;
		text-align: center;
		font-size: 0.26rem;
		color: #898989;
		margin-top: 0.59rem;
	}

	.bottom-box {
		width: 100%;
		height: 2.4rem;
		position: fixed;
		left: 0;
		bottom: 0;
		/* box-shadow: 0rem -0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1); */
		padding: 0.48rem 0;
		/* background: #ffffff; */
		z-index: 7;
	}

	.bottom-btn {
		width: 6.11rem;
		height: 1.48rem;
		font-size: 0.52rem;
		color: #ffffff;
		text-align: center;
		line-height: 1.48rem;
		border-radius: 1.48rem;
		background: #C25E4D;
		margin: 0 auto;
	}

	.nogoodstxt {
		width: calc(100% - 2.82rem);
		height: auto;
		margin: 0 auto;
		line-height: 0.7rem;
		font-size: 0.3rem;
		color: #666666;
		text-align: left;
		margin-bottom: 1.19rem;
	}

	.nogoodstxt span {
		color: #1A1A1A;
		font-weight: bold;
	}

	.specialtext {
		width: 100%;
		height: auto;
		color: #1A1A1A;
		line-height: 0.67rem;
		font-size: 0.37rem;
		text-align: left;
	}

	.specialtext span {
		font-weight: bold;
	}

	.yimg {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
		margin-bottom: 0.4rem;
	}

	.yimg img {
		width: 3rem;
		height: 3rem;
	}

	.nouse {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin-top: 0.5rem;
	}

	.nouse img {
		width: 0.5rem;
		height: 0.5rem;
	}

	.no-txt {
		width: 90%;
		height: auto;
		font-size: 0.3rem;
		text-align: left;
	}

	.adviser-txts {
		text-align: left;
		color: #1A1A1A;
		font-size: 0.37rem;
		line-height: 0.67rem;
		margin-bottom: 0.59rem;
	}

	.adviser-txts span {
		font-weight: bold;
		color: #1A1A1A;
	}

	.margintop {
		margin-top: 0.59rem;
	}

	.adviser-msg .reportqusimg {
		display: inline-block;
		width: 0.36rem;
		height: 0.36rem;
		border-radius: 0.41rem;
		border: 0.01rem solid #333333;
		text-align: center;
		line-height: 0.36rem;
		font-size: 0.26rem;
		font-weight: 300;
	}

	.adviser-msg div {
		display: inline;
	}

	.report .notice-box .notice-msg {
		height: 96%;
	}

	.report .notice-box .notice-msg::-webkit-scrollbar {
		display: none;
	}

	.report .notice-box .notice-title {
		height: auto;
	}

	.rline-name {
		width: 100%;
		height: auto;
		line-height: 0.8rem;
		font-size: 0.46rem;
		color: #1A1A1A;
		text-align: left;
		font-weight: bold;
		margin-top: 0.59rem;
		border-bottom: 0.02rem solid #333333;
		margin-bottom: 0.2rem;
	}

	.rline-intro {
		line-height: 0.46rem;
		font-size: 0.37rem;
	}

	.gordetail {
		width: 100%;
		height: auto;
		line-height: 0.6rem;
		font-size: 0.32rem;
		text-align: right;
		color: #C25E4D;
		margin-top: 0.2rem;
	}

	.notice-box2 {
		background: #FFFFFF;
	}

	@media screen and (max-width:1170px) {
		.user-report {
			justify-content: center;
		}

		.report-msg {
			width: 9rem;
			margin-top: 0.59rem;
		}

		.godetail {
			left: 50%;
			margin-left: -2.28rem;
		}

		.main-detail {
			justify-content: center;
		}

		.list-box .list-msg {
			width: calc(100% - 1.5rem);
		}
	}

	@media screen and (max-width:560px) {
		.userinfo {
			width: 3.5rem;
		}
	}

	@media screen and (max-width:660px) {

		.model2 .model-box,
		.model3 .model-box {
			width: 2.6rem;
			margin-left: 0;
			display: flex;
			justify-content: flex-end;
		}

		.model2,
		.model3,
		.model1 {
			width: 2.4rem;
		}

		.model-line {
			width: 3rem;
		}

		.report-mode .txt {
			width: 2rem;
			overflow: hidden;
			height: auto;
		}

		.model2,
		.model3 {
			left: auto;
			right: 0;
		}

		.model2 .txt,
		.model3 .txt {
			width: 2.6rem;
			margin-left: 0;
		}

		.model1 {
			left: 0;
		}
	}

	@media screen and (max-width:1000px) {
		.reporttitle .reportname {
			/* width: 50%; */
			height: auto;
			display: block;
		}

		.info-topbox {
			display: block;
			padding-bottom: 0;
			margin-bottom: 0.19rem;
		}

		.userinfo {
			width: 100%;
			margin-top: 0.59rem;
		}

		.userinfo-box .info-value {
			margin-top: 0;
		}

		.nogoodstxt {
			width: 100%;
			font-size: 0.37rem;
		}

		.list-box .list-msg {
			font-size: 0.37rem;
		}

		.adviser-txt {
			padding: 0 10%;
		}
	}
    .go-to-buy{
        margin:0 auto !important;
    }
    .btns{
        z-index:10000;
    }
    .report-blur {
        filter: blur(5px);
    }

</style>
