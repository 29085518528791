<template>
  <div class="btns" v-if="checkIsInApp">
    <button class="go-back"  @click="goBack">返回</button>
  </div>
</template>

<script>
import checkIsInApp from '../util/app.js'
export default {
  name: "ReturnBtn",
  methods: {
    checkIsInApp() {
        return checkIsInApp();
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style scoped>

.btns,.go-back {
    z-index:10000;
}

.go-back {
    margin: 0 auto;
}

</style>
