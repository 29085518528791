<template>
	<div class="notFound">
		<div class="notfoundbg">
			<img src="../../public/imgs/405.png" alt="">
			<div class="nofoundtxt">很抱歉，页面它不小心迷路了~</div>
			<div class="goback cursor" @click="gohome">返回首页</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'notFound',
		components:{
		},
		data:()=>({
		}),
		mounted() {
		},
		methods:{
			gohome(){
				this.$router.push({
					path:'/'
				})
			}
		},
	}
</script>

<style>
	.notfoundbg{
		width: 100%;
		height: 100%;
		background: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/404bg.png);
		background-repeat: no-repeat;
		background-size: 15.28rem 10.31rem ;
		background-position: right bottom;
	}
	.notFound{
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 16;
	}
	.notFound img{
		width: 4.81rem;
		height: 2.69rem;
		margin-top: 10%;
	}
	.nofoundtxt{
		width: 100%;
		height: 0.52rem;
		font-size: 0.37rem;
		color: #666666;
		text-align: center;
		margin-top: 0.3rem;
	}
	.goback{
		width: 1.5rem;
		height: 0.52rem;
		font-size: 0.37rem;
		margin: 0 auto;
		color: #C25E4D;
		text-align: center;
		margin-top: 0.54rem;
		font-weight: 400;
	}
</style>
