<template>
	<div class="about">
		<div class="pc_box">
			<div class="about-banner">
				<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/about/bg.png" alt="">
				<div class="about-banner-txt">
					<div class="about-banner-title">NUTRIPACKS 坚信</div>
					<div class="about-banner-line"></div>
					<p>每个人都是独特的个体</p>
					<p>追寻各自崇尚的生命价值拥有健康的身体和心态</p>
					<p>会让这个过程变得更积极而主动</p>
					<p>我们愿用营养和科技帮助你</p>
					<p>成为更好的自己</p>
				</div>
			</div>
			<div class="about-name">
				<div class="about-name-title">企业介绍</div>
				<div class="about-name-msg">ABOUT NUTRIPACKS</div>
				<div class="about-name-line"></div>
			</div>
			<div class="about-main1 about-main">
				<div class="about-main-box" style="">
					<div class="about-main-msg" style="margin-bottom: 12px;">2019年，NUTRIPACKS旗下个人定制健康服务品牌</div>
					<div class="about-main-msg">NUTRIPACKS橘袋正式登陆中国，以跨境电商+个性化膳食营养补充剂专属测评定制的模式服务大众消费者，旨意把国外科学的健康理念和产品带给国内的用户。通过完成在线专业营养评测，即可获得专属每日一袋，专为个人定制的高品质膳食营养补充剂方案。</div>
				</div>
				<div class="about-main1-img about-main-img">
					<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/about/about1.jpg" alt="">
				</div>
			</div>
			<div class="about-name">
				<div class="about-name-title">国内合作方</div>
				<div class="about-name-msg">OUR PARTNERS</div>
				<div class="about-name-line"></div>
			</div>
			<div class="about-main2 about-main">
				<div class="about-main2-img about-main-img">
					<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/about/about2.jpg" alt="">
				</div>
				<div class="about-main-box" style="">
					<div class="about-main-msg">源自美国纽约的NUTRIPACKS，创立之初便汇集了医疗大数据专家、云计算专家、资深营养学专家、营养配方学专家等，凭借15年以上营养研发智慧，深度结合现代营养科学与前沿数字科技，面向企业和个人提供高品质的创新健康服务。</div>
					<div class="about-main-msg">作为中国首批在医疗供应服务的先驱者——老肯医疗（股票代码：834611），经过二十余年的发展具有良好市场口碑的基础上，于2008年成立国际贸易部，积极实施“走出去”的战略，将老肯产品推向世界的同时，也引进国外先进的医疗健康服务和产品。</div>
					<div class="about-main-msg">2019年，美国NUTRIPACKS携手老肯医疗，将旗下NUTRIPACKS橘袋全新健康定制型营养品牌带入中国。专注研究现代营养科学，致力打造国内领先的数字化健康管理服务科技公司。</div>
				</div>
			</div>
			<div class="about-name">
				<div class="about-name-title">荣誉团队</div>
				<div class="about-name-msg">OUR TEAM</div>
				<div class="about-name-line"></div>
			</div>
			<div class="about-main3 about-main">
				<div class="about-main-box">
					<div class="about-main-msg" style="width: 432px;">团队由资深营养学家、自然疗法医生，生物化学博士，营养配方学家、食品安全学家及数据算法工程专家等共同组成。</div>
				</div>
				<div class="about-main3-img about-main-img">
					<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/about/about3.jpg" alt="">
				</div>
			</div>
			<div class="about-bottom">
				<div class="about-bottom-title">
					跨境电商
				</div>
				<div class="about-bottom-msg">
					<div class="about-bottom-line"></div>
					<div class="about-bottom-msg-txt">CROSS-BORDER E-COMMERCE</div>
					<div class="about-bottom-line"></div>
				</div>
				<div class="about-bottom-txt">
					<p>美国原装进口 / 海关监管 / 每一颗NUTRIPACKS橘袋从生产到销售均经过严格检测</p>
					<p>跨境电商是两个不同国家之间商品通过正规的贸易规定进入到对方国家进行销售，经营者必须在该国家登记并取得合法的《工商营业执照》《食品经营许可证》，产品进入该国必须严格接受海关检验检疫后并存放于保税区内，消费者购买是凭借身份证直接于官方或其他平台购买。出关再次对身份和产品进行检验核对后，方可出关放行到达消费者手中。</p>
				</div>
				<div class="about-bottom-tabs">
					<div class="about-bottom-tab">
						<div class="about-bottom-img">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/about/a3.png" alt="">
						</div>
						<div class="about-bottom-tab-txt">美国生产直供</div>
					</div>
					<div class="about-bottom-tab">
						<div class="about-bottom-img">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/about/a4.png" alt="">
						</div>
						<div class="about-bottom-tab-txt">国际航空国际海运</div>
					</div>
					<div class="about-bottom-tab">
						<div class="about-bottom-img">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/about/a2.png" alt="">
						</div>
						<div class="about-bottom-tab-txt">海关监控保税仓储存</div>
					</div>
					<div class="about-bottom-tab">
						<div class="about-bottom-img">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/about/a1.png" alt="">
						</div>
						<div class="about-bottom-tab-txt">国内配送闪电发货</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mb_box"></div>
	</div>
</template>

<script>
	export default {
		name: 'about',
		components: {},
		data: () => ({
			data1: '',
			publicurl:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/',
		}),
		mounted() {},
	}
</script>

<style>
	/* pc */
	.about-bottom-tabs{
		width: 900px;
		margin: 0 auto;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin-top: 115px;
	}
	.about-bottom-tab{
		width: 133px;
		height: auto;
	}
	.about-bottom-tab .about-bottom-img{
		width: 133px;
		height: 133px;
		display: flex;
		align-items: center;
	}
	.about-bottom-img img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	.about-bottom-tab-txt{
		width: 100%;
		text-align: center;
		font-size: 12px;
	}
	.about-bottom-txt{
		width: 812px;
		margin: 0 auto;
		margin-top: 35px;
		line-height: 24px;
		font-size: 14px;
		text-align: center;
	}
	.about-bottom-msg{
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
		margin-top: 15px;
	}
	.about-bottom-msg-txt{
		font-size: 16px;
		line-height: 21px;
		color: #afbfcc;
	}
	.about-bottom-line{
		width: 83px;
		height: 1px;
		background: #afbfcc;
		margin: 10px 20px;
	}
	.about-bottom-title{
		width: 100%;
		font-size: 40px;
		line-height: 40px;
		text-align: center;
		font-weight: bold;
	}
	.about-bottom{
		margin-top: 160px;
		padding-top: 115px;
		padding-bottom: 105px;
		width: 100%;
		height: auto;
		background: rgba(61, 133, 198, 0.1);
	}
	.about-main{
		display: flex;
		align-items: center;
		margin-top: 85px;
	}
	.about-main-img{
		width: 50%;
		display: flex;
		align-items: center;
		overflow: hidden;justify-content: center;
	}
	.about-main-img img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	.about-main-msg{
		margin: 0 auto;
				text-align: justify;
	}
	.about-main1 .about-main-msg{
		width: 441px;
		margin: 0 auto;
	}
	.about-main2{
		height: 875px;
	}
	.about-main2 .about-main-msg{
		width: 490px;
	}
	.about-main2-img{
		height: 875px;
	}
	
	
	.about-main-box{
		width: 50%;
		align-items: center;
		text-align: left;
	}
	.about-main-txt{
		font-size: 18px;
		line-height: 30px;
		color: #829999;
	}
	.about-main-msg{
		font-size: 18px;
		line-height: 32px;
		color: #829999;
	}
	.about-main1{
		width: 100%;
		height: 658px;
		display: flex;
		justify-content: baseline;
		margin-top: 85px;
	}
	.about-main1 .about-main1-img{
		width: 50%;
		height: 658px;
		display: flex;
		align-items: center;
		overflow: hidden;
	}
	.about-main1-img img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	.about-name{
		width: 100%;
		height: auto;
		text-align: center;
		margin-top: 100px;
	}
	.about-name .about-name-title{
		font-size: 36px;
		line-height: 36px;
		color: #1A1A1A;
	}
	.about-name .about-name-msg{
		font-size: 12px;
		margin: 15px auto;
		color: #929d9d;
	}
	.about-name .about-name-line{
		width: 35px;
		height: auto;
		border: 2px solid #c8d0d0;
		margin: 0 auto;
	}
	.about-banner{
		width: 100%;
		height: 685px;
		position: relative;
		display: flex;
		align-items: center;
	}
	.about-banner img{
		width: auto;
		height: 685px;
		align-items: center;
	}
	.about-banner-txt{
		width: 100%;
		height: auto;
		position: absolute;
		top: 210px;
		left: 0;
		text-align: center;
	}
	.about-banner-title{
		font-size: 40px;
		font-weight: bold;
	}
	.about-banner-txt p{
		font-size: 16px;
		line-height: 32px;
	}
	.about-banner-line{
		width: 68px;
		height: auto;
		border-bottom: 5px solid #C25E4D;
		margin: 30px auto 40px auto;
	}
	
	@media screen and (max-width:1750px){
		.about-main2-img img{
			height: 875px;
			width: auto;
		}
	}
	@media screen and (max-width:1000px) {
		.pc_box{
			display: none;
		}
		.mb_box{
			display: block;
			margin-bottom: 50px;
		}
		body{
			font: 12px/1 "Microsoft Yahei","Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
		}
	}
	@media screen and (min-width:1001px) {
		.pc_box{
			display: block;
			/* margin-bottom: 50px; */
		}
		.mb_box{
			display: none!important;
		}
	}
	@media screen and (min-width:1500px){
		.about-banner img{
			width: 100%;
			height: auto;
		}
	}
</style>
