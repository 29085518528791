<template>
  <div class="bmiBox">
    <img
      src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/bmiBack.png"
      class="bmiBack"
    />
    <div class="bmiMain">
      <div class="mian_title"><img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/bmiLogo.png" style="width:75%;height:0.7rem"></div>
      <div class="mian_form">
        <div class="form-li">
          身高
          <input
            type="text"
            v-model="height"
            class="form-Input"
            placeholder="请输入"
            @input="onInput"
          />
          cm
        </div>
        <div class="form-li">
          体重
          <input
            v-model="weight"
            class="form-Input"
            placeholder="请输入"
            @input="onInput"
          />
          斤
        </div>
        <div class="form-li">
          <span style="margin-right:10px">BMI</span>
          <div style="font-weight:bold;width:5.7rem;text-align:right;">{{ bmi }}</div>
        </div>
        <div class="form-li">
          <span style="margin-right:4.2rem">健康体重</span>
          <div><span style="font-weight:bold;color:green;margin-right:5px">{{ healthy_weight }}</span>斤</div>
        </div>
      </div>
      <!-- 1阶段 -->
      <div class="tables">        
        <div class="table-title">生酮1阶段</div>
        <table>
          <tr>
            <td>时长</td>
            <td>减去自重</td>
            <td>初始体重</td>
            <td>预计体重</td>
            <td>预计达成体重</td>
          </tr>
          <tr>
            <td>3周</td>
            <td>5%</td>
            <td>{{weight ? weight : '-'}}</td>
            <td>{{expected_weight1 ? expected_weight1 : '-'}}</td>
            <td :style="achieve_weight1 ? 'font-weight:bold;color:red' : ''">{{achieve_weight1 ? achieve_weight1 : '-'}}</td>
          </tr>
        </table>
      </div>
      <!-- 2阶段 -->
      <div class="tables">        
        <div class="table-title">生酮2阶段</div>
        <table>
          <tr>
            <td>时长</td>
            <td>减去自重</td>
            <td>初始体重</td>
            <td>预计体重</td>
            <td>预计达成体重</td>
          </tr>
          <tr>
            <td>6周</td>
            <td>8%</td>
            <td>{{weight ? weight : '-'}}</td>
            <td>{{expected_weight2 ? expected_weight2 : '-'}}</td>
            <td :style="achieve_weight2 ? 'font-weight:bold;color:red' : ''">{{achieve_weight2 ? achieve_weight2 : '-'}}</td>
          </tr>
        </table>
      </div>
      <!-- 3阶段 -->
      <div class="tables">        
        <div class="table-title">生酮3阶段</div>
        <table>
          <tr>
            <td>时长</td>
            <td>减去自重</td>
            <td>初始体重</td>
            <td>预计体重</td>
            <td>预计达成体重</td>
          </tr>
          <tr>
            <td>9周</td>
            <td>10%</td>
            <td>{{weight ? weight : '-'}}</td>
            <td>{{expected_weight3 ? expected_weight3 :'-'}}</td>
            <td :style="achieve_weight3 ? 'font-weight:bold;color:red' : ''">{{achieve_weight3 ? achieve_weight3 : '-'}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: "",
      weight: "",
      bmi: 0,
      healthy_weight: 0,
      expected_weight1:'',
      achieve_weight1:'',
      expected_weight2:'',
      achieve_weight2:'',
      expected_weight3:'',
      achieve_weight3:'',
    };
  },
  methods: {
    onInput() {
      this.bmi = (this.weight / 2 / ((this.height / 100 ) * (this.height / 100 ))).toFixed(2);
      this.healthy_weight =this.height > 105 ? (this.height - 105) * 2 : 0;
      this.expected_weight1 = (this.weight * 0.05).toFixed(2)
      this.achieve_weight1 =(this.weight - (this.weight * 0.05)).toFixed(2)
      this.expected_weight2 = (this.weight * 0.08).toFixed(2)
      this.achieve_weight2 =(this.weight - (this.weight * 0.08)).toFixed(2)
      this.expected_weight3 = (this.weight * 0.1).toFixed(2)
      this.achieve_weight3 =(this.weight - (this.weight * 0.1)).toFixed(2)
    },
  },
};
</script>

<style scoped>
.bmiBox {
  position: relative;
  padding:0 0.25rem;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
.bmiBack {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}
.mian_form{
    margin-top:1rem
}
.mian_title {
  font-size: 1rem;
  margin-top: 1rem;
}
.form-li {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-size: 0.45rem;
  margin-top: 0.2rem;
}
.form-Input {
  width: 5.7rem;
  font-size: 0.45rem;
  height: 0.7rem;
  border: 1px solid #cccccc;
  text-align:right;
  padding-right:0.1rem;
  margin:0 5px
}
table{
    width:100%;
    font-size:0.4rem;
    margin-top:0.3rem
}
td{
    border:1px solid black;
    text-align:center;
    height:0.6rem;
    line-height:0.6rem
}
.tables{
    margin-top:1rem
}
.table-title{
    font-size:0.4rem;
    font-weight:bold;
    text-align:left;
}
</style>
