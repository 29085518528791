<template>
	<div class="checkboxs" v-if="qusdata">
		<!-- <div class="checkicon"></div> -->
		<div class="topicon" :class="qusdata.icon_url?'yesicon':'noicon'">
			<img v-if="qusdata.icon_url" :src="qusdata.icon_url" alt="">
		</div>
		<div class="title">{{qusdata.title}}</div>
		<div class="msg">{{qusdata.question_desc}}</div>
		<div class="tab"></div>
		<div class="chose">
			<div class="chosebox" :class="item.type?'thisone':''" v-for="(item,j) in qusdata.answer" :key="j" @click="getthischeck(item,j)">
				<div class="txt">{{item.item}}</div>
			</div>
		</div>
		<div class="btns">
			<button class="last show_mb" v-if="qusdata.current_num!=1" @click="last">上一题</button>
			<button class="next" @click="ok">下一题</button>
		</div>
	</div>
</template>

<script>
	export default{
		name:'checkbox',
		props: {
			rdata: Object
		},
		watch:{
				rdata(n,o){ //n为新值,o为旧值;
					//console.log(n,o)
					this.o = o
					this.n = n
					var data = n
					var answer = data.answer
					for(var i= 0;i<answer.length;i++){
						answer[i].type=false
					}
					data.answer = answer
					this.qusdata = data;
				}
		},
		data:()=>({
				title:'请选择你当下最关注的健康目标 ',
				msg:'',
				icon:'',
				qusdata:'',
				cchoses:[],
				get:0,
				o:'',
				n:'',
				e:''
		}),
		mounted() {
			//console.log(this.rdata)
					var data = this.rdata
					var answer = data.answer
					for(var i= 0;i<answer.length;i++){
						answer[i].type=false
					}
					data.answer = answer
					this.qusdata = data;
		},
		methods:{
			getthischeck(item,e){
				this.e = e
				//console.log(item,e)
				var that = this
				var qusdata = this.qusdata
				var answer = qusdata.answer
				if(item.type==false){
					item.type=!item.type
					for(var i=0;i<answer.length;i++){
						var pi = answer[i]
						if(pi.group!=item.group){
							pi.type=false
							answer[i]=pi
						}
					}
					qusdata.answer = answer
					that.qusdata = qusdata
				}else{
					item.type=!item.type
				}
				// var that = this
				// var qusdata = this.qusdata
				// var answer = qusdata.answer
				// var l = answer.length-1
				// if(answer[e].item=='以上都没有'&&answer[e].type==false){
				// 	for(var i=0;i<answer.length;i++){
				// 		answer[i].type=false
				// 	}
				// 	answer[e].type=true
				// 	qusdata.answer = answer
				// 	that.qusdata = qusdata
				// }else if(answer[e].item=='以上都不是'&&answer[e].type==false){
				// 	for(var j=0;j<answer.length;j++){
				// 		answer[j].type=false
				// 	}
				// 	answer[e].type=true
				// 	qusdata.answer = answer
				// 	that.qusdata = qusdata
				// }else if(answer[e].item=='以上都不符合'&&answer[e].type==false){
				// 	for(var k=0;k<answer.length;k++){
				// 		answer[k].type=false
				// 	}
				// 	answer[e].type=true
				// 	qusdata.answer = answer
				// 	that.qusdata = qusdata
				// }else{
				// 	if(that.qusdata.answer[l].item=='以上都没有'||that.qusdata.answer[l].item=='以上都不是'||that.qusdata.answer[l].item=='以上都不符合'){
				// 		that.qusdata.answer[l].type=false
				// 	}
				// 	that.qusdata.answer[e].type=!that.qusdata.answer[e].type
				// }
				// //console.log(that.qusdata)
				this.$forceUpdate()
				// var qusdata = this.qusdata
				// var chose = qusdata.answer
				// chose[e].type=!chose[e].type
				// qusdata.answer = chose
				// that.qusdata =qusdata
			},
			ok(){
				var that = this
				var qusdata = this.qusdata
				var answer = qusdata.answer
				var arr = []
				for(var i=0;i<answer.length;i++){
					if(answer[i].type){
						arr.push(answer[i].id)
					}
				}
				if(arr.length==0){
					that.$Message.info('请选择后再提交')
				}else{
					this.$emit('getback',{value:arr,way:'1'})
				}
			},
			last(){
				this.$emit('getback',{value:'',way:'0'})
			}
		}
	}
</script>

<style>
	.checkboxs{
		width: 100%;
		max-width: 26rem;
		height: auto;
		margin: 0 auto;
		/* margin-top: 1.5rem; */
	}
	.checkboxs .title{
		width: 100%;
		height: auto;
		font-size: 0.52rem;
		line-height: 1rem;
		font-weight: bold;
	}
	.checkboxs .msg{
		width: 100%;
		font-size: 0.3rem;
		line-height: 0.52rem;
		color: #1A1A1A;
	}
	.checkboxs .tab{
		background: #C25E4D;
		width: 1.11rem;
		height: 0.145rem;
		border-radius: 0.145rem;
		margin: 0.44rem auto 0.59rem auto;
	}
	.checkboxs .chose{
		width: 100%;
		margin: 0 auto;
		height: auto;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.checkboxs .chose .chosebox{
		width: 4.2rem;
		height: 1.85rem;
		border-radius: 0.3rem;
		padding: 0.4rem 0 0.2rem 0;
		/* border: 0.01rem solid #eee; */
		background: #F5F5F5;
		margin-top: 0.5rem;
		cursor: pointer;
		position: relative;

		background: #FFFFFF;
		border-radius: 0.19rem;
		border: 0.04rem solid #D5DDE6;
	}
	/* .checkboxs .chosebox .icon{
		width: 1rem;
		height: 1rem;
		background:#eee;
		margin: 0 auto;
	} */
	.checkboxs .chosebox .txt{
		width: 70%;
		margin: auto 15%;
		height: auto;
		line-height: 0.37rem;
		text-align: center;
		color: #1A1A1A;
		font-size: 0.26rem;
		position: absolute;
		top: 50%;
    transform: translateY(-50%);
	}
	.checkboxs .chose .thisone{
		/* border: 0.02rem solid #C25E4D;
		background: #fff;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1); */


		background: #FFFFFF;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(216, 118, 101, 0.26);
		border-radius: 0.19rem;
		border: 0.04rem solid #C25E4D;
	}
	.checkicon{
		width: 1.5rem;
		height: 1.5rem;
		margin: 0.15rem auto;
		background-color: #efefef;
	}
</style>
