<template>
	<div class="packboxs" v-if="qusdata">
		<div class="topicon" :class="qusdata.icon_url?'yesicon':'noicon'">
			<img v-if="qusdata.icon_url" :src="qusdata.icon_url" alt="">
		</div>
		<div class="title">{{qusdata.title}}</div>
		<div class="msg">{{qusdata.question_desc}}</div>
		<div class="tab"></div>
		<div class="chose" v-if="showit&&qusdata.keyword!='name'">
			<!-- <div class="input-group thepack">坑 -->
			<div class="thepack">
				<input type="text" class="packinput" maxlength="3" v-model="input" :placeholder="placeholder">
				<div class="danwei">
					{{qusdata.keyword=='weight'?'KG':'CM'}}
				</div>
				<!-- <span class="input-group-addon" v-if="unit" style="padding: 0 0.6rem;font-size: 0.45rem;border:none;background:none;color: #1A1A1A;font-weight: bold;">{{unit}}</span> -->
			</div>
		</div>
		<div class="chose" v-if="showit&&qusdata.keyword=='name'">
			<!-- <div class="input-group thepack">坑 -->
			<div class="thepack chosename">
				<input type="text" ref="inputs" class="packinput nameinput" maxlength="8" v-model="input" :placeholder="placeholder">
				<!-- <span class="input-group-addon" v-if="unit" style="padding: 0 0.6rem;font-size: 0.45rem;border:none;background:none;color: #1A1A1A;font-weight: bold;">{{unit}}</span> -->
			</div>
		</div>
		<div class="btns btnsp" v-if="showit" :class="qusdata.current_num!=1?'':'firstti'">
			<button class="last show_mb" v-if="qusdata.current_num!=1" @click="last">上一题</button>
			<button class="next" @click="ok">下一题</button>
		</div>
	</div>
</template>

<script>
	export default{
		name:'pack',
		props: {
			rdata: Object
		},
		watch:{
				rdata(n,o){ //n为新值,o为旧值;
					//console.log(o.length)
					this.o = o
					if(n){
						this.qusdata = n;
						this.input=''
						this.showit = true
					}
			if(n.keyword=='name'){
				// this.$refs.buttonFocus.$el.focus();
				this.placeholder='你的姓名'
			}else if(n.keyword=='age'){
				this.placeholder='你的年龄'
			}else if(n.keyword=='sex'){
				this.placeholder='你的性别'
			}else if(n.keyword=='weight'){
				this.placeholder='你的体重'
			}else if(n.keyword=='height'){
				this.placeholder='你的身高'
			}
				}
		},
		data:()=>({
			title:'请输入你的姓名 ',
			o:'',
			msg:'',
			icon:'',
			input:'',
			placeholder:'你的身高',
			num:0,
			nametest:'^[\u4e00-\u9fa5a-zA-Z0-9]+$',
			unit:'',
			qusdata:'',
			cchoses:[],
			get:0,
			showit:true
		}),
		mounted() {
			this.$nextTick(()=>{
				this.$refs.inputs.focus();
			})
			// this.$refs.buttonFocus.$el.focus();
			//console.log(this.rdata)
			this.qusdata = this.rdata
			if(this.rdata.keyword=='name'){
				this.placeholder='你的姓名'
			}else if(this.rdata.keyword=='age'){
				this.placeholder='你的年龄'
			}else if(this.rdata.keyword=='sex'){
				this.placeholder='你的性别'
			}else if(this.rdata.keyword=='weight'){
				this.placeholder='你的体重'
			}else if(this.rdata.keyword=='height'){
				this.placeholder='你的身高'
			}
		},
		methods:{
			ok(){
				var that = this
				var nametest = new RegExp(this.nametest)
				var input = this.input
				input=input.replace(/\s+/g,"");
				// this.input = input
				var keyword = this.rdata.keyword
				if(input==''){
					that.$Message.info('请填写后再提交')
				}else{
					if(keyword=='name'){
						if(!nametest.test(input)){
							that.$Message.info('请正确填写你的姓名')
						}else if(input.length>8){
							that.$Message.info('最多填写八个字！')
						}else{
						this.showit = false
							this.$emit('getback',{value:input,way:'1'})
						}
					}else if(keyword=='weight'){
						input = input*1
						if(isNaN(input)){
							that.$Message.info('请正确填写你的体重')
						}else if(input<15||input>250){
							that.$Message.info('请正确填写你的体重')
						}else{
						this.showit = false
							this.$emit('getback',{value:input,way:'1'})
						}
					}else if(keyword=='height'){
						input = input*1
						//console.log(input)
						if(isNaN(input)){
							that.$Message.info('请正确填写你的身高')
						}else if(input<100||input>250){
							that.$Message.info('请正确填写你的身高')
						}else{
						this.showit = false
							this.$emit('getback',{value:input,way:'1'})
						}
					}else{
						this.showit = false
						this.$emit('getback',{value:input,way:'1'})
					}
				}
			},

			last(){
						// this.showit = false
				this.$emit('getback',{value:'',way:'0'})
			}
		}
	}
</script>

<style>
	.packboxs{
		width: 100%;
		max-width: 26rem;
		height: auto;
		margin: 0 auto;
		position: relative;
	}
	.lastpc img{
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.055rem;
		margin-right: 0.15rem;
	}
	.packboxs .title{
		width: 100%;
		height: auto;
		font-size: 0.52rem;
		line-height: 1rem;
		font-weight: bold;
		/* margin-top: 4rem; */
	}
	.packboxs .msg{
		width: 100%;
		font-size: 0.3rem;
		line-height: 0.4rem;
		color: #444;
	}
	.packboxs .tab{
		background: #C25E4D;
		width: 1.11rem;
		height: 0.145rem;
		border-radius: 0.145rem;
		margin: 0.59rem auto 1.11rem auto;
	}
	.packboxs .chose{
		width: 80%;
		margin: 0 auto;
		height: auto;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.packboxs .chose .chosebox{
		width: 2rem;
		height: 2rem;
		border-radius: 2rem;
		padding: 0.45rem 0 0.25rem 0;
		/* border: 0.01rem solid #eee; */
		background: #eee;
		margin: 0.2rem;
		cursor: pointer;
		align-items: center;
	}
	.packboxs .chosebox .icon{
		width: 0.7rem;
		height: 0.7rem;
		margin: 0 auto;
	}
	.packboxs .chosebox .txt{
		width: 100%;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.2rem;
	}
	.packboxs .chosebox .centertxt{
		width: 100%;
		height: 1.4rem;
		line-height: 1.4rem;
		font-size: 0.2rem;
	}
	.packboxs .chose .thisone{
		border: 0.02rem solid #C25E4D;
		background: #fff;
	}
	.packboxs .thisone .centertxt{
		color: #C25E4D;
	}
	.thepack{
		width: 9.4rem;
		height: 1.4rem;
		position: relative;
		/* background: #E6E6E6;
		border-radius: 10px;
		overflow: hidden; */


		display: flex;
		justify-content: center;
	}
	.thepack .danwei{
		position: absolute;
		line-height: 0.89rem;
		right: calc( 50% - 1.8rem );
		font-size: 0.28rem;
	}
	.chosename{
		width: 5.22rem;
		height: 5.83rem;
		margin: 0 auto;
		background: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/namebg.png);
			background-repeat:no-repeat;
			background-position:top left;
			background-size: 100%;
	}
	.thepack .packinput{
		border: none;
		background: #FFFFFF;
		/* height: 1.4rem; */
		/* line-height: 1.4rem; */
		/* font-size: 0.45rem; */
		/* text-indent: 0.6rem; */
		text-align: center;


		width: 4.22rem;
		height: 0.89rem;
		line-height: 0.89rem;
		border-radius: 0.89rem;
		font-size: 0.28rem;
		border: 0.04rem solid #D5DDE6;
	}
	.chosename .nameinput{
		margin-top: 2.57rem;
		border: none;
		background: none;
		text-align: left;
		text-indent: 0.1rem;
	}

	@media screen and (max-width:1000px) {
		.chose .chosename{
			width: 6.78rem;
			height: 7.58rem;
			margin: 0 auto;
			background: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/namebg.png);
			background-repeat:no-repeat;
			background-position:top left;
			background-size: 100%;
		}
		.chose .chosename .nameinput{
			margin-top: 3.5rem;
			width: 80%;
			text-indent: 0.2rem;
		}
		.thepack .danwei{
			position: absolute;
			line-height: 1.35rem;
			right: 0.5rem;
			font-size: 0.37rem;
		}
	}
</style>
